import React from "react"; 
import {Ordersicon,FitwalletIcon,WishlistIcon,AddressbookIcon,ProfileIcon,OrdersiconSelected,WishlistIconSelected,FitwalletIconSelected,AddressbookIconSelected,ProfileIconSelected} from "../../MiniComponents/SvgIcons.jsx";

export default function SmallParts() {
  return (
    <div>
      {/* <div className="container">
        <div className="col-12">
          <div className="row">
            <div className="col-10">Home paes</div>
            <div className="col-2 text-end">
              <img
                src={require("../assets/chevron-forward-outline.svg").default}
                style={{ verticalAlign: "baseline", width: "35px" }}
                alt="menu"
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export function NavMenuSubItem(props) {
    return (
      <div>
        <div className="container">
          <div className="col-12">
            <div className="row">
              <div className="col-10" style={{fontSize:"14px",fontFamily:"Inter",fontWeight:"500"}}>{props.title}</div>
              <div className="col-2 text-end" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <img
                  src={require("../../assets/chevron-forward-outline.svg").default}
                  style={{width: "20px"}}
                  alt="menu"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export function NavMenuSubItem1(props) {
    let ImageSource;
    
    // Conditionally set the image based on the selected state
    const isSelected = props.activeItem === props.title;
  
    switch (props.title) {
      case "Orders":
        ImageSource = isSelected
          ? <OrdersiconSelected className='img-fluid' style={{ width: "25px" }} />
          : <Ordersicon className='img-fluid' style={{ width: "25px" }} />;
        break;
      case "Wishlist":
        ImageSource = isSelected
          ? <WishlistIconSelected className='img-fluid' style={{ width: "25px" }} />
          : <WishlistIcon className='img-fluid' style={{ width: "25px" }} />;
        break;
      case "FitCash":
        ImageSource = isSelected
          ? <FitwalletIconSelected className='img-fluid' style={{ width: "25px" }} />
          : <FitwalletIcon className='img-fluid' style={{ width: "25px" }} />;
        break;
      case "Address Book":
        ImageSource = isSelected
          ? <AddressbookIconSelected className='img-fluid' style={{ width: "25px" }} />
          : <AddressbookIcon className='img-fluid' style={{ width: "25px" }} />;
        break;
      case "Logout":
        ImageSource = isSelected
          ? <ProfileIconSelected className='img-fluid' style={{ width: "25px" }} />
          : <ProfileIcon className='img-fluid' style={{ width: "25px" }} />;
        break;
      default:
        ImageSource = null;
    }
  
    return (
      <div className={`container ${isSelected ? 'active-item' : ''}`}>
        <div className="col-12">
          <div className="row">
            <div className="col-2 text-start">
              {ImageSource}
            </div>
            <div className="col-10" 
                 style={{ 
                   fontSize: "15.26px", 
                   fontFamily: "Inter", 
                   fontWeight: "400", 
                   color: "#4E4E4E", 
                   paddingLeft:"3px"
                  
                 }}>
              {props.title}
            </div>
          </div>
        </div>
      </div>
    );
  }