 
import Feedback from "react-bootstrap/esm/Feedback.js";
import {guest_login_API,generate_otp_API,app_id,refereshtoken_API,verify_otp_API,check_referral_code} from "./ApiNames.js";
import axios from 'axios'; 
import { post } from "./PostCall.js";
import { CheckSessionValidity, Headers } from "./HeaderValidity.js";
 

const baseURL = process.env.REACT_APP_API_URL;


console.log('Base URL:', process.env.REACT_APP_API_URL);
console.log('Base URL:', baseURL);
console.log(process.env.NODE_ENV);
console.log(process.env.PUBLIC_URL);

// OLD
const FormData = require('form-data');
// async function CallApi(url,body){
 
//     // try{
//     //   let current_timestamp=Math.round(+new Date()/1000);
//     // let headerdata={  
//     //     'Client-Id': app_id
//     //   }
//     // if(localStorage.getItem('access_token'))
//     // {
//     //     headerdata={  
//     //         'Client-Id': app_id,
//     //         'Authorization':"Bearer "+localStorage.getItem('access_token')
//     //       }
//     // }
   
//     // if(((current_timestamp+100)>=(localStorage.getItem('access_expiry_timestamp'))))
//     // {
//     //     await RefershHeaders();  
//     //     headerdata={  
//     //         'Client-Id': app_id,
//     //         'Authorization':"Bearer "+localStorage.getItem('access_token')
//     //     } 
//     // }

//     // let config = {
//     //     method : "post",
//     //     mode: 'no-cors',
//     //     maxBodyLength: Infinity,
//     //     url : process.env.NODE_ENV==="production"?`${baseURL}${url}`:`${url}`,
//     //     headers : headerdata,
//     //     data :  body
//     // }


//     // return await axios(config).then(res => res.data);
//     // }catch(error){
//     //   if (error.response) {
        
//     //     console.log('Server responded with an error:', error.response.data);
//     //   } else if (error.request) {
        
//     //     console.log('No response received:', error.request);
//     //   } else {
        
//     //     console.log('Error setting up request:', error.message);
//     //   }
//     // }
// }

export default async function GuestLogin(utm_source,utm_medium,utm_campaign) {
  //   try{
  //   let body = new FormData(); 
  //   body.append('utm_source', utm_source); 
  //   body.append('utm_medium', utm_medium); 
  //   body.append('utm_campaign', utm_campaign); 
  //   console.log("called1",localStorage.getItem('access_expiry_timestamp')    )
  //   let current_timestamp=Math.round(+new Date()/1000);
    
  //   let ret={  
  //     'Content-Type': 'application/json',
  //       'Client-Id': app_id,
        
  //     }
  //   // if(localStorage.getItem('access_expiry_timestamp')===null || localStorage.getItem('access_expiry_timestamp')=== undefined ) 
  //   // {  
  //       let res=await post(guest_login_API, body); 
  //       if(res.error==="0")
  //       {
  //           localStorage.setItem('is_guest', '1');
  //           localStorage.setItem('access_expiry_timestamp', res.access_expiry_timestamp); 
  //           localStorage.setItem('access_token', res.access_token);
  //           localStorage.setItem('refresh_token', res.refresh_token);             
  //           localStorage.setItem('refresh_token_expiry_timestamp',res.refresh_token_expiry_timestamp);
            
  //       // } 
  //       window.location.reload();
  //       console.log("current_timestamp:",current_timestamp,"access_expiry_timestamp",(localStorage.getItem('access_expiry_timestamp')))
        
  //       ret={  
  //         'Content-Type': 'application/json',
  //           'Client-Id': app_id,
  //           'Authorization':"Bearer "+localStorage.getItem('access_token'),
            
  //         }
        
  //   } 
  //   return ret;
  // } catch (error) {
  //   alert("Error in Guest Login API Call");
  //   console.log(error);
  //   return null;
  // }
  try {
    
    let body = new FormData();
    // body.append('utm_source', utm_source);
    // body.append('utm_medium', utm_medium);
    // body.append('utm_campaign', utm_campaign);
    let HeadersData = await Headers();        
    let res = await post(guest_login_API, body, HeadersData);
    // alert("17Called Guest Login API");
    console.log(res,"Guest1");
    if (res != null) {
      // alert("srininvas0");
      localStorage.setItem('is_guest', '1');
      localStorage.setItem('access_expiry_timestamp', res.access_expiry_timestamp);
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      localStorage.setItem('refresh_token_expiry_timestamp', res.refresh_token_expiry_timestamp);
      // alert(res.refresh_token_expiry_timestamp,":Guest");
      window.location.reload();
      console.log(res, "New Api Call POST Guest");
      return res;
    } else {
      // alert("srininvas");
      return null;
    }
  } catch (error) {
    // alert("Error in Guest Login API Call");
    console.log(error);
    return null;
  }
    
  }

  export async function AddOrder(couponCode,total,itemsTotal,paymentType,fpused,orderSource,freeItems,utm_source,utm_medium,utm_campaign,value) { 
    if(await CheckSessionValidity()){
      let body = new FormData();
      body.append('coupon_code', couponCode); 
      body.append('total_amount', total); 
      body.append('items_total', itemsTotal); 
      body.append('payment_type', paymentType); 
      body.append('rewards_used', fpused); 
      body.append('order_placed_from', 'mobile'); 
      body.append('order_source', orderSource); 
      body.append('free_items',  JSON.stringify(freeItems).toString()); 
      body.append('utm_source', utm_source); 
      body.append('utm_medium', utm_medium); 
      body.append('utm_campaign', utm_campaign); 
      body.append('is_cod_order',value );  
      let HeadersData = await Headers();  
      try {
        let res = await post("/add_order", body,HeadersData);
        console.log(res, "AddOrder");
        return res;
      } catch (error) {
        console.error("Error while adding order:", error);
        return { error: true, message: "Failed to add order" }; // Handle error case
      }
    }
   
  }
  export async function storeloactor() { 
    if(await CheckSessionValidity()){
    let body = new FormData();
    body.append('location', '17.4418972,78.3968598'); 
    let HeadersData = await Headers();  
    try {
      let res = await post("/store_locator", body,HeadersData);
      console.log(res, "storeloactor");
      return res;
    } catch (error) {
      console.error("Error storeloactor:", error);
      return { error: true, message: "Failed show loaction" }; 
    }
  }
  }
  export async function store_locator_feedback(visit_status,feedback) {
    let HeadersData = await Headers();  
    if(await CheckSessionValidity()){
    try {
      let body = new FormData();
      body.append('visit_status',visit_status); 
      body.append('feedback',feedback); 
      let res=await post("/store_locator_feedback",body,HeadersData);
        console.log(res,":store_locator_feedback")
      return res;
    } catch (e) {
      console.log('Error')
    }
  }
  }

  export async function OrderRelatedData(razor_order_id){
    if(await CheckSessionValidity()){
    let body = new FormData();
    body.append('razor_order_id', razor_order_id);
    let HeadersData = await Headers();  
    let res = await post("/order_related_small_data",body,HeadersData);
    console.log(res.body,"Order Related Data");
    return res;
    }
  }

  export async function GenerateOtp(mobile) { 
    if(await CheckSessionValidity()){
        let body = new FormData();

        body.append('mobile_number', mobile); 
        body.append('mobile_number_country_id', "1"); 
let HeadersData = await Headers();  
      let res=await post(generate_otp_API,body,HeadersData);
     
      return res;
    }
  }
  
  export async function compare_similar_products(item_id) {
    if(await CheckSessionValidity()){
    let body = new FormData();
    body.append('item_id', item_id);
    
    let HeadersData = await Headers();  
    let res = await post("/compare_similar_products", body,HeadersData); 
    return res;
    }
}
export async function compare_products(item_ids) {
  if(await CheckSessionValidity()){
  let body = new FormData();
  // body.append('main_item_id', main_item_id);
  body.append('item_ids', JSON.stringify(item_ids));
  
  let HeadersData = await Headers();  
  let res = await post("/compare_products", body,HeadersData); 
  return res;
  }
}
export async function search_compare_similar_products(search_string) { 
  if(await CheckSessionValidity()){
  try {
    let body = new FormData();
    body.append('search_string', search_string);
    let HeadersData = await Headers();  
    let res=await post("/search_compare_similar_products",body,HeadersData);
    console.log(res,":search_compare_similar_products")
    return res;
  } catch (e) {
    console.log('Error')
  }
  }
  
}
export async function frequently_brought_together(product_id,include_ids) {
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('product_id', product_id);
  body.append('include_ids', JSON.stringify(include_ids));
  let HeadersData = await Headers();  
 
  let res = await post("/frequently_brought_together", body,HeadersData); 
  return res;
  }
}

  export async function VerifyOtp(mobile,otp,utm_source,utm_medium,utm_campaign) { 
    if(await CheckSessionValidity()){
    let body = new FormData();
    body.append('mobile_number', mobile);
    body.append('otp', otp);  
    body.append('mobile_number_country_id', "1"); 
    body.append('utm_source', utm_source); 
    body.append('utm_medium', utm_medium); 
    body.append('utm_campaign', utm_campaign); 
    let HeadersData = await Headers();  
  let res=await post(verify_otp_API,body,HeadersData);
  if(res.error==='0'){
    localStorage.setItem('is_guest', '0');
    localStorage.setItem('access_expiry_timestamp', res.access_expiry_timestamp); 
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);
    localStorage.setItem('refresh_token_expiry_timestamp',res.refresh_token_expiry_timestamp);
  }
     
  return res;
}
}



export async function HomePage() {  
  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/new/home",{},HeadersData);
    console.log(res,":Homepageres")
  return res;
  }
}

export async function TopGrossingProductsApi() {  
  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/top_grossing_products",{},HeadersData);
    console.log(res,":TopGrossres")
  return res;
  }
}
export async function RecentlyviewedApi() {  
  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/top_grossing_products",{},HeadersData);
    console.log(res,":RecentlyViewedres")
  return res;
}
}

export async function OrderAgainProductsApi() {  
  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/order_again_products",{},HeadersData);
    console.log(res,":OrderAgainres")
  return res;
}
}

export async function ProductDetailsApi(id) {
  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  
  body.append('product_id', id);  

let res=await post("/product_details",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function CheckProductAvaiabilityApi(pin) {
  
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('pincode', pin);  
  let HeadersData = await Headers();  
  let res=await post("/get_estimated_deliverydate",body,HeadersData);
    console.log(res,":res")
  return res;
  }
}

export async function AddToCart(p_id,qnt,utm_source,utm_medium,utm_campaign) { 
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('product_id', p_id);
  body.append('quantity', qnt);  
  body.append('utm_source', utm_source); 
  body.append('utm_medium', utm_medium); 
  body.append('utm_campaign', utm_campaign); 

  let HeadersData = await Headers();  
let res=await post("/add_to_cart",body,HeadersData);
  console.log(res,":res")
return res;
  }
}
export async function viewsimilarpro(p_id) { 
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('product_id', p_id);
  let HeadersData = await Headers();  
let res=await post("/out_of_stock_related_products",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function ordercancelremarks(o_id,feedback) { 
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('order_id', o_id);
  body.append('feedback',feedback );
  let HeadersData = await Headers();  
let res=await post("/add_order_cancellation_remarks",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function ordercancel(o_id,returntype) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('order_id', o_id);
  body.append('return_type',returntype,HeadersData );

let res=await post("/cancel_order",body);
  console.log(res,":res")
return res;
}
}

export async function AddwalletApi(amt){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
    let body = new FormData();
    body.append('add_amount', amt);  


  let res=await post("/add_wallet_balance",body,HeadersData);
    console.log(res,":res")
  return res;
}
}

export async function RemoveItemFromCart(c_id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('cart_id', c_id);  


let res=await post("/delete_cart_item",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function SubmitMembershipFeedback(membership_id,review){
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append("membership_id",membership_id);
  body.append("review",review);
  let HeadersData = await Headers();  
  let res = await post("/submit_membership_feedback",body,HeadersData);
  console.log(res, "SubmitReview res")
  return res;
}
}

export async function RetrieveWebNotification(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res = await post("/send_web_notifications",HeadersData);
  console.log(res, "WEbbbbbbbb")
  return res;
}
}

export async function AddSubScriptionPlan(item_id){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("item_id",item_id);
  let res = await post("/direct_buy_subscription_plan",body,HeadersData);
  console.log(res, "AddSubScriptionPlan res")
  return res;

}}

export async function emailsubscriptionsApi(email){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("ref_type","Website Home Nutsby");
  body.append("email_id",email);
  let res = await post("/email_subscriptions",body,HeadersData);
  console.log(res, "emailsubscriptionsApi res")
  return res;
  }
}

export async function UserRegistration(name,email,mobile,otp,gender,referral_code,utm_source,utm_medium,utm_campaign) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('mobile_number', mobile);
  body.append('otp', otp);  
  body.append('email_id',email); 
  body.append('name',name);
  body.append('fcm_token', "");
  body.append('mobile_number_country_id',"+91");
  body.append('device_details', "");
  body.append('gender',gender);
  body.append('referral_code',referral_code);
  body.append('platform',"Website");
  body.append('utm_source',utm_source);
  body.append('utm_medium',utm_medium);
  body.append('utm_campaign',utm_campaign);
  
let res=await post("/registeration2",body,HeadersData);
  console.log(res,":res")
return res;
}
}
export async function verify_referral_code(referral_code) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('referral_code', referral_code); 
 let res=await post(check_referral_code,body,HeadersData);
console.log(res)

return res;
}
}
export async function MembershipreviewList(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res = await post("/membership_review_list",{},HeadersData);
  console.log(res,"MembershipReviews res")
  return res;
}}

export async function UpdateFavStatus(p_id) { 
  if(await CheckSessionValidity()){
  let body = new FormData();
  body.append('product_id', p_id); 

  let HeadersData = await Headers();  
let res=await post("/add_or_remove_favorite",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function EditAddressApi(id,name,mobile,area,address2,landmark,address,pincode,markerPosition,activeButton,otherType) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('address_id',id); 
  body.append('name',name); 
  body.append('mobile_number', mobile);
  body.append('loc',markerPosition);
  body.append('loc_address',address);
  body.append('address_type',activeButton );
  body.append('address_line1', area);
  body.append('address_line2',address2);  
  body.append('pincode', pincode);  
  body.append('landmark',landmark);  
  body.append('other_address_type',otherType);  

let res=await post("/edit_customer_address",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function DeleteAddressApi(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('address_id',id,HeadersData); 

let res=await post("/delete_customer_address",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function CustomerAddressDetailsApi(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('address_id',id); 
let res=await post("/customer_address_details",body,HeadersData);
  console.log(res,":customer_address_details")
return res;
}
}

export async function Logout(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res = await post("/logout",{},HeadersData); 
  // await GuestLogin();
  localStorage.clear();
  window.location.reload();
  // localStorage.setItem('is_guest', '1');
  // localStorage.setItem('access_expiry_timestamp', null); 
  // localStorage.setItem('access_token',null);
  // localStorage.setItem('refresh_token',null); 
  console.log(res,"Logout res")
  return res;
}}

export async function GetOrderList() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/new/orders_list",{},HeadersData);
    console.log(res,":GetOrderList res")
  return res;
}}

export async function GetOrderDetails(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  console.log(id,"order_id") 
  let body = new FormData();
  body.append('order_id', id);  

let res=await post("/order_details",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function scratch_card_list() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/scratch_card_list",{},HeadersData);
    console.log(res,":scratch_card_list res")
  return res;
}}
export async function scratch_card_details(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  console.log(id,"order_id") 
  let body = new FormData();
  body.append('scratch_card_id', id);  

let res=await post("/scratch_card_details",body,HeadersData);
  console.log(res,":res")
return res;
}}
export async function redeem_scratch_card(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  console.log(id,"order_id") 
  let body = new FormData();
  body.append('scratch_card_id', id);  

let res=await post("/redeem_scratch_card",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function blog_details(id) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('id', id);  
  let res=await post("/blog_details",body,HeadersData);
    console.log(res,":Getblog_details res")
  return res;
}
}
export async function blog_comment(id,email,comment,name) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('id', id); 
  body.append('email', email); 
  body.append('comment', comment); 
  body.append('name', name);  
  let res=await post("/blog_comment",body,HeadersData);
    console.log(res,":Getblog_comment res")
  return res;
}}

export async function GetFitPointsList() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/fitpoints_api",{},HeadersData);
    console.log(res,":GetFitPointsList res")
  return res;
}}

export async function GetAdressList() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/customer_address_list",{},HeadersData);
    console.log(res,":GetAdressList res")
  return res;
}}

export async function GetFavList() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/favourites_list",{},HeadersData);
    console.log(res,":GetFavList res")
  return res;
}}
export async function blog_list(category_hash_id,year,month,tag_hash_id,title,author_hash_id) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("category_hash_id",category_hash_id);
  body.append("year",year);
  body.append("month",month);
  body.append("tag_hash_id",tag_hash_id);
  body.append("title",title);
  body.append("author_hash_id",author_hash_id);
  let res=await post("/blog_list",body,HeadersData);
    console.log(res,":Getblog_list res")
  return res;
}
}


export async function CartListApiCall(value) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
 body.append('is_cod_order',value );  
  let res=await post("/cart_list",body,HeadersData);
    console.log(res,":cart_list res")
  return res;
}
}

export async function ProfileDetails(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=  await post("/profile_details",{},HeadersData);
  console.log(res,"GetProfileDetails res")
  return res;
}
}


// export async function EditProfileDetails(name,email_id,date_of_birth,image){
//   if(await CheckSessionValidity()){
//     let HeadersData = await Headers();  
//   console.log(name,"name")
//   console.log(email_id,"name")
//   console.log(date_of_birth,"name")  
//   console.log(image,"image");
//   let body = new FormData();
//   body.append('name', name);  
//   body.append("email_id",email_id);
//   body.append("date_of_birth",date_of_birth);
//   body.append("mobile_number_country_id","1");
//   body.append("image",image);

//   let res = await post("/edit_profile",body,HeadersData);
//   console.log(res, "editProfile res")
//   return res;
// }}
export async function RateOrderdetailsApi(id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('order_id', id);  
let res=await post("/order_review_products",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function OrderRatingSubmitApi(id,ratings,deliveryrating) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('order_id', id); 
  body.append('item_ratings', ratings);
  body.append('rating', deliveryrating);
  
let res=await post("/order_review_submitting",body,HeadersData);
  console.log(res,":res")
return res;
}
}

export async function GoalHomeMuscleBuildingApi() {  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
let res=await post("/muscle_building_data",{},HeadersData);
  console.log(res,":res")
return res;
  }
}

export async function HappyHealthyDataApi() {  
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/happy_healthy_data",{},HeadersData);
    console.log(res,":res")
  return res;
  }
  }
  
  export async function AthleteDataApi() {  
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let res=await post("/athlete_data",{},HeadersData);
      console.log(res,":res")
    return res;
    }
    }
    export async function MembershipDetails(){
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res = await post("/membership_details",{},HeadersData);
      console.log(res, "MembershipDet res")
      return res;
    }}
    export async function FitnessForeverDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/fitness_forever_data",{},HeadersData);
        console.log(res,":res")
      return res;
      }}

    export async function haircareDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/hair_care_data",{},HeadersData);
        console.log(res,":hair_care_data")
      return res;
      }}
    export async function skincareDataApi() { 
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();   
      let res=await post("/skin_care_data",{},HeadersData);
        console.log(res,":skin_care_data")
      return res;
      }}
      export async function eyecareDataApi() {  
        if(await CheckSessionValidity()){
          let HeadersData = await Headers();  
        let res=await post("/eye_care_data",{},HeadersData);
          console.log(res,":eye_care_data")
        return res;
        }}
    export async function sleepsupportDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/sleep_support_data",{},HeadersData);
        console.log(res,":sleep_support_data")
      return res;
      }}
    export async function jointhealthDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/joint_health_data",{},HeadersData);
        console.log(res,":joint_health_data")
      return res;
      }  }
     export async function stressreliefDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
       let res=await post("/stress_relief_data",{},HeadersData);
         console.log(res,":stress_relief_data")
       return res;
       }  }

     export async function digestionDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
       let res=await post("/digestion_data",{},HeadersData);
         console.log(res,":digestion_data")
       return res;
        }   }  
    export async function liverDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
         let res=await post("/liver_health_data",{},HeadersData);
           console.log(res,":liver_health_data")
         return res;
          }}
    export async function weightgainDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
            let res=await post("/weight_gain_data",{},HeadersData);
              console.log(res,":liver_health_data")
            return res;
             }    } 
    export async function weightlossDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
              let res=await post("/weight_loss_data",{},HeadersData);
                console.log(res,":liver_health_data")
              return res;
               }
              }
    export async function age_8_17DataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/age_8_17",{},HeadersData);
        console.log(res,":age_8_17")
      return res;
       }        
      }
    export async function age_18_27DataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
        let res=await post("/age_18_27",{},HeadersData);
          console.log(res,":age_18_27")
        return res;
         }        
        }
    export async function age_28_37DataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
          let res=await post("/age_28_37",{},HeadersData);
            console.log(res,":age_28_37")
          return res;
           }  
          }
    export async function age_38_50DataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
     let res=await post("/age_38_50",{},HeadersData);
       console.log(res,":age_38_50")
     return res;
      }     
    }
    export async function age_50_aboveDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
        let res=await post("/age_50_above",{},HeadersData);
          console.log(res,":age_50_above")
        return res;
         }      
        }
    export async function nutrieatsDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
        let res=await post("/nutrieats_home",{},HeadersData);
            console.log(res,":nutrieats_home")
        return res;
           }     
          }
     export async function healthysnacksDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/healthy_snacks",{},HeadersData);
          console.log(res,":healthy_snacks")
      return res;
         }   }  

     export async function fresh_healthyDataApi() {  
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      let res=await post("/fresh_healthy",{},HeadersData);
          console.log(res,":fresh_healthy")
      return res;
         }  }  
      export async function PeanutbutterDataApi() {  
        if(await CheckSessionValidity()){
          let HeadersData = await Headers();  
          let res=await post("/peanut_butter_data",{},HeadersData);
              console.log(res,":peanut_butter_data")
          return res;
             }     }
      export async function allCategoriesDataApi() { 
        if(await CheckSessionValidity()){
          let HeadersData = await Headers();   
        let res=await post("/new/allcategories",{},HeadersData);
          console.log(res,":allcategories")
        return res;
        }}
        export async function allbrandsDataApi() {  
          if(await CheckSessionValidity()){
            let HeadersData = await Headers();  
          let res=await post("/brands",{},HeadersData);
            console.log(res,":brands")
          return res;
          }}
      
      export async function GetHeaderDetailsApi() {  
        if(await CheckSessionValidity()){
          let HeadersData = await Headers();  
        if(await CheckSessionValidity()){
          let HeadersData = await Headers();  
          let res=await post("/header_retrive_data",{},HeadersData);
          console.log(res,":header_retrive_data")
        return res;
        }
          
          }}

export async function AddAddressApi(name,mobile,area,address2,landmark,address,pincode,markerPosition, activeButton, otherType) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('name',name); 
    body.append('mobile', mobile);
    body.append('loc', markerPosition);
    body.append('loc_address',address);
    body.append('address_type', activeButton);
    body.append('address_line_1', area);
    body.append('address_line_2', address2);  
    body.append('pincode', pincode);  
    body.append('landmark', landmark);  
    body.append('other_address_type',otherType);  

    let res=await post("/add_customer_address",body,HeadersData);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}}

export async function save_session_loc(location,address,pincode) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  if(await CheckSessionValidity()){
  try {
    let body = new FormData();
    body.append('location',location); 
    body.append('address', address);
    body.append('pincode', pincode);
  
    let res=await post("/save_session_loc",body,HeadersData);
      console.log(res,":save_session_loc")
    return res;
  } catch (e) {
    console.log('Error')
  }
}
}}


export async function register_affiliate(payee_name,instagram_link,website_link,category,description,bank_name,bank_branch_name,bank_ifsc_code, bank_account_number, bank_upi_id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('payee_name',payee_name); 
    body.append('instagram_link',instagram_link); 
    body.append('website_link',website_link); 
    body.append('category',category); 
    body.append('description',description); 
    body.append('bank_name',bank_name); 
    body.append('bank_branch_name',bank_branch_name); 
    body.append('bank_ifsc_code',bank_ifsc_code); 
    body.append('bank_account_number',bank_account_number); 
    body.append('bank_upi_id',bank_upi_id); 

    let res=await post("/register_affiliate",body,HeadersData);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}}
export async function web_contact_us(name,mobile,mail,msg) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('name',name); 
    body.append('mobile',mobile); 
    body.append('mail',mail); 
    body.append('address',''); 
    body.append('msg',msg); 
    

    let res=await post("/web_contact_us",body,HeadersData,HeadersData);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}}
export async function app_review(experience,suggestions,order_id) {
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('experience',experience); 
    body.append('suggestions',suggestions); 
    body.append('order_id',order_id); 

    let res=await post("/submit_website_order_review_popup",body,HeadersData);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}}

export async function CategoryWiseProducts(category_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('category_hash_id', category_hash_id);
    body.append('page_number', page_number);  
    body.append('brand_filter', JSON.stringify(brand_filter));  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await post("/category_wise_products_list_for_app",body,HeadersData);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}}


export async function product_collection_items(collection_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('collection_hash_id', collection_hash_id);
    body.append('page_number', page_number);  
    body.append('brand_filter', JSON.stringify(brand_filter));  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await post("/product_collection_items",body,HeadersData);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}}
export async function BrandWiseProducts(brand_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('brand_hash_id', brand_hash_id);
    body.append('page_number', page_number);  
    body.append('sort_condition', sort_condition); 
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await post("/brand_products",body,HeadersData);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}}


export async function SearchProductsAPI(search_string,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  try {
    let body = new FormData();
    body.append('search_string', search_string);
    body.append('page_num', page_number);  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('selectedPrice', JSON.stringify(price_filter));   
    body.append('selectedDiscount', JSON.stringify(discount_filter));  
    body.append('selectedquantites', JSON.stringify(selected_qnt));  
    body.append('selectedFlavour', JSON.stringify(flavour_name));  
    body.append('selectedBrand', JSON.stringify(brand_filter)); 

    let res=await post("/universal_search_products_list_for_app",body,HeadersData);
    console.log(res,":universal_search_products_list_for_app")
    return res;
  } catch (e) {
    console.log('Error')
  }

}}
export async function UpdateFavStatusAPI(p_id) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('product_id', p_id); 
let res=await post("/add_or_remove_favorite",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function ClearUnavaialbeCartApiFunction() {   
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res=await post("/remove_unavaiable_items",{},HeadersData);
  console.log(res,":res")
return res;
}}

export async function EditContactAPIFunction(add_id,name,mobile) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('address_id', add_id);   
  body.append('name', name);   
  body.append('mobile_number', mobile);   
  let res=await post("/edit_address_contact",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function ReviewListAPI(p_id) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('item_id', p_id); 
let res=await post("/item_review_list",body,HeadersData);
  console.log(res,":res")
return res;
}}
export async function show_homepage_popup(value,order_number) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('is_sms', value); 
  body.append('order_number',order_number);
let res=await post("/check_order_review_popup",body,HeadersData);
  console.log(res,":res")
return res;
}}
export async function home_screen_order_packed_images() { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  
  let res=await post("/home_screen_order_packed_images",{},HeadersData);
    console.log(res,":res")
  return res;
  }}



export async function SubmitProductReview(item_id,rating,review,title,ReviewFiles){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  console.log(item_id,"item_id")
  console.log(rating,"rating")
  console.log(review,"review")  
  console.log(title,"title");
  console.log(ReviewFiles,"ReviewFiles[]")
  let body = new FormData();
  body.append('item_id', item_id);  
  body.append("rating",rating);
  body.append("review",review);
  body.append("title",title);
  ReviewFiles.forEach((file, index) => {
    body.append(`ReviewFiles[${index}]`, file);
  });

  let res = await post("/item_review",body,HeadersData);
  console.log(res, "item_review res")
  return res;
}}

export async function AverageRatingListApi(p_id) { 
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append('item_id', p_id); 
let res=await post("/customer_avg_review_ratings",body,HeadersData);
  console.log(res,":res")
return res;
}}

export async function UniversalSearchAPI(search_string){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("search_string",search_string);
  let res = await post("/universal_search",body,HeadersData);
  console.log(res,"/universal_search")
  return res;
}}

export async function DefaultSearchAPI(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let res = await post("/universal_search_default_page",{},HeadersData);
  console.log(res,"/universal_search_default_page")
  return res;
}}

export async function ClearTagsAPI(id){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("tag_id",id);
  let res = await post("/universal_search_delete_tag",body,HeadersData);
  console.log(res,"/universal_search_delete_tag")
  return res;
}}

export async function AddTagsAPI(product_name, reftype, refid){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("product_name",product_name);
  body.append("reftype",reftype);
  body.append("refid",refid);
  let res = await post("/add_search_product_name",body,HeadersData);
  console.log(res,"/add_search_product_name")
  return res;
}}


export async function CouponsListAPI(order_amount){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("order_amount",order_amount);
  let res = await post("/coupons_list",body,HeadersData);
  console.log(res,"/coupons_list")
  return res;
}}

export async function ApplyCouponApi(coupon_id){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("coupon_id",coupon_id);
  let res = await post("/apply_coupon",body,HeadersData);
  console.log(res,"/apply_coupon")
  return res;
}}

export async function RemoveCoupon(){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("coupon_id","0");
  let res = await post("/remove_coupon",body,HeadersData);
  console.log(res,"/remove_coupon")
  return res;
}}

export async function RepeatOrder(order_id){
  if(await CheckSessionValidity()){
    let HeadersData = await Headers();  
  let body = new FormData();
  body.append("order_id",order_id);
  let res = await post("/repeat_order",body,HeadersData);
  console.log(res,"RepeatOrder res")
  return res;
  }}
  export async function ItemWiseCoupons(product_id){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("product_id",product_id);
    let res = await post("/item_wise_coupons_list",body);
    console.log(res,"item_wise_coupons")
    return res;
  }}

  export async function UpdateAddressSelection(id){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("address_id",id);
    let res = await post("/address_mark_as_default",body,HeadersData);
    console.log(res,"/address_mark_as_default")
    return res;
  }}

  export async function NotifyMeApi(id,request_type){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("item_id",id);
    body.append("request_type",request_type);
    let res = await post("/add_item_request_by_item_id",body,HeadersData);
    console.log(res,"/add_item_request_by_item_id")
    return res;
  }}

  export async function FitPointDetails(fitpoint_id){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("fitpoint_id",fitpoint_id);
    let res = await post("/fitpoints_details",body,HeadersData);
    console.log(res,"/fitpoints_details")
    return res;
  }}
  
  export async function SubCategory(category_hash_id){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("category_hash_id",category_hash_id);
    let res = await post("/sub_categories",body,HeadersData);
    console.log(res,"sub_categories");
    return res;
  }}
  export async function fb_utm_parameter(fb_campaign_id){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("fb_campaign_id",fb_campaign_id);
    let res = await post("/get_utm_parameter_by_fb_campaign_id",body,HeadersData);
    console.log(res,"sub_categories");
    return res;
  }}
  // 
  export async function multiple_items_add_to_cart(product_ids){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();  
    let body = new FormData();
    body.append("product_ids",JSON.stringify(product_ids));
    let res = await post("/multiple_items_add_to_cart",body,HeadersData);
    console.log(res,"multiple_items_add_to_cart");
    return res;
  }}

  export async function AddItemRequest(item_name){
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();
    let body = new FormData();
    body.append("item_name",item_name);
    let res = await post("/add_item_request",body,HeadersData);
    console.log(res,"add_item_request");
    return res;
  }}

  export async function deleteuserimage() {
    if(await CheckSessionValidity()){
      let HeadersData = await Headers();
    let res=await post("/delete_user_image",{},HeadersData);
    console.log(res,":header_retrive_data")
    return res;
    }}

    export async function EditProfileDetails(name,email_id,date_of_birth,image,gender){
      if(await CheckSessionValidity()){
        let HeadersData = await Headers();  
      console.log(name,"name")
      console.log(email_id,"name")
      console.log(date_of_birth,"name")
      console.log(image,"image");
      let body = new FormData();
      body.append('name', name);
      body.append("email_id",email_id);
      body.append("date_of_birth",date_of_birth);
      body.append("mobile_number_country_id","1");
      body.append("image",image);
      body.append("gender",gender);
      
      let res = await post("/edit_profile",body,HeadersData);
      console.log(res, "editProfile res")
      return res;
      }
    }