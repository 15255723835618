import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ScratchCard from 'react-scratchcard-v2';
import { useLocation ,useParams} from 'react-router-dom';
import "../../SubCss/scratchcard.css";
import { scratch_card_details } from "../../Service/ApiCalling";
import image1 from '../../assets/images/scratchcard_orange.png';
import image2 from '../../assets/images/scrachcard_yellow.png';
import image3 from '../../assets/images/scratchcard_red.png';
import image4 from '../../assets/images/scratchcard_blue.png';

const images = [image1, image2, image3, image4];
const scratchBannerImage = images[Math.floor(Math.random() * images.length)];

 
function Scratchcarddetails() {
    const location = useLocation();
    const { pathname } = location;
  
    const { cardId } = useParams(); 
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate('/scratchcards');
    }
   
    const decodedURIComponent = decodeURIComponent(cardId);
    const decodedCardId = atob(decodedURIComponent); 
  

    try {
      const parsedObject = JSON.parse(decodedCardId);
      console.log(parsedObject, 'Decoded Object');
    } catch (error) {
      console.log('Error parsing the decoded string as JSON:', error);
    }
  
    console.log(decodedCardId, 'Decoded CardId (Base64)');
    const [cardDetails, setCardDetails] = useState(null);
  const[fitcash_amount , setfitcash_amount] =useState(null);
  const[description , setdescription] =useState(null);
    const fetchCardDetails = async (cardId) => {
     
      const details = await scratch_card_details(cardId);
      setCardDetails(details); 
      setdescription(details.scratch_card_details.description)
      setfitcash_amount(details.scratch_card_details.fitcash_amount)

    };
  
    useEffect(() => {
    //   if (pathname === "/scratchcards") {
    //     document.body.style.backgroundColor = "#F4F5FA";
    //   } else {
    //     document.body.style.backgroundColor = "white";
    //   }
  
     
      if (cardId) {
        console.log(cardId ,'HGHSGJH')
        fetchCardDetails(decodedCardId); 
      }
  
  
    }, [pathname, cardId]);

   
  
  
   
  return (
    <>
    
 <div className="mainCarousel d-none  d-lg-block container" style={{ paddingBottom: "0px", paddingTop: "10rem", borderRadius: '30px' }}>
  <Row>
    <Col lg={12} md={12} sm={12} col={12}>
      <div style={{ position: 'relative' }}>
        <img 
          src={require("../../assets/images/scratchpagebanner.png")} 
          alt="" 
          style={{ borderRadius: '20px', webkitUserDrag: "none", width: '100%', height:"100%" }} 
        />
        <div 
          style={{ 
            position: 'absolute',  
            left: '50%', 
            top: '50%', 
            transform: 'translate(-50%, -50%)', 
            color: '#FFFFFF', 
            fontSize: '16px', 
            fontWeight: '600',
            padding: '10px 15px', 
            borderRadius: '5px',
          
            display: 'flex', 
            flexDirection: 'column',  
            justifyContent: 'center',
            alignItems: 'center' 
          }}
        >
          <div style={{ fontSize: '20px', fontWeight:"700" }}>
            You Won!
          </div>
          <div style={{ fontSize: '27px',fontWeight:"700", display:"flex" }}>
           <span>₹ {Math.round(fitcash_amount)}</span><span ><img src={require("../../assets/images/wallet1.png")} alt="" style={{width:"35px"}}/></span>
          </div>
          <div style={{ fontSize: '20px',fontWeight:"700" }}>
            Fitcash
          </div>
        </div>
      </div>
    </Col>
  </Row>
</div>



<div className="mainCarousel d-block d-lg-none container" style={{ paddingBottom: "0px", paddingTop: "10rem", borderRadius: '30px', position: 'relative' }}>
  <Row>
    <Col lg={12} md={12} sm={12}>
      <div style={{ position: 'relative', display: "flex", justifyContent: "center" }}>
        <img 
          src={require("../../assets/images/mobilescratchcard.png")} 
          alt="" 
          style={{ borderRadius: '20px', webkitUserDrag: "none", width: '100%' }} 
        />
        <div 
          style={{ 
            position: 'absolute',  
            left: '50%', 
            top: '50%', 
            transform: 'translate(-50%, -50%)', 
            color: '#FFFFFF', 
            fontSize: '16px', 
            fontWeight: '600',
            padding: '10px 15px', 
            borderRadius: '5px',
          
            display: 'flex', 
            flexDirection: 'column',  
            justifyContent: 'center',
            alignItems: 'center' 
          }}
        >
          <div style={{ fontSize: '18px', fontWeight:"700",  }}>
            You Won!
          </div>
          <div style={{ fontSize: '27px',fontWeight:"700", display:"flex" }}>
           <span>₹ {Math.round(fitcash_amount)}</span><span ><img src={require("../../assets/images/wallet1.png")} alt="" style={{width:"30px"}}/></span>
          </div>
          <div style={{ fontSize: '18px',fontWeight:"700" }}>
            Fitcash
          </div>
        </div>
      </div>
    </Col>
  </Row>
</div>

<p 
            style={{
              fontSize: '12px',
              marginTop: '20px',
              color: '#676767',
              textAlign: 'center',
              padding: '10px 15px',
            }}
            dangerouslySetInnerHTML={{ __html: description }} 
          />
        <div className="container" style={{display:"flex", justifyContent:"center",marginBottom:"30px"}}>

      <Button 
    variant="primary" 
     onClick={handleGoBack}
    style={{
      backgroundColor: "#023047",
      color: "#FFFFFF",
      width: "23rem",
      borderRadius: "10px",
      fontFamily: "Montserrat",
      padding: "10px 30px",
      fontSize: "14px",
      fontWeight: "600",
      border: "none",
      cursor: "pointer"
    }}
  >
Go Back
  </Button></div>
     
    </>
  );
}

export default Scratchcarddetails;
