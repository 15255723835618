
import React from 'react';
import { BrowserRouter as Router, Route, Routes,  Link, useNavigate,useLocation, Navigate } from 'react-router-dom';
import Home from './Pages/Home';


import ProductDetails from './Pages/ProductDetails';
import Header from './MainComponents/Header';
import FooterJs from './MainComponents/FooterJs';
import Accounts from './MainComponents/Accounts';
import WriteReviewpopup from './MainComponents/WriteReviewPopup';
import Login from './MainComponents/Login';
import ProductsList from './Pages/ProductsList';
import GuestLogin, { HomePage } from './Service/ApiCalling';
import RegisterModal from './MainComponents/RegisterModal';
import AddAddressModal from './MainComponents/SubComponents/AddAddressModal';
import OrderDetails from './MainComponents/SubComponents/OrderDetails';
import SearchScreen from './MainComponents/SubComponents/SearchScreen';
import GoalsHome from './Pages/GoalsHome';
import EliteMembership from './Pages/EliteMembership';
import UniversalProductslist from './Pages/UniversalProductslist';
import AllReviews from './MainComponents/AllReviews';
import ReviewsList from './MainComponents/SubComponents/ReviewsList';
import Loginpage from './Pages/loginpage';
import Subcategorypage from './Pages/Subcategorypage';
import NutriEatsPage from './Pages/Nutrieats';
import HealthySnacks from './Pages/Healthysnacks';
import Agepage from './Pages/Agepage';
import LogoutModal from './MainComponents/SubComponents/LogoutModal';
import PeanutButterPage from './Pages/Peanutbutter';
import Productcollectionitem from './Pages/Product_collection_item';
import AboutJS from './MainComponents/SubComponents/Aboutus';
import ShippingPolicyJS from './MainComponents/SubComponents/ShippingPolicy';
import PrivacyJS from './MainComponents/SubComponents/Privacy';
import ReturnandRefundJS from './MainComponents/SubComponents/ReturnandRefund';
import TermsandConditionsJS from './MainComponents/SubComponents/TermsandConditions';
import ContactJS from './MainComponents/SubComponents/Contact';
import Afiliatepage from './MainComponents/SubComponents/Afiliatepage';
import Help from './MainComponents/SubComponents/Help';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BottomBar from './MainComponents/SubComponents/Bottom';
import Productcomparision from './MainComponents/SubComponents/Productcomparision.js'
import ReactGA from 'react-ga4';
import Push from 'push.js';
import Axios from 'axios';
import StoreloactorJS from './MainComponents/SubComponents/Storelocater';
import Blog from './MainComponents/SubComponents/Blogs'
import NotFound from './Pages/404page';
import RedirectToNewProduct from './MainComponents/SubComponents/Newredirect';
import BlogDetails from './MainComponents/SubComponents/BlogDetails.js';
import ReactPixel from 'react-facebook-pixel';
import Scratchcard from './MainComponents/SubComponents/Scratchcard.js';
import Scratchcarddetails from './MainComponents/SubComponents/Scratchcarddetails.js';

function App() {

  const [isDesktop, setIsDesktop] = React.useState(window.innerWidth > 768);
  const navigate = useNavigate(); 
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);

  const RetrieveWebNotification = async () => {
    try {
        const config = {
            method: 'post',
            url: process.env.NODE_ENV === 'production'
                ? 'https://app.nutsby.com/send_web_notifications'
                : '/send_web_notifications',
            headers: {
                'Client-Id': '7559cfa692227a068928964a23f54b5e70ab503',
            },
            data: {},
        };

        console.log('Sending request to:', config.url);
        const res = await Axios(config);
        console.log('Response received:', res.data);

        // Check for success response
        if (res.data.error === "0") {
            const notification = res.data.notification;
            if (notification && notification.title && notification.body) {
                return {
                    title: notification.title,
                    body: notification.body
                };
            } else {
                console.error('Invalid notification format:', res.data);
            }
        } else {
            console.error('Error in response:', res.data.message);
        }
    } catch (error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
    }
};



  const fetchNotification = async () => {
    // console.log('Fetching notification...');
    // setLoading(true);
    // // const response = await RetrieveWebNotification();
    // console.log('Received response:', response);
    // setLoading(false);

    // if (response && response.title && response.body) {
    //     console.log('Showing notification with title and body:', response.title, response.body);
    //     showNotification(response.title, response.body);
    // } else {
    //     console.log('Showing static notification...');
    //     // const staticNotification = {
    //     //     title: 'Scheduled Notification',
    //     //     body: 'This notification is sent  .',
    //     // };
    //     // showNotification(staticNotification.title, staticNotification.body);
    // }
};

const showNotification = (title, body) => {
  console.log('Attempting to show notification:', title, body);
  if (title && body) {
    Push.create(title, {
      body: body,
      timeout: 4000,
      onClick: function () {
        window.focus();
        this.close();
      },
    });
  } else {
    console.error('Invalid notification parameters:', title, body);
  }
};
const requestPermission = async () => {
  try {
      const permission = await Push.Permission.request();
      if (permission === 'granted') {
          console.log('Notification permission granted');
          await fetchNotification(); 
      } else {
          console.error('Notification permission denied');
      }
  } catch (error) {
      console.error('Error requesting notification permission:', error);
  }
};
React.useEffect(() => {
  

  // requestPermission(); 
  // const intervalId = setInterval(fetchNotification, 6000000); 
  // return () => clearInterval(intervalId); 
}, []);



  React.useEffect(() => {
   
    ReactGA.initialize('G-422066774');

    
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]); 

  React.useEffect(() => {
   
    ReactPixel.init('313701438318359'); 
  }, []); 
  React.useEffect(() => {
   
    ReactPixel.pageView();
  }, [location]); 

  React.useEffect(() => {
    const expiryTimestamp = localStorage.getItem('access_expiry_timestamp');
console.log("Stored access_expiry_timestamp:", expiryTimestamp);
const utm_source = localStorage.getItem("utm_source") ?? "N/A";
const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
    // if(!localStorage.getItem("access_expiry_timestamp")){
    //   GuestLogin(utm_source,utm_medium,utm_campaign);
      
    // }
    // HomePage();
    const access_token = localStorage.getItem("access_token");
    if(!access_token){
      // alert("Calling Guest");
      // GuestLoginAPICall();

      const GuestLoginAPIFunction=async ()=>{
        console.log(":Called GuestLogin API from APP Page");
        let data = await GuestLogin();
        console.log("Data GuestLogin:",data);
        if(data!=null){
            if(data.error==="0"){
              // localStorage.setItem('is_guest', '1');
              // localStorage.setItem('access_expiry_timestamp', data.access_expiry_timestamp); 
              // localStorage.setItem('access_token', data.access_token);
              // localStorage.setItem('refresh_token', data.refresh_token); 
              localStorage.setItem('is_guest', '1');
localStorage.setItem('access_expiry_timestamp', data.access_expiry_timestamp);
localStorage.setItem('access_token', data.access_token);
localStorage.setItem('refresh_token', data.refresh_token);
localStorage.setItem('refresh_token_expiry_timestamp', data.refresh_token_expiry_timestamp);
              
                console.log("Home Page API call from Home JS")
            }else if(data.error==="1"){
                console.log("error 1,Home Page");
            }
        }else{
            console.log("No Data Home Page");
        }
        
    }

    GuestLoginAPIFunction();
      }
    
    
    window.addEventListener('resize', handleResize);
    
    
    return () => window.removeEventListener('resize', handleResize);
    }, [isDesktop]);

    const handleResize = () => {
      const isDesktopNow = window.innerWidth > 768;
      if (isDesktopNow !== isDesktop) {
      setIsDesktop(isDesktopNow);
      window.location.reload();
      }
      };
  
 
  return (
    
    <>        
      {location.pathname !== "/mobile-login" && <Header />}
      
     
   
      <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/order-feedback/:id" element={<Home />} />

  <Route path="/profile" element={<Accounts />} />
  <Route path="/orders" element={<Accounts />} />
  <Route path="/order-details/:id" element={<Accounts />} />
  <Route path="/:name/:type/:id" element={<ProductsList />} />
  <Route path="/search-result/:searchstring" element={<UniversalProductslist />} />
  <Route path="/wishlist" element={<Accounts />} />
  <Route path="/search-result-mobile" element={<SearchScreen />} />
  <Route path="/fit-Cash" element={<Accounts />} />
  {/* <Route path="/maingoal/:name" element={<GoalsHome />} /> */}
  <Route path="/maingoal/muscle-building" element={<GoalsHome />} />
  <Route path="/maingoal/athelete-journey" element={<GoalsHome />} />
  <Route path="/maingoal/happy-healthy" element={<GoalsHome />} />
  <Route path="/maingoal/fitness-forever" element={<GoalsHome />} />
  <Route path="/hair-care-supplements" element={<Subcategorypage />} /> 
  <Route path="/skin-care-supplements" element={<Subcategorypage />} /> 
  <Route path="/eye-care-supplements" element={<Subcategorypage />} />
  <Route path="/sleep-support-supplements" element={<Subcategorypage />} />
  <Route path="/liver-health-supplements" element={<Subcategorypage />} />
  <Route path="/stress-relief-supplements" element={<Subcategorypage />} />
  <Route path="/digestion-supplements" element={<Subcategorypage />} />
  <Route path="/weight-loss-supplements" element={<Subcategorypage />} />
  <Route path="/weight-gain-supplements" element={<Subcategorypage />} />
  <Route path="/fresh-and-healthy" element={<Subcategorypage />} />
  <Route path="/healthy-snacks" element={<Subcategorypage />} />
  <Route path="/kids-8-7" element={<Subcategorypage />} />
  <Route path="/youngsters-18-27" element={<Subcategorypage />} />
  <Route path="/youth-28-37" element={<Subcategorypage />} />
  <Route path="/mid-age-38-50" element={<Subcategorypage />} />
  <Route path="/senior-citizens-above50" element={<Subcategorypage />} />
  {/* <Route path="/:name" element={<Subcategorypage />} /> */}
  <Route path="/product_details/:id/:name" element={<RedirectToNewProduct />} />
  <Route path="/rate-order/:id" element={<Accounts/>} />
  <Route path="/feedback-form/:id" element={<Accounts/>} />
  <Route path="/address-book" element={<Accounts />} />
  <Route path="/:name/p/:id" element={<ProductDetails />} />
  <Route path="/mobile-login" element={<Loginpage />} />
  <Route path="/nutrieats" element={<NutriEatsPage />} />
  <Route path="/peanut-butter" element={<PeanutButterPage />} />
  <Route path="/about-us" element={<AboutJS />} />
  <Route path="/shipping-policy" element={<ShippingPolicyJS />} />
  <Route path="/privacy-policy" element={<PrivacyJS />} />
  <Route path="/return-and-refund-policy" element={<ReturnandRefundJS />} />
  <Route path="/terms-and-conditions" element={<TermsandConditionsJS />} />
  <Route path="/contact-us" element={<ContactJS />} />
  <Route path="/afiliate" element={<Afiliatepage />} />
  <Route path="/Help/:id" element={<Help />} />
  <Route path="/store-locator" element={<StoreloactorJS/>} />
  <Route path="/blog-list" element={<Blog/>} />
  <Route path="/:title/blog/:id" element={<BlogDetails/>} />
  <Route path="/productcomparision" element={<Productcomparision/>} />
  <Route path="/scratchcards" element={<Scratchcard/>}/>
  <Route path="/EliteMembership" element ={<EliteMembership/>}/>
  <Route path="/scratchcardsdetails/:cardId" element={<Scratchcarddetails/>}/>
  <Route path="*" element={<NotFound />} />
</Routes>

   
     
      {location.pathname !== "/mobile-login" && <FooterJs />}
    </>
  );
}

export default App;