// RedirectToNewProduct.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductDetailsApi } from '../../Service/ApiCalling';

const RedirectToNewProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [HomeData, setHomeData] = useState([]);
  const [detailsData, setDetailsData] = useState(null);

  const fetchInfo = async (Product_nuin) => {
    try {
      const HomeDatatemp = await ProductDetailsApi(Product_nuin);
      if (HomeDatatemp.error === "0") {
        setHomeData(HomeDatatemp);
        setDetailsData(HomeDatatemp.details);
      } else {
        console.error('Error fetching product data:', HomeDatatemp);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchInfo(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (!loading && detailsData) {
      const newUrl = `/${detailsData.name}/p/${detailsData.nuin}`; 
      navigate(newUrl, { replace: true }); 
    }
  }, [loading, detailsData, navigate]);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return null; 
};

export default RedirectToNewProduct;

