import React, { useState } from "react";
import "../SubCss/SubscribeFormCss.css";
import { Container, Row, Col} from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import { emailsubscriptionsApi } from '../Service/ApiCalling';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SubscribeForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate email before making the API call
    if (!validateEmail(email)) {
      setError('Enter a valid email address');
      return;
    } else {
      setError(''); // Clear any previous error if email is valid
    }

    try {
      let data = await emailsubscriptionsApi(email);
      if (data.error === "0") {
        toast.success(data.message, {
          autoClose: 3000,
        });
      } else if (data.error === "1") {
        toast.error(data.message, {
          autoClose: 3000,
        });
      } else {
        toast.info(data.message, {
          autoClose: 3000,
        });
      }
      setEmail(''); // Clear the email field after a successful operation
    } catch (error) {
      toast.error('An unexpected error occurred.');
    }
  };

  return (
    <>
<section
  className="subscription_section"
  style={{ position: 'relative', overflow: 'hidden' }}
>
  
  <div
    className="form-image-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    }}
  >
    
    <div
      className="subscribe"
      style={{
        zIndex: 1,
      }}
    >
      <h2
        id="stayHome"
        style={{
          fontSize: '45px',
          fontFamily: 'Bebas Neue',
          color: '#023047',
          marginBottom: '10px',
        }}
      >
        STAY HOME & GET YOUR DAILY <br /> NEEDS FROM OUR SHOP
      </h2>
      <p
        className="nutstext1"
        id="startShop inter-regular"
        style={{ color: '#023047', fontWeight: '600' }}
      >
        Start Your daily shopping with{' '}
        <span
          className="nutstext2"
          id="nutsbytxt inter-regular"
          style={{
            color: '#219EBC',
            fontWeight: '700',
            paddingLeft: '5px',
          }}
        >
          NUTSBY
        </span>
      </p>
      <form
        className="row g-3"
        style={{
          paddingLeft: '0px',
          width: 'auto',
          maxWidth: '600px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control subscribeform inter-regular"
          id="inputPassword2"
          placeholder=" Your Email Address"
          style={{ flex: '1', marginRight: '10px', padding: '11px' }}
        />
        <button
          type="submit"
          className="btn  subscribebutton inter-regular"
          style={{ flexShrink: 0, padding: '10px 20px' }}
        >
          Submit
        </button>
      </form>
      {error && (
            <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
          )}
    </div>

    {/* Responsive Image */}
    <picture>
      <source
        media="(max-width: 767px)"
        srcSet={require('../assets/images/sss.png')}
      />
      <source
        media="(min-width: 768px)"
        srcSet={require('../assets/images/PNG/delivery.png')}
      />
      <img
        src={require('../assets/images/PNG/delivery.png')}
        alt="Subscription Background"
        className="subscription-image"
        style={{ width: '100%', height: 'auto', display: 'block', zIndex: 0 }}
      />
    </picture>
    <ToastContainer />
  </div>

  <style jsx>{`
    .form-image-container {
      display: flex;
      flex-direction: column-reverse;
    }
    .subscribebutton:hover{
      background-color: #ffb703 !important;
      border: 1px solid #FFB703 !important;
    }

    @media (min-width: 768px) {
      .form-image-container {
        flex-direction: column;
      }

      .subscribe {
        position: absolute;
        top: 35%;
        left: 30%;
        transform: translate(-50%, -50%);
      }
    }
   

    @media (max-width: 767px) {
      .subscribe {
        padding: 10px 40px 10px 40px !important;
      }

      .subscription-image {
        margin-top: 20px;
      }
    }
  `}</style>
</section>



    </>
  );
}
