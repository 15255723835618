import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Rating = ({ rating,width,height }) => {
  const stars = [];
  
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} style={{ width:width,height:height,color: 'gold' }} />);
    } else {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} style={{width:width,height:height, color: 'lightgray' }} />);
    }
  } 
  return <div>{stars}</div>;
};

export default Rating;
