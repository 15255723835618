import axios from "axios";
import { useState } from "react";
import { post } from "./PostCall";
import GuestLogin from "./ApiCalling";
import { app_id } from "./ApiNames";

export async function Headers() {
    let HeadersArray = {
        'Client-Id': app_id
    };
    const AccessToken = localStorage.getItem('access_token');
    if (AccessToken) {
        HeadersArray = {
            'Client-Id': app_id,
            'Authorization': `Bearer ${AccessToken}`
        };
    } else {
        HeadersArray = {
            'Client-Id': app_id
        };
    }

    return HeadersArray;
}

export async function CheckSessionValidity() {

    console.log("Header Validity Checking");
    let current_timestamp = Math.round(new Date() / 1000);
    console.log(current_timestamp, ":HeaderValidityCheck00");
    let access_token = localStorage.getItem('access_token');
    console.log(access_token, ":HeaderValidityCheck0");
    let refresh_token = localStorage.getItem('refresh_token');
    console.log(refresh_token, ":HeaderValidityCheck1");
    let access_expiry_timestamp = localStorage.getItem('access_expiry_timestamp');
    console.log(access_expiry_timestamp, ":HeaderValidityCheck2");
    let refresh_token_expiry_timestamp = localStorage.getItem('refresh_token_expiry_timestamp');
    console.log(refresh_token_expiry_timestamp, ":HeaderValidityCheck3");
    // alert(refresh_token_expiry_timestamp,":Session Validity");
    let HeadersData = await Headers();

    console.log(HeadersData, "Header Validity Check");

    if((Number(refresh_token_expiry_timestamp))>Number(access_expiry_timestamp)){
        
       
        console.log('step0');
        if (current_timestamp + 100 >= Math.round(Number(access_expiry_timestamp))) {
            
            console.log('step1');
            let body = new FormData();
            body.append('refresh_token', refresh_token);
            let response = await post('/refresh_customer_access_token', body,HeadersData);
            
            console.log(response,"RefreshTokenResponse");
                console.log(response.error,"RefreshTokenError");
            
            if (response!=null || response!=undefined || response!=="") {
                
                console.log('step2');
                
                
                if (response.error === "0") {
                    CallFunction();
                    
                    console.log("Session Validity Check");
                    localStorage.setItem('access_expiry_timestamp', response.access_expiry_timestamp);
                    localStorage.setItem('access_token', response.access_token);
                    localStorage.setItem('refresh_token', response.refresh_token);
                    localStorage.setItem('refresh_token_expiry_timestamp', response.refresh_token_expiry_timestamp);
                    return true;
                } 
                 if (response.error === "1") {
                    console.log('step3');
                    console.log("Refresh token Invalid");
                    // if(await CheckSessionValidity()){
                        console.log('step4');
                        // window.location.reload();
                        // GuestLoginAPICall();
                        return true;
                    // }else{
                    //     console.log('step5');
                    //     LogoutAPICall();
                    //     return false;
                    // }
                    
                }
            } else {
                console.log('step6');
                return false;
            }

    
        } else {
            console.log('step7');
            return true;
    
        }
    }else{
        console.log('step8');
        
            // window.location.reload();
            localStorage.clear();
            GuestLogin();
        
            
            return true;
        
        
        
    }
    

}


export async function CallFunction(){
    
    localStorage.setItem('access_token', "");
    localStorage.setItem('access_expiry_timestamp', "");

}