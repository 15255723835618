import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, Image, Badge, Spinner } from 'react-bootstrap';
import { EditProfileDetails, ProfileDetails,GetHeaderDetailsApi,deleteuserimage } from '../../Service/ApiCalling';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ShimmerDiv, ShimmerTable, ShimmerTitle } from 'shimmer-effects-react';
import { ToastContainer, toast } from 'react-toastify';
import "../../SubCss/profile.css"
import { useCart } from "./MiniComponents/CartContext";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation} from 'react-router-dom';

function Profile() {
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [dob, setDOB] = useState(null)
  const [email, setEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); 
  const [profile_details, setProfileDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const {setheadername,setimage} = useCart();
  const location = useLocation();
  const { pathname } = location;
  const getDetails = async () => {
    try {
      let profileDetailsData = await ProfileDetails();
      if (profileDetailsData.error === "0") {
        setLoading(false);
        setProfileDetails(profileDetailsData.details);
        setName(profileDetailsData.details.name);
        setEmail(profileDetailsData.details.email_id);

        // Handle invalid date format
        const dateOfBirth = profileDetailsData.details.date_of_birth;
        setDOB(dateOfBirth !== "0000-00-00" ? new Date(dateOfBirth) : null);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
      toast.error("Failed to fetch profile details.", { autoClose: 3000 });
    }
  };

  
const [activeButton, setActiveButton] = useState('');

  // Use useEffect to set the activeButton when profile_details.gender is available
  useEffect(() => {
    if (profile_details?.gender) {
      setActiveButton(profile_details.gender);
    }
  }, [profile_details]);

  // State management for the "Other" input field
  const [otherType, setOtherType] = useState('');
  const [otherTypeError, setOtherTypeError] = useState('');

  // Function to handle button clicks
  const handleButtonClick = (button) => {
    setActiveButton(button);
    if (button !== 'Other') {
      // Clear otherType state if any button other than "Other" is clicked
      setOtherType('');
    }
  };
  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    let image = document.getElementById('image');
    if (image) {
      image.src = URL.createObjectURL(file);
    }
  };

  // Handle date change
  const handleDateChange = (date) => {
    setDOB(date);
  };
  const handleRemovePhoto = async () => {
   
    const res = await deleteuserimage();  
    if (res.success) {
      getDetails()
      const headerData = await GetHeaderDetailsApi();
      if (headerData.error === "0") {
        setheadername(headerData.user_name);
        setimage(headerData.user_profile);
      }
  
      console.log("Profile photo removed");
    } else {
      console.log("Failed to remove photo");
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false || !dob) {
      event.stopPropagation();
      toast.error("Please fill in all required fields, including a valid Date of Birth.", { autoClose: 3000 });
    } else {
      try {
        let submitDetails = await EditProfileDetails(name, email, dob ? dob.toISOString() : null, selectedFile,activeButton);
        if (submitDetails.error === "0") {
          toast.success(submitDetails.message, { autoClose: 3000 });
          setLoading(false);

          const headerData = await GetHeaderDetailsApi();
          if (headerData.error === "0") {
            setheadername(headerData.user_name);
            setimage(headerData.user_profile);
          }
        } else {
          toast.error(submitDetails.message, { autoClose: 3000 });
        }
      } catch (error) {
        console.error("Error submitting details:", error);
        toast.error("Failed to submit details.", { autoClose: 3000 });
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    getDetails();
  }, []);

  if(!loading){
    return (
      <>
  
        <div>
        <Row className='container' >
                <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb1' style={{marginBottom: "0px",paddingLeft:"0px" ,paddingTop: "6rem", }}>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      role='button'
                      onClick={() => {
                        console.log("Breadcrumb clicked");
                        window.location.href = `/`;
                      }}
                    >
                      Home
                    </Breadcrumb.Item>
                    {pathname === "/profile" && (
                      <Breadcrumb.Item href="#" style={{color:"#2D2D2D"}} className="order-breadcrumb">
                       Profile
                      </Breadcrumb.Item>
                    )}
                  </Breadcrumb>
                </Col>
              </Row>
          <Container className=''>
            
            <Row>
  <div
    className="mainCarousel d-none d-md-block d-lg-block container"
    style={{ paddingBottom: "0px", borderRadius: "30px" }}
  >
    <Row>
      <Col lg={12} md={12} sm={12} col={12} style={{display:"flex",justifyContent:"center"}}>
        <img
          src={require("../../assets/images/userprofile.png")}
          alt=""
          style={{ borderRadius: "20px", webkitUserDrag: "none", width: "100%" }}
        />
      </Col>
    </Row>
  </div>
  <div
    className="mainCarousel d-block d-md-none d-lg-none d-sm-block container"
    style={{ paddingBottom: "0px", paddingTop: "1rem" }}
  >
    <Row>
      <Col lg={12} md={12} sm={12} col={12} style={{display:"flex",justifyContent:"center"}}>
        <img
          src={require("../../assets/images/userprofile_mb.png")}
          alt=""
          style={{ borderRadius: "18px", webkitUserDrag: "none", width: "100%" }}
        />
      </Col>
    </Row>
  </div>

  <Col className="d-flex profile mt-20 ">
  <div  className="positioning"
  
  >
    <Image
      src={profile_details.profile_image_dir_file_path}
      className="profile_logo-img"
      style={{
       
      }}
      alt="Profile"
      id="image"
      roundedCircle
    />
    <div>
      <label htmlFor="file-upload">
        <input
          id="file-upload"
          type="file"
          name="profile_picture"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Container
          className="d-flex justify-content-center"
          style={{}}
          alt="Corner"
        >
          <div style={{ color: "#219EBC", fontWeight: "600",fontSize:"14px" }}>Change Photo</div>
        </Container>
      </label>
      
      {/* Remove Photo Button */}
      <Container
        className="d-flex justify-content-center"
       
      >
        <div
          style={{
            color: "red",       // Set text color to red
            fontWeight: "600",  // Make it bold
            cursor: "pointer",  // Show pointer cursor on hover
            fontSize:"14px"
          }}
          onClick={handleRemovePhoto} // Add the onClick function to handle photo removal
        >
          Remove Photo
        </div>
      </Container>
    </div>
  </div>
</Col>

</Row>

  
              <Col lg={12} md={12} sm={12} xs={12}  className='container' >
                
                <div style={{color:'#676767',fontSize:"16px",fontWeight:"700",marginBottom:'10px',marginTop:"132px"}}>User Information</div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row  style={{background:"white",borderRadius:"21px",padding:"20px 8px"}}>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        {/* <Form.Label style={{fontFamily:"Inter",fontWeight:'600'}}>Name</Form.Label> */}
                        <Form.Control
                          required
                          name="name"
                          type="text"
                          className="form-control custom-placeholder"
                          placeholder="Enter your name"
                          style={{ backgroundColor: "#F5F5F5",border:'none',color:"#2D2D2D",fontSize:"14px" }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          maxLength={20}
                          // pattern="[a-zA-Z\s]+"
                          title="Name should be alphanumeric (letters and numbers only)"
                        />

                        <Form.Control.Feedback type="invalid" >
                         Name should be alphanumeric (letters and numbers only).
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {/* <Form.Label style={{fontFamily:"Inter", fontWeight:'600'}}>Email Address</Form.Label> */}
                        <Form.Control
                          required
                          name="email"
                          type="text"
                          className="form-control custom-placeholder"
                          placeholder="Enter your e-mail id"
                          pattern="\S+@\S+\.\S+"
                          style={{ backgroundColor: "#F5F5F5",border:"none",color:"#2D2D2D",fontSize:"14px" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                          Please provide a valid email address (e.g., user@example.com).
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
  <Form.Group className="mb-3" controlId="formBasicDob">
    {/* <Form.Label style={{ fontFamily: "Inter", fontWeight: '600' }}>Date Of Birth</Form.Label> */}
    <div className="datepicker-wrapper">
      <DatePicker
        required
        name="date"
        className={`form-control custom-placeholder ${!dob && validated ? 'is-invalid' : ''}`} 
        placeholderText="Enter your date of birth"
        style={{ backgroundColor: "#F5F5F5",  border: 'none',color:"#2D2D2D",fontSize:"14px" }}
        selected={dob}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
      />
      {!dob && validated &&(
        <div className="invalid-feedback1" style={{  }}>
          Please provide your date of birth.
        </div>
      )}
    </div>
    {/* <Form.Control.Feedback type="invalid" style={{ fontFamily: "Inter" }}>
      Please provide your date of birth.
    </Form.Control.Feedback> */}
  </Form.Group>
</Col>

                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="mb-1" controlId="formBasicPhone">
                        {/* <Form.Label style={{fontFamily:"Inter", fontWeight:'600'}}>Phone No.</Form.Label> */}
                        <Form.Control
                          readOnly
                          type="tel"
                          className="form-control custom1-placeholder"
                          value={profile_details.mobile_number}
                          placeholder="Enter your phone no."
                          maxLength={10}
                          pattern="[6-9]{1}[0-9]{9}"
                          style={{ backgroundColor: "#F5F5F5",border:'none',color:"#2D2D2D",fontSize:"14px" }}
                        />
                      </Form.Group>
                    </Col>
                   
                  </Row>
                  <div style={{color:'#676767',fontSize:"16px",fontWeight:"700",marginBottom:'10px',marginTop:"20px"}}>Gender</div>
                  <Row  style={{background:"white",borderRadius:"17px",padding:"20px 8px"}}>
                
                 
  <Col>
    <Button
      variant="primary"
      className="male"
      style={{
        backgroundColor: activeButton === 'Male' ? "#EBFBFF" : "#fff",
        borderRadius: "10px",
        display: "inline-flex",
        marginRight: "5px",
        padding:"6px 15px",
        fontSize: "14px",
        alignItems: "center",
        color: activeButton === 'Male' ? "#219EBC" : "#2D2D2D",
        borderColor: activeButton === 'Male' ? "#219EBC" : "rgb(244, 245, 250)"
      }}
      onClick={() => handleButtonClick('Male')}
    >
      <span>Male</span>
    </Button>

    <Button
      variant="primary"
      className="female"
      style={{
        backgroundColor: activeButton === 'Female' ? "#EBFBFF" : "#fff",
        borderRadius: "9px",
        display: "inline-flex",
        marginRight: "5px",
        padding:"6px 15px",
        fontSize: "14px",
        alignItems: "center",
        color: activeButton === 'Female' ? "#219EBC" : "#2D2D2D",
        borderColor: activeButton === 'Female' ? "#219EBC" : "rgb(244, 245, 250)"
      }}
      onClick={() => handleButtonClick('Female')}
    >
      <span>Female</span>
    </Button>

    <Button
      variant="primary"
      className="others"
      style={{
        backgroundColor: activeButton === 'NA' ? "#EBFBFF" : "#fff",
        borderRadius: "10px",
        display: "inline-flex",
        marginRight: "5px",
        fontSize: "14px",
        padding:"6px 15px",
        alignItems: "center",
        color: activeButton === 'NA' ? "#219EBC" : "#2D2D2D",
        borderColor: activeButton === 'NA' ? "#219EBC" : "rgb(244, 245, 250)"
      }}
      onClick={() => handleButtonClick('NA')}
    >
      <span>Others</span>
    </Button>
  </Col>



                  

                  
                  </Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                      <Row xl={5} lg={3} md={2} sm={2} xs={12} style={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
                        <Button variant="primary" type="" style={{ backgroundColor: "#023047", color: "white", borderRadius: "10px", margin: "14px",padding:"10px"}} align="end" >
                          Save
                        </Button>
                      </Row>
                    </Col>
                </Form>
              </Col>
  
            
            <ToastContainer />
          </Container>
          <Container className='mt-100' />
        </div>
  
      </>
    )
  }else{
    return (
    <Container className="mt-20" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
            
      <Row className="profile d-flex">
          <Col  xs={5} sm={5} md={4} lg={2}>
          <ShimmerDiv mode="light"  height={150} width={150} rounded={50} />

          </Col>
      </Row>
  <Row>
  <ShimmerTable mode="light" row={2} col={2} border={0} borderColor={"#cbd5e1"} rounded={0.25} rowGap={0} colPadding={[10, 5, 10, 5]} />
  
  
  </Row>      
   
  
</Container>);
  }
}


export default Profile