import axios from 'axios';
import { RefershHeaders } from './HeaderValidity';
const baseURL = process.env.REACT_APP_API_URL;

// export async function CallApi(url,body){
//     try{
//       let current_timestamp=Math.round(+new Date()/1000);
//     let headerdata={  
//         'Client-Id': "7559cfa692227a068928964a23f54b5e70ab503"
//       }
//     if(localStorage.getItem('access_token'))
//     {
//         headerdata={  
//             'Client-Id': "7559cfa692227a068928964a23f54b5e70ab503",
//             'Authorization':"Bearer "+localStorage.getItem('access_token')
//           }
//     }

//     if(((current_timestamp+100)>=(localStorage.getItem('access_expiry_timestamp'))))
//     {
//         await RefershHeaders();  
//         headerdata={  
//             'Client-Id': "7559cfa692227a068928964a23f54b5e70ab503",
//             'Authorization':"Bearer "+localStorage.getItem('access_token')
//         } 
//     }

//     let config = {
//         method : "post",
//         mode: 'no-cors',
//         maxBodyLength: Infinity,
//         url : process.env.NODE_ENV==="production"?`${baseURL}${url}`:`${url}`,
//         headers : headerdata,
//         data :  body
//     }


//     return await axios(config).then(res => res.data);
//     }catch(error){
//       if (error.response) {

//         console.log('Server responded with an error:', error.response.data);
//       } else if (error.request) {

//         console.log('No response received:', error.request);
//       } else {

//         console.log('Error setting up request:', error.message);
//       }
//     }
// }

export async function post(url, body, headers) {
  let response;
  try {

    response = await axios.post(process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_URL}${url}` : `${url}`, body, {
      headers: headers
    }).then(res => res.data);
    console.log(response, "post Call");

  } catch (error) {
    console.log(error, "POST request error");
  }
  return response;
}


