import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Thread, Window } from 'stream-chat-react';
import { GetHeaderDetailsApi } from '../../Service/ApiCalling';
import 'stream-chat-react/dist/css/v2/index.css';

function StreamChatApp({ defaultMessage }) {
  const [channel, setChannel] = useState(null);
  const [streamchatAppId, setStreamchatAppId] = useState(''); 
  const [streamchatChannelId, setStreamchatChannelId] = useState('');
  const [streamchatClient, setStreamchatClient] = useState(null); 
  const [streamchatToken, setStreamchatToken] = useState(''); 
  const [streamchatUserId, setStreamchatUserId] = useState('');
  const [user_name, setUser_name] = useState('');
  const [user_profile, setUser_profile] = useState('');

  useEffect(() => {
    const fetchHeaderData = async () => {
      const headerData = await GetHeaderDetailsApi();
      if (headerData.error === "0") {
        setStreamchatAppId(headerData.streamchat_app_id);
        setStreamchatChannelId(headerData.streamchat_channel_id); 
        setStreamchatClient(StreamChat.getInstance(headerData.streamchat_client)); 
        setStreamchatToken(headerData.streamchat_token); 
        setStreamchatUserId(headerData.streamchat_user_id); 
        setUser_name(headerData.user_name);
        setUser_profile(headerData.user_profile);
      }
    };

    fetchHeaderData();
  }, []);

  useEffect(() => {
    const connectUser = async () => {
      if (streamchatClient && streamchatToken && streamchatUserId) {
        await streamchatClient.connectUser(
          {
            id: streamchatUserId,
            name: user_name, 
            image: user_profile, 
          },
          streamchatToken
        );

        const channel = streamchatClient.channel('messaging', streamchatChannelId, {
          name: 'General Channel',
        });
        setChannel(channel);
      }
    };

    connectUser();

    return () => {
      if (streamchatClient) {
        streamchatClient.disconnectUser();
      }
    };
  }, [streamchatClient, streamchatToken, streamchatUserId, streamchatChannelId, user_name, user_profile]);

  if (!channel) {
    return <div style={{ color: "white" }}>Loading...</div>;
  }

  return (
    <div>
      <Chat client={streamchatClient} theme="messaging light">
        <Channel channel={channel}>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput Input={(props) => <MessageInput {...props} defaultValue={defaultMessage} />} />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  );
}

export default StreamChatApp;
