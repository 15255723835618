import React, { useCallback,useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css"; 
import { Container, Row, Col, Button, Breadcrumb, Form,Offcanvas,Image } from 'react-bootstrap';
import { compare_products,AddToCart,GetHeaderDetailsApi,compare_similar_products,search_compare_similar_products,ClearTagsAPI,AddTagsAPI,DefaultSearchAPI } from '../../Service/ApiCalling';
import { useLocation } from 'react-router-dom'; 
import { useCart } from "./../../MainComponents/SubComponents/MiniComponents/CartContext";
 import { ToastContainer, toast } from 'react-toastify';
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import { debounce } from "@mui/material";
 import styled from 'styled-components';
 
import { faStar,faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import SvgIcons,{DiscountIcon,} from "../../MiniComponents/SvgIcons";
function Productcomparision() {
    const location = useLocation();
    const { pathname } = location;
    const [prods,setProdsData] = useState([]);
    const user = location.state?.user;
    const { setCartCount } = useCart();
    const [comparesimilarproduct ,  setcomparesimilarproducts] = useState([]);
    const [comparesimilarheadings ,  setcomparesimilarheadings] = useState([]);
    const [addClicked,setAddClicked] = useState(null);
    const [isloading,setIsLoading] = useState(true);
    const [reviewsShow, setreviewsShow] = React.useState(false);
    const handleReviewsClose = () => setreviewsShow(false);
    const handleReviewsShow = () => setreviewsShow(true);
    const [similarproduct ,  setsimilarproducts] = useState([]);
    const [similarheadings ,  setimilarheadings] = useState([]);
 
const [productIdsToCompare, setProductIdsToCompare] = useState(() => {
  const storedIds = JSON.parse(localStorage.getItem('productIds')) || [];
  return storedIds;
});


const handleCompare = (id) => {
  comparesimilarproducts(id);
};

const comparesimilarproducts = async (productId) => {
  console.log(productId, ":fromCompare");

 
  let productIds = JSON.parse(localStorage.getItem('productIds')) || [];

  
  if (productId != null && !productIds.includes(productId)) {
      productIds.push(productId);
      localStorage.setItem('productIds', JSON.stringify(productIds)); // Save to localStorage
      setProductIdsToCompare(productIds); // Update state
  }

  
  const uniqueProductIds = Array.from(new Set(productIds.filter(id => id != null)));

  try {
      let compare_productsResponse = await compare_products(uniqueProductIds);
      
      if (compare_productsResponse.error === "0") {
          setcomparesimilarproducts(compare_productsResponse.details);
          setcomparesimilarheadings(compare_productsResponse.headings);
          console.log('Similar products:', compare_productsResponse.details);
      } else if (compare_productsResponse.error === "1") {
          setcomparesimilarproducts([]);
          
      } else {
          console.error('Error fetching similar products:', compare_productsResponse.error);
      }
  } catch (error) {
      console.error('Error fetching similar products:', error);
  }
};


const handleDelete = async (productId) => {
  console.log(productId, ":fromCompare delete");

  let productIds = JSON.parse(localStorage.getItem('productIds')) || [];

  productIds = productIds.filter(id => id !== productId);
  

  localStorage.setItem('productIds', JSON.stringify(productIds)); 
  setProductIdsToCompare(productIds); 

  const uniqueProductIds = Array.from(new Set(productIds.filter(id => id != null)));

  try {
      let compare_productsResponse;
      if (uniqueProductIds.length > 0) {
          compare_productsResponse = await compare_products(uniqueProductIds);
      }

      if (compare_productsResponse?.error === "0") {
          setcomparesimilarproducts(compare_productsResponse.details);
          setcomparesimilarheadings(compare_productsResponse.headings);
          console.log('Similar products:', compare_productsResponse.details);
      } else if (compare_productsResponse?.error === "1") {
          setcomparesimilarproducts([]);

      } else {
          console.error('Error fetching similar products:', compare_productsResponse.error);
      }
  } catch (error) {
      console.error('Error fetching similar products:', error);
  }
};

const similarproducts = async () => {
 
  const productIds = JSON.parse(localStorage.getItem('productIds[0]')) || [];

 
  if (productIds.length === 0) {
      console.error('No product IDs available for comparison.');
      return; 
  }

  try {
     
      let compare_similar_productsResponse = await compare_similar_products(productIds[0]);

     
      if (compare_similar_productsResponse.error === "0") {
          setsimilarproducts(compare_similar_productsResponse.details);
          setimilarheadings(compare_similar_productsResponse.headings);
          console.log('Similar products:', compare_similar_productsResponse.details);
      } else {
          console.error('Error fetching similar products:', compare_similar_productsResponse.error);
      }
  } catch (error) {
      console.error('Error fetching similar products:', error);
  }
};




    
    const comparebtn = styled.button`
    border: 0.25px solid #FFB703;
    background-color: #FFB703;
    color: black;
    width: auto;
    height: auto;
    font-size: 18px;
    border-radius: 42px; 
    font-weight: 500;
    cursor: pointer;
    display: flex; 
    align-items: center;
    justify-content: center;
    padding: 0 15px; 
    box-sizing: border-box; 
    
    /* Optional: If you want to ensure text doesn't overflow */
    overflow: hidden;
    text-overflow: ellipsis;
    `;
    const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
        if(clicked === true){
            
          setAddClicked(index);
        }
        // AddToCart 
        // console.log(qnt)
        // if(qnt>0)
        //     {
          const utm_source = localStorage.getItem("utm_source") ?? "N/A";
const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
          
              let addcartDatatemp = await AddToCart(p_id,qnt,utm_source,utm_medium,utm_campaign);
                if (addcartDatatemp.error === "0") {
                  toast.success(addcartDatatemp.message, {
                    autoClose: 3000,
                  });
                    // fetchInfo();  
                        const newList = comparesimilarproduct.map((item) => {
                            if (item.product_id === p_id) {
                              const updatedItem = {
                                ...item,
                                product_total: addcartDatatemp.product_total,
                                product_total_mrp: addcartDatatemp.product_total_mrp,
                                cart_quantity: addcartDatatemp.cart_quantity,      
                              };
                              setTimeout(function() {
                  
                                setAddClicked(null);
                                
                              }, 1300);
                      
                              return updatedItem;
                            } 
                            return item;
                          });
                      
                          setcomparesimilarproducts(newList); 
                        
                  const headerData = await GetHeaderDetailsApi();
                    if (headerData.error === "0") {
                      if(headerData.cart_count!=null){
                        setCartCount(headerData.cart_count);
                        
                      }else{
                        setCartCount(0);
                      }
                      
                      // console.log(headerData.cart_count,"khfaosdufhuafs") 
                    }
                }
            // }
        
      };
      
      const [searchString,setSearchString] =React.useState("");
      const universal_search=async (searchvalue)=>{
        if(searchvalue.length>0){
          let data=await search_compare_similar_products(searchvalue);
          if(data.error=="0"){  
           
            setProdsData(data.products);
         
          }else{
           
            setProdsData([]);
          }
        }
      }
    
      const debouncedSearch = useCallback(
        debounce((value) => handleUniversalSearch(value), 300),
        []
      );
    const handleUniversalSearch=async (searchString)=> { 
        setSearchString(searchString);
        universal_search(searchString);
        setIsLoading(false);
      };
      const ClearTagsAPIFunction=async (id)=>{
        let data=await ClearTagsAPI(id);
        if(data.error=="0"){
          DefaultSearchPageAPIFunction();
        }
      }
    
      const AddTagsAPIFunction=async (product_name,reftype,refid)=>{
        let data=await AddTagsAPI(product_name,reftype,refid);
        if(data.error=="0"){
        }
      }
      const DefaultSearchPageAPIFunction=async ()=>{  
        let data=await DefaultSearchAPI();
        if(data.error=="0"){
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      } 
    
      const gotToNewPagesss = () => {
        // navigate("/membership_details");
        // window.location.href = `/membership_details`;
      }

    useEffect(() => {
        if (pathname === "/productcomparision") {
            document.body.style.backgroundColor = "#F4F5FA";
        } else {
            document.body.style.backgroundColor = "white";
        }
        comparesimilarproducts();
        similarproducts();
        // comparesimilarproducts1();
    }, [pathname]);

    
  

    return (
        <>
           <section className="pdsection2" style={{ marginTop: "10rem",marginBottom:"10rem" }}>
    
    <Container style={{  borderRadius: "15px", padding: '0px' }}>
    <h5 style={{ color: "#676767", fontWeight: "700", fontSize: "16px" }}>Product Comparison</h5>
    <div style={{ padding: '0px',backgroundColor: "white",borderRadius: "15px",  }}>
        <div style={{ overflowX: 'auto', borderRadius: '5px', marginBottom: "20px" }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, borderBottom: '1px solid #ddd' }}>
                    <tr>
                        <th style={{
                            textAlign: 'left',
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'white',
                            zIndex: 2,
                            borderRight: '1px solid #ddd',
                            color: "#2D2D2D",
                            fontSize: "12px",
                            padding: '15px 20px',
                            minWidth:"130px",
                            width:"130px"
                        }}>
                       <div className="dashed-border-box" onClick={handleReviewsShow} style={{ borderRadius: "12px" }}>
                    <Row>
                        <div style={{ textAlign: 'center', padding: '0px' }}>
                            <div style={{ fontSize: '24px' }}>+</div>
                            <div style={{ fontSize: '12px', color: "#219EBC" }}>
                                Add items <br /> to compare
                            </div>
                        </div>
                    </Row>
                </div>
                        </th>
                       
                        {comparesimilarproduct.map((product) => (
    <th key={product.product_id} style={{ textAlign: 'center', padding: '15px', position: 'relative', minWidth: '150px',width:"150px", height: '100px'}}>
   <div style={{ background: "#FFEDED", borderRadius: '5px',  position: 'absolute',
            top: '5px',
            right: '3px', padding: '10px' }}>
    <svg
        onClick={() => handleDelete(product.product_id)}
        width="20" 
        height="20" 
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            display:"flex",
            alignItems:"center",
            cursor: 'pointer',
        }}
    >
        <g clipPath="url(#clip0_66_2143)">
            <path d="M13.125 2.8396C12.0779 2.8396 11.0307 2.8396 9.98362 2.8396L9.73438 1.59373C9.62588 1.04817 9.14266 0.6521 8.58637 0.6521H5.41362C4.85734 0.6521 4.37413 1.04817 4.26562 1.59334L4.01638 2.8396C2.96927 2.8396 1.92211 2.8396 0.875 2.8396C0.633194 2.8396 0.4375 3.03551 0.4375 3.2771C0.4375 3.51869 0.633194 3.7146 0.875 3.7146H1.34877L2.08626 12.2484C2.16615 13.12 2.88562 13.7771 3.76062 13.7771H10.2394C11.1144 13.7771 11.8338 13.12 11.9137 12.2463L12.6512 3.7146H13.125C13.3668 3.7146 13.5625 3.51869 13.5625 3.2771C13.5625 3.03551 13.3668 2.8396 13.125 2.8396ZM5.12352 1.76466C5.15086 1.62707 5.27306 1.5271 5.41362 1.5271H8.58637C8.72694 1.5271 8.84914 1.62707 8.87648 1.76506L9.09138 2.8396H4.90862L5.12352 1.76466ZM11.0422 12.1689C11.0041 12.5868 10.6589 12.9021 10.2394 12.9021H3.76062C3.34106 12.9021 2.99587 12.5868 2.95781 12.1711L2.22679 3.7146H11.7732L11.0422 12.1689Z" fill="#FF0000"/>
            <path d="M8.3125 11.1521H5.6875C5.44569 11.1521 5.25 11.348 5.25 11.5896C5.25 11.8312 5.44569 12.0271 5.6875 12.0271H8.3125C8.55431 12.0271 8.75 11.8312 8.75 11.5896C8.75 11.348 8.55431 11.1521 8.3125 11.1521Z" fill="#FF0000"/>
        </g>
        <defs>
            <clipPath id="clip0_66_2143">
                <rect width="14" height="14" fill="white" transform="translate(0 0.2146)"/>
            </clipPath>
        </defs>
    </svg>
</div>

        <div style={{display:"flex",alignItems:"center" }}>
           <div><img src={product.default_image} alt={product.short_name} style={{background: "#F3F3F3" ,borderRadius: "8px"}} /></div> 
            
       
            
        </div>
        <div style={{
            fontSize: '12px',
            color: '#999999',
            marginTop: '5px',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            
        }}>{product.short_name}</div>
        
        <button
            onClick={() => AddToCartCall(product.product_id, (Number(product.cart_quantity) + 1))}
            style={{
                marginTop: '10px',
                padding: '5px 10px',
                fontSize: '12px',
                backgroundColor: '#F7FDFF',
                color: '#219EBC',
                border: '0.5px solid #219EBC',
                borderRadius: '5px',
                cursor: 'pointer'
            }}>
            Add to Cart
        </button>
    </th>
))}

                        {/* Additional columns for more comparison options */}
                        {[...Array(7)].map((_, index) => (
                            <th key={`additional-column-${index}`} style={{ textAlign: 'center', padding: '10px' ,borderBottom: '1px solid #ddd',}}>
                                <div className="" style={{  textAlign: 'center', padding: '10px' }}>
                                  -
                                </div>
                                
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
   
    {comparesimilarheadings.map((heading) => (
        <tr key={heading}>
            <td style={{
                padding: '15px 20px',
                fontWeight: 'bold',
                borderBottom: '1px solid #ddd',
                position: 'sticky',
                left: 0,
                backgroundColor: 'white',
                zIndex: 1,
                borderRight: '1px solid #ddd',
                color: "#2D2D2D",
                fontSize: "12px"
            }}>
                {heading}
            </td>
            {comparesimilarproduct.map((product) => (
                <td key={product.product_id} style={{ padding: '10px', borderBottom: '1px solid #ddd', fontSize: '12px', color: '#999999', textAlign: 'center', fontWeight: '600' }}>
                    {heading === "Category" ? product.category_name || '-' :
                        heading === "Rating" ? product.Rating ? (
                            <>
                                <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} /> {product.Rating}
                            </>
                        ) : '-' :
                        heading === "Brand" ? product.brand_name || '-' :
                        heading === "Flavour" ? product.Flavour || '-' :
                        heading === "Serving Days" ? product.ServingDays || '-' :
                        heading === "Ingredients" ? product.Ingridients || '-' :
                        heading === "Diet" ? product.Diet || '-' :
                        product[heading.toLowerCase()] || '-'} 
                </td>

            ))}
            {[...Array(7)].map((_, index) => (
                            <th key={`additional-column-${index}`} style={{ textAlign: 'center', padding: '10px' ,borderBottom: '1px solid #ddd',}}>
                                <div className="" style={{  textAlign: 'center', padding: '10px' }}>
                                  -
                                </div>
                                
                            </th>
                        ))}
        </tr>
    ))}
</tbody>

            </table>
        </div>
    </div>
</Container>


</section>
<Offcanvas
            show={reviewsShow}
            onHide={handleReviewsClose}
            placement='end'
            style={{
            width: "30rem"
        }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <>
<div className="input-group" style={{ position: 'relative', width: '100%' }}>
  <input
    className="form-control"
    type="search"
    onChange={(e) => debouncedSearch(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission if it exists
        window.location.href = `/search-result/${e.target.value}`;
        AddTagsAPIFunction(e.target.value, "Text", "");
      }
    }}
    placeholder="What are you looking for?"
    aria-label="Search"
    style={{
      borderRadius: "38px",
      outline: "none",
      border: "none",
      height: "30px",
      width: "95%",
      fontFamily: "Inter",
      fontSize: "13px",
      boxShadow: "none !important",
      padding: "18px",
    }}
  />
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: 'absolute',
      right: '10px', 
      top: '50%', 
      transform: 'translateY(-50%)', 
      pointerEvents: 'none' 
    }}
  >
    <path d="M17.541 16.6501L13.742 12.7289C14.7665 11.442 15.3785 9.79339 15.3785 7.99945C15.3785 3.87622 12.1364 0.532959 8.13797 0.532959C4.13953 0.532959 0.897461 3.87622 0.897461 7.99945C0.897461 12.1227 4.13953 15.4659 8.13797 15.4659C9.88449 15.4659 11.4866 14.8278 12.7381 13.7642L16.5371 17.6818C16.9806 18.1001 17.3966 17.8307 17.541 17.6818C17.8298 17.3875 17.8298 16.9443 17.541 16.6501ZM2.31049 7.99945C2.31049 4.68101 4.91996 1.99009 8.13797 1.99009C11.356 1.99009 13.9689 4.68101 13.9689 7.99945C13.9689 11.3179 11.3594 14.0088 8.1414 14.0088C4.9234 14.0088 2.31049 11.3179 2.31049 7.99945Z" fill="black"/>
  </svg>
</div>
{isloading || searchString == "" ? (
   <div style={{  marginTop: "30px" }}>
   {similarproduct.map((element, index) => (
       <div key={index}>
       
               <Row className="mb-2" style={{ cursor: 'pointer' }}>
                   <Col sm={3} md={3} xs={3} lg={3}>
                       <Image src={element.default_image} layout="fill" quality={100} style={{ margin: "0px !important", padding: "0px" ,background:"#F5F5F5"}} rounded />
                   </Col>
                   <Col sm={9} md={9} xs={9} lg={9}>
                       <p className="cart-item-name" style={{color:"#999999",marginBottom:"0.5rem",fontSize:"12px",fontWeight:'600'}}>{element.short_name}</p>
                       <p>
                       <b style={{ fontSize:"12px",fontWeight:'700',color:'#2D2D2D' }}>{element.price}</b>&nbsp;
                       <span style={{ fontSize: "10px",margin:"3px",fontWeight:'400',color:'#2D2D2D' }} className="mrp-strike">{element.mrp}</span>
                       <span className="" style={{ height: "25px", width: "100%" }}>
                       
                       <span style={{ fontSize: "12px",color:"#00BC57",fontWeight:"600" }}>{element.discount_percentage}% Off</span>
                   </span>
                   </p>
       
                     
                   <div className="d-flex align-items-center " style={{justifyContent:"space-between"}}>
                                              <div className="elite-price-container3 inter-regular d-flex align-items-center" style={{gap:"6px" ,padding:"5px 12px"}}>
                                              <svg width="12" height="19" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1148 9.38723C10.3684 9.38723 9.69298 9.37928 9.01822 9.39518C8.90974 9.39763 8.78964 9.49366 8.69924 9.57195C7.41946 10.6907 4.41888 10.602 3.80998 8.1529C3.79125 8.07644 3.78544 7.99693 3.76672 7.87459C3.9417 7.87459 4.08828 7.87459 4.23485 7.87459C6.53808 7.87459 8.8413 7.86848 11.1439 7.88193C11.459 7.88377 11.5487 7.78652 11.5558 7.50637C11.5739 6.82621 11.4855 6.16561 11.2562 5.51909C11.12 5.13435 11.2562 4.76247 11.5823 4.54227C11.8884 4.33553 12.3087 4.32513 12.6057 4.55328C12.7413 4.65726 12.8698 4.80712 12.926 4.96064C14.1341 8.27401 12.6858 11.9005 9.18158 13.215C5.58695 14.5631 1.64106 12.8511 0.402604 9.48816C-0.797758 6.22495 0.770009 2.66263 3.98819 1.35001C4.83988 1.00259 5.73353 0.836827 6.65882 0.839885C7.08886 0.84172 7.42656 1.09311 7.52277 1.45093C7.62221 1.82099 7.45756 2.23141 7.10048 2.388C6.92291 2.46629 6.7079 2.48648 6.50773 2.49687C4.12896 2.61737 2.37716 4.01502 1.8761 6.19987C1.3802 8.36025 2.41978 10.5316 4.42533 11.5103C6.32176 12.4357 9.36367 12.1905 10.8953 9.77319C10.9605 9.67043 11.016 9.56156 11.1142 9.38723H11.1148Z" fill="#9E7829"/>
<path d="M9.53309 6.35579H3.87286C3.79473 5.38141 5.09518 4.27553 6.4208 4.18562C7.99115 4.07919 9.4414 5.08109 9.53374 6.35579H9.53309Z" fill="#9E7829"/>
<path d="M11.227 2.60784C11.2328 3.25192 10.6936 3.77795 10.0156 3.78835C9.34732 3.79875 8.7791 3.27394 8.76941 2.6372C8.75973 2.00413 9.31374 1.46526 9.98463 1.45486C10.6536 1.44446 11.2211 1.97172 11.227 2.60784Z" fill="url(#paint0_linear_1_4076)"/>
<path d="M11.227 2.60784C11.2328 3.25192 10.6936 3.77795 10.0156 3.78835C9.34732 3.79875 8.7791 3.27394 8.76941 2.6372C8.75973 2.00413 9.31374 1.46526 9.98463 1.45486C10.6536 1.44446 11.2211 1.97172 11.227 2.60784Z" fill="#9E7829"/>
<defs>
<linearGradient id="paint0_linear_1_4076" x1="9.329" y1="0.73093" x2="12.7365" y2="1.56967" gradientUnits="userSpaceOnUse">
<stop stop-color="#00CEFF"/>
<stop offset="0.943494" stop-color="#00FFE3"/>
</linearGradient>
</defs>
</svg>


                                                  <span className="" style={{fontsize:"14px",fontweight:"600",color:"black"}}><span style={{fontSize:"14px",fontWeight:"600"}}>{element.elite_price}</span></span>
                                                                                     
                                              </div>
                                              <comparebtn className="productaddtocart1"   onClick={() => {

comparesimilarproducts(element.product_id)
}}  style={{width:'45%',background:"#F7FDFF",border:" 0.3px solid #219EBC"}}>
<span style={{display:'flex'}}>




</span>
<span style={{ }}>
<img src={require("../../assets/images/PNG/compare.png")} alt=""/>
</span>

<span style={{color:"#219EBC",marginLeft:"5px",fontWeight:"600",background:"#F7FDFF",}}>+compare</span>
</comparebtn>
                                          </div>
                   </Col>
               </Row>
          
           <hr style={{ border: "1px solid #ccc", margin: "10px 0" }} />
       </div>
   ))}
</div>
) : (
  searchString !== "" && (
    <div>
<ToastContainer />

<Container style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }}>


    {prods.length > 0 && (
        <>
            
         
                <div style={{  marginTop: "10px" }}>
                    {prods.map((element, index) => (
                        <div key={index}>
                        
                                <Row className="mb-2" style={{ cursor: 'pointer' }}>
                                    <Col sm={3} md={3} xs={3} lg={3}>
                                        <Image src={element.image} layout="fill" quality={100} style={{ margin: "0px !important", padding: "0px" ,background:"#F5F5F5"}} rounded />
                                    </Col>
                                    <Col sm={9} md={9} xs={9} lg={9}>
                                        <p className="cart-item-name" style={{color:"#999999",marginBottom:"0.5rem",fontSize:"12px",fontWeight:'600'}}>{element.name}</p>
                                        <p>
                                        <b style={{ fontSize:"12px",fontWeight:'700',color:'#2D2D2D' }}>{element.price}</b>&nbsp;
                                        <span style={{ fontSize: "10px",margin:"3px",fontWeight:'400',color:'#2D2D2D' }} className="mrp-strike">{element.mrp}</span>
                                        <span className="" style={{ height: "25px", width: "100%" }}>
                                        
                                        <span style={{ fontSize: "12px",color:"#00BC57",fontWeight:"600" }}>{element.discount_percentage}% Off</span>
                                    </span>
                                    </p>
                        
                                      
                                    <div className="d-flex align-items-center " style={{justifyContent:"space-between"}}>
                                                               <div className="elite-price-container3 inter-regular d-flex align-items-center" style={{gap:"6px" ,padding:"5px 12px"}}>
                                                               <svg width="12" height="19" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1148 9.38723C10.3684 9.38723 9.69298 9.37928 9.01822 9.39518C8.90974 9.39763 8.78964 9.49366 8.69924 9.57195C7.41946 10.6907 4.41888 10.602 3.80998 8.1529C3.79125 8.07644 3.78544 7.99693 3.76672 7.87459C3.9417 7.87459 4.08828 7.87459 4.23485 7.87459C6.53808 7.87459 8.8413 7.86848 11.1439 7.88193C11.459 7.88377 11.5487 7.78652 11.5558 7.50637C11.5739 6.82621 11.4855 6.16561 11.2562 5.51909C11.12 5.13435 11.2562 4.76247 11.5823 4.54227C11.8884 4.33553 12.3087 4.32513 12.6057 4.55328C12.7413 4.65726 12.8698 4.80712 12.926 4.96064C14.1341 8.27401 12.6858 11.9005 9.18158 13.215C5.58695 14.5631 1.64106 12.8511 0.402604 9.48816C-0.797758 6.22495 0.770009 2.66263 3.98819 1.35001C4.83988 1.00259 5.73353 0.836827 6.65882 0.839885C7.08886 0.84172 7.42656 1.09311 7.52277 1.45093C7.62221 1.82099 7.45756 2.23141 7.10048 2.388C6.92291 2.46629 6.7079 2.48648 6.50773 2.49687C4.12896 2.61737 2.37716 4.01502 1.8761 6.19987C1.3802 8.36025 2.41978 10.5316 4.42533 11.5103C6.32176 12.4357 9.36367 12.1905 10.8953 9.77319C10.9605 9.67043 11.016 9.56156 11.1142 9.38723H11.1148Z" fill="#9E7829"/>
<path d="M9.53309 6.35579H3.87286C3.79473 5.38141 5.09518 4.27553 6.4208 4.18562C7.99115 4.07919 9.4414 5.08109 9.53374 6.35579H9.53309Z" fill="#9E7829"/>
<path d="M11.227 2.60784C11.2328 3.25192 10.6936 3.77795 10.0156 3.78835C9.34732 3.79875 8.7791 3.27394 8.76941 2.6372C8.75973 2.00413 9.31374 1.46526 9.98463 1.45486C10.6536 1.44446 11.2211 1.97172 11.227 2.60784Z" fill="url(#paint0_linear_1_4076)"/>
<path d="M11.227 2.60784C11.2328 3.25192 10.6936 3.77795 10.0156 3.78835C9.34732 3.79875 8.7791 3.27394 8.76941 2.6372C8.75973 2.00413 9.31374 1.46526 9.98463 1.45486C10.6536 1.44446 11.2211 1.97172 11.227 2.60784Z" fill="#9E7829"/>
<defs>
<linearGradient id="paint0_linear_1_4076" x1="9.329" y1="0.73093" x2="12.7365" y2="1.56967" gradientUnits="userSpaceOnUse">
<stop stop-color="#00CEFF"/>
<stop offset="0.943494" stop-color="#00FFE3"/>
</linearGradient>
</defs>
</svg>


                                                                   <span className="" style={{fontsize:"14px",fontweight:"600",color:"black"}}><span style={{fontSize:"14px",fontWeight:"600"}}>{element.elite_price}</span></span>
                                                                                                      
                                                               </div>
                                                               <comparebtn className="productaddtocart1"  onClick={() => {
                                                                comparesimilarproducts(element.product_id)
                                                               }} style={{width:'45%',background:"#F7FDFF",border:" 0.3px solid #219EBC"}}>
    <span style={{display:'flex'}}>
    



    </span>
    <span style={{ }}>
    <img src={require("../../assets/images/PNG/compare.png")} alt=""/>
</span>

    <span style={{color:"#219EBC",marginLeft:"5px",fontWeight:"600",background:"#F7FDFF",}}>+compare</span>
  </comparebtn>
                                                           </div>
                                    </Col>
                                </Row>
                            {/* </a> */}
                            {/* Add a line under each product */}
                            <hr style={{ border: "1px solid #ccc", margin: "10px 0" }} />
                        </div>
                    ))}
                </div>
           
        </>
    )}
</Container>



      {/* <a
        href={`/search-result/${searchString}`}
        onClick={(e) => {
          // e.preventDefault();
          AddTagsAPIFunction(searchString, "Text", "");
        }}
      >
        <div style={{ display: "flex", marginLeft: "25px", marginTop: "15px" }}>
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "#CCF5FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50px",
              height: "50px",
              marginRight: "10px"
            }}
          >
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5974 14.6232L12.689 10.6609C13.3531 9.58925 13.7385 8.32257 13.7385 6.96318C13.7385 3.1173 10.6629 0 6.8691 0C3.07533 0 0 3.1173 0 6.96318C0 10.8092 3.07518 13.9262 6.8691 13.9262C8.32848 13.9262 9.68023 13.4637 10.7925 12.6778L14.6545 16.5929C14.9228 16.8646 15.2746 17 15.6259 17C15.9777 17 16.3291 16.8646 16.5979 16.5929C17.1341 16.0487 17.1341 15.1672 16.5974 14.6232ZM6.8691 11.6703C4.30485 11.6703 2.22592 9.56299 2.22592 6.96347C2.22592 4.36396 4.30485 2.25655 6.8691 2.25655C9.4335 2.25655 11.5123 4.36396 11.5123 6.96347C11.5123 9.56299 9.4335 11.6703 6.8691 11.6703Z" fill="#009FC6" />
            </svg>
          </div>
          <div>
            <p style={{ fontSize: "16px", color: "#009FC6", margin: "0px" }}>Show all results for &gt;</p>
            <p style={{ color: "black" }}>{searchString}</p>
          </div>
        </div>
      </a> */}
    </div>
  )
)}



    </>
                {/* <ReviewsList
                product_id={detailsData.product_id} /> */}
            </Offcanvas.Body>
        </Offcanvas>
        </>
    );
}

export default Productcomparision;
