// CartItem.js
import React from 'react';
import './../../SubCss/CartItem.css'; 
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useCart } from './../SubComponents/MiniComponents/CartContext.jsx';
import CartList from "./../CartList.js";
const CartItem = () => {
  const { cartCount } = useCart();

  const [cartshow, setcartShow] = React.useState(false);

  const handlecartClose = () => setcartShow(false);
  const handlecaartShow = () => setcartShow(true);
  return (
    <>
     {cartCount >= 1 ? ( <div className="cart-item "> 
        <div className="cart-details">
          <div className="cart-icon-container" style={{ background: '#004A70', padding: '2px', borderRadius: "50%", margin: '7px' }}>
            <svg width="32" height="28" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* SVG Content */}
              <path d="M14.2325 18.4198H4.07201C3.83974 18.42 3.60984 18.3731 3.39629 18.2817C3.18274 18.1904 2.99001 18.0565 2.82982 17.8883C2.66963 17.7201 2.54534 17.5211 2.46449 17.3033C2.38365 17.0856 2.34795 16.8537 2.35956 16.6217L2.82192 6.78082C2.84106 6.33964 3.02989 5.9229 3.34899 5.61763C3.66808 5.31236 4.09277 5.14216 4.53437 5.14258H13.7702C14.2118 5.14216 14.6365 5.31236 14.9556 5.61763C15.2747 5.9229 15.4635 6.33964 15.4826 6.78082L15.945 16.6217C15.9566 16.8537 15.9209 17.0856 15.8401 17.3033C15.7592 17.5211 15.6349 17.7201 15.4747 17.8883C15.3145 18.0565 15.1218 18.1904 14.9083 18.2817C14.6947 18.3731 14.4648 18.42 14.2325 18.4198ZM4.53437 6.28992C4.38298 6.28992 4.23779 6.35006 4.13075 6.45711C4.0237 6.56416 3.96356 6.70935 3.96356 6.86074L3.5012 16.6788C3.49732 16.7561 3.50923 16.8334 3.53617 16.906C3.56312 16.9786 3.60455 17.0449 3.65795 17.101C3.71134 17.157 3.77559 17.2017 3.84677 17.2321C3.91795 17.2626 3.99459 17.2782 4.07201 17.2781H14.2325C14.31 17.2782 14.3866 17.2626 14.4578 17.2321C14.529 17.2017 14.5932 17.157 14.6466 17.101C14.7 17.0449 14.7414 16.9786 14.7684 16.906C14.7953 16.8334 14.8072 16.7561 14.8034 16.6788L14.341 6.8379C14.341 6.68651 14.2809 6.54132 14.1738 6.43427C14.0668 6.32723 13.9216 6.26709 13.7702 6.26709L4.53437 6.28992Z" fill="white"/>
              <path d="M12.5773 5.71923H11.4357V4.72031C11.4357 4.11475 11.1951 3.53399 10.7669 3.10579C10.3388 2.6776 9.75799 2.43704 9.15244 2.43704C8.54688 2.43704 7.96612 2.6776 7.53792 3.10579C7.10973 3.53399 6.86917 4.11475 6.86917 4.72031V5.71923H5.72754V4.72031C5.72754 3.81197 6.08838 2.94083 6.73067 2.29854C7.37296 1.65625 8.2441 1.29541 9.15244 1.29541C10.0608 1.29541 10.9319 1.65625 11.5742 2.29854C12.2165 2.94083 12.5773 3.81197 12.5773 4.72031V5.71923Z" fill="white"/>
            </svg>
          </div>
          <span className="item-count">{cartCount} item</span>
        </div>
        <a className="view-cart-button" onClick={handlecaartShow}>
          <span style={{marginRight:'5px'}}>View Cart</span> 
          <span style={{marginTop:"10px"}}><svg width="7" height="auto" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8561 14.6182C10.8437 14.6079 10.8272 14.6037 10.8148 14.5934L2.85282 7.38696C2.40487 6.98442 1.67823 6.98442 1.23028 7.38696C1.22615 7.39109 1.22409 7.39522 1.22202 7.39935C1.11356 7.4889 1.02595 7.60107 0.965339 7.72801C0.904725 7.85494 0.87256 7.99358 0.871093 8.13423L0.871094 22.5492C0.873408 22.6925 0.907146 22.8335 0.969925 22.9622C1.0327 23.091 1.12299 23.2044 1.23441 23.2944L1.23028 23.2986C1.45567 23.4934 1.74364 23.6006 2.04155 23.6006C2.33946 23.6006 2.62743 23.4934 2.85282 23.2986L10.8561 16.0756C10.9615 15.9857 11.0462 15.874 11.1042 15.7482C11.1622 15.6223 11.1923 15.4854 11.1923 15.3469C11.1923 15.2083 11.1622 15.0714 11.1042 14.9456C11.0462 14.8198 10.9615 14.7081 10.8561 14.6182Z" fill="#008EB0"/>
          </svg></span>
        </a>
      </div>):""}

      <Offcanvas show={cartshow} onHide={handlecartClose} placement='end' style={{ width: "30rem",zIndex:"10001"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Cart</Offcanvas.Title>
        </Offcanvas.Header> 
        <CartList />               
      </Offcanvas>
    
 </>
  );
};

export default CartItem;
