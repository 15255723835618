import React from "react"; 
import '../fonts/BebasNeue-Regular.ttf';
import '../fonts/Inter-Regular.ttf';
import { NavLink } from "react-router-dom";

export default function DownloadTheApp(){
  
    return(
        <>
        <section className="download_App" style={{background:"linear-gradient(180deg, #C0DAEB, transparent)",paddingTop: "80px",width:"100%"}}>
          <div className="container">
          <div className="container image-container bgimgformobille_ref" style={{backgroundImage: `url(${require('../assets/images/downloadapp_latest.png')})`,backgroundRepeat: "no-repeat",backgroundSize: "contain"}}>
            <div className="row"> 
              <div className="col-md-12">
                  <div id="downloadtxt">
                    <div id="Storebtn" className="Storebtn" style={{display:"flex", justifyContent:"center", paddingTop:"180px"}}> 
                    <span className="playstore" style={{ padding: "0px 5px" }}>
  <a
    href="https://play.google.com/store/apps/details?id=com.nutsby.android&hl=en_IN&pli=1"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={require('../assets/images/Googleplay.png')} alt="Google Play Store"></img>
  </a>
</span>

<span className="appstore" style={{ padding: "0px 5px" }}>
  <a
    href="https://apps.apple.com/in/app/nutsby-building-a-healthy-you/id6477144689"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={require('../assets/images/Appstore.png')} alt="App Store"></img>
  </a>
</span>

                    </div>
                  </div>
                  
              </div>
              <div className="container container_nutsbyapps" style={{display:"flex", alignItems:"center", justifyContent:"center", 
                position:"relative", bottom:"21px"}}> 
                <NavLink to="/">
                  <img src={require('../assets/images/Googleplay.png')} alt="" style={{width:"50px", height:"auto"}}></img>
                </NavLink>
                <NavLink to="/">
                  <img src={require('../assets/images/Appstore.png')} alt="" style={{width:"50px", height:"auto"}}></img>
                </NavLink>
            </div>
            </div>
           





          </div>
          </div>
          
        </section>
           
        </>
    )

}