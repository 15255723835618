
import React, { useEffect, useState } from 'react';
import AccountsTabs from './SubComponents/AccountsTabs';
import { Container, Row, Col, Button, NavLink } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Profile from './SubComponents/Profile';
import Orders from './SubComponents/Orders';
import Contactlist from './SubComponents/Contactlist';
import Addressbook from './SubComponents/Addressbook';
import Wishlist from './SubComponents/Wishlist';
import Fitpoints from './SubComponents/Fitpoints';
import { Elite, GlimpseStars } from "../MiniComponents/SvgIcons.jsx";
import RateOrder from './SubComponents/RateOrder';
import { GetHeaderDetailsApi } from '../Service/ApiCalling';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from "react-router-dom";
import "../SubCss/AccountsCss.css";
import OrderDetails from './SubComponents/OrderDetails';
import MetaTags from './Metatags.js';
import { Helmet } from 'react-helmet-async';

function Accounts() {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();
  const [order_id, setOrderId] = useState(params.id);
  const [accountdetails, setAccountDetails] = useState();
  
  const [is_subscribed, setSubscribed] = useState('');
  const [subscription_until, setUntilDate] = useState('');
  const [savedamt, setSaved] = useState('0');

  const getHeaderData = async () => {
    let AccountDetails = await GetHeaderDetailsApi();
    if (AccountDetails.error === "0") {
      setSubscribed(AccountDetails.is_subscribed);
      setUntilDate(AccountDetails.subscripton_valid_date);
      setSaved(AccountDetails.total_saved);
      if (AccountDetails.is_subscribed === "1") {
        localStorage.setItem('is_subscribed', AccountDetails.is_subscribed);
      }
      setAccountDetails(AccountDetails);
      console.log(accountdetails, "header");
    }
  }

  const navigate = useNavigate();
  const gotToNewPagesss = () => {
    // navigate("/membership_details");
  }

  useEffect(() => {

    getHeaderData();
  }, []);

  // Update the body background color based on the pathname
  useEffect(() => {
    document.body.style.backgroundColor = "#F4F5FA";

  }, [pathname]);

  return (
    <>
    <Helmet>
    
    <meta name="robots" content="noindex"/> 
    </Helmet>
     

   
   

    <Container className='topMarginElitCard' >


      {/* <Col lg={12} md={12} sm={12}>
        <Row className='d-flex justify-content-center' >

          
          <div style={{marginTop:"24px"}}>
            <div style={{display:"flex",justifyContent:"center", marginBottom:"-14px" ,marginTop:"10px"}}>
          {is_subscribed == "1" ?<div className="membership-duration1 membership-card1 ">{(<span className="membership-text" style={{padding:"10px"}}>Expires on {subscription_until}</span>)}</div>
              :(is_subscribed == "2" ?<div className="membership-duration1 membership-card1 ">{(<span className="membership-text" style={{fontSize:"small",padding:"10px"}}>Expired on {subscription_until}</span>)}</div> :<></>)}</div>

            <div className="plancard1 membership-card1">

            
              <div className="price-section1 " style={{justifyContent:"space-between",display:"flex"}}>
              <div
  className={
    (is_subscribed === "1" || is_subscribed === "2") 
      ? 'd-none d-md-block d-xl-block'  
      : 'd-block d-sm-block d-md-block d-xl-block d-xxl-block'  
  }
  style={{
    paddingRight: is_subscribed !== "1" && is_subscribed !== "2" ? "30px" : "0", 
  }}
>
                {
                  <Elite className='img-fluid' style={{ padding: "15px" }} />

                }</div>

                {(is_subscribed=="1" || is_subscribed =="2")?<Container id="amount_saved" className="col-lg-4 col-md-3 col-sm-8 col-8 d-flex justify-content-center" style={{ alignItems: "center"}}>

                  <GlimpseStars />
                  
                  {savedamt===0 ? <>
                    <div className='d-none d-md-block d-xl-block  d-sm-none'>
                    <span id="subscribed_text" className='subscribed_text' style={{ color: "white"}}>&nbsp;{"You've saved"}&nbsp;</span>
                    <span id="subscribed" className='subscribed' style={{ color: "white"}}>₹{savedamt}</span>
                  </div>

                  <div className='d-block d-md-none d-xl-none  d-sm-block'>
                  <span id="subscribed_text" className='subscribed_text' style={{ color: "white",fontSize:"15px" }}>&nbsp;{"You've saved"}&nbsp;</span>
                  <span id="subscribed" className='subscribed' style={{ color: "white",fontSize:"15px" }}>₹{savedamt}</span>
                  </div>
                  </>:<>
                  <div className='d-none d-md-block d-xl-block  d-sm-none'>
                    <span id="subscribed_text" className='subscribed_text' style={{ color: "white"}}>&nbsp;{"Enjoy the benefits of Elite"}</span>                    
                  </div>

                  <div className='d-block d-md-none d-xl-none  d-sm-block'>
                  <span id="subscribed_text" className='subscribed_text' style={{ color: "white",fontSize:"15px" }}>&nbsp;{"Enjoy the benefits of Elite"}</span>                  
                  </div>
                  </>}
                  
                  <GlimpseStars />
                </Container>:<></>}

                <Button onClick={() => {gotToNewPagesss();}} className="col-lg-2 col-md-3 col-sm-4 col-4" variant="primary" type="submit" style={{ fontWeight: "500", fontSize: "15px", border: "transparent", backgroundImage: "linear-gradient(to right, #FBE4BA, #9E7829)", color: "black", borderRadius: "30px" }} align="end">
                  Explore Now
                </Button>
              </div>


            </div>
          </div>
     

        </Row>
      </Col> */}

      <Row>
        {/* <Col col={2} md={3} lg={2} className='NotRequiredtoshowScreen' style={{marginTop:"44px"}}>
          <AccountsTabs />
        </Col> */}
        <Col col={12} md={12} lg={12}>
          {pathname === "/profile" ? <Profile /> : ""}
          {pathname === "/orders" ? <Orders /> : ""}
          {pathname === "/wishlist" ? <Wishlist /> : ""}
          {pathname === "/fit-cash" ? <Fitpoints /> : ""}
          {pathname === "/address-book" ? <Addressbook /> : ""}
          {pathname.includes("RateOrder") ? <RateOrder /> : ""}
          {pathname.includes("order-details")  ? <OrderDetails /> : ""}
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Accounts
