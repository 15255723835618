import React, { createContext, useState, useEffect, useContext } from 'react';
import { GetHeaderDetailsApi } from '../../../Service/ApiCalling'; 

// Creating the CartContext
const CartContext = createContext();

// Hook to use the Cart Context
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// CartProvider component that provides the Cart context to its children
export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [headeramount, setamount] = useState(0);
  const [headername,setheadername] = useState('guest');
  const [image ,setimage] =useState(null);
  
  // Fetch header details and update the cart count and amount
  useEffect(() => {
    const fetchHeaderDetails = async () => {
      let data = await GetHeaderDetailsApi();
      if (data.error === "0") {
        setheadername(data.user_name);
        setimage(data.user_profile);
        if (data.cart_count != null) {
          setCartCount(data.cart_count);
          setamount(data.cart_total);
        } else {
          setCartCount(0);
          setamount(0);
        }
        localStorage.setItem('is_subscribed', data.is_subscribed);
      }
    };

    fetchHeaderDetails();
  }, []);

  return (
    // Providing cartCount, setCartCount, headeramount, and setamount to context
    <CartContext.Provider value={{ cartCount, setCartCount, headeramount, setamount ,headername,setheadername,image ,setimage}}>
      {children}
    </CartContext.Provider>
  );
};
