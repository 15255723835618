import React, { useEffect, useState } from "react";
import {Container, Row, Col,Modal,Image, Offcanvas,Card} from 'react-bootstrap'; 
import {AddToCart} from '../../../Service/ApiCalling';
import Button from 'react-bootstrap/Button';
import "../../../SubCss/CartModal.css"; 
import  {DiscountIcon,EliteIcon} from "../../../MiniComponents/SvgIcons";  
export default function CartFreeCardItem(params) {

    const [cart_qnty, setcart_qnty] = useState(params.cart_quantity);

    const AddToCartCall=async (p_id,qnt)=> { 
        let addcartDatatemp = await AddToCart(p_id,qnt);
        if (addcartDatatemp.error === "0") {
            params.cartrefresh();
            // params.setChildChanged("1");
            setcart_qnty(qnt);
            if(params.type!="normal"){
                params.removeSuggest();
            } 
        }
      };

    return (
        <>
            <Row className="justify-content-center align-items-center">     
                <Col xs="auto">
                    <div className="img-fluid">
                    <img src={params.image} alt="" style={{width:"60px",height:"80px"}}/>
                    </div>
                </Col>

                <Col> 
                    <div style={{fontFamily: "Inter", fontWeight: 400}} >
                        {params.name}
                    </div> 
                    <div className="d-flex justify-content-between">
                        <span className='order-prod-name' style={{fontFamily: "Inter", fontWeight: 600,color:"#219EBC"}}>
                            {((params.price)==="0.00") ? "Free":params.price}
                        </span>
                        <span style={{fontSize:"15px",color:'#959595',fontFamily:"Inter"}}>
                            Qty. {params.qnty}
                        </span>
                    </div>
                </Col>
            </Row>
        </>
    )
}