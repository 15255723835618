
import "../SubCss/SlidersCss.css";
import { Container, Row, Col, Button ,Modal,Form} from 'react-bootstrap';
import { Link, NavLink,useLocation } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import { useState,useEffect } from 'react';
import { HomePage,show_homepage_popup,home_screen_order_packed_images,app_review ,AddToCart, OrderAgainProductsApi, TopGrossingProductsApi, NotifyMeApi, GetHeaderDetailsApi,allbrandsDataApi, save_session_loc} from '../Service/ApiCalling';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import ProductCard from "../MainComponents/SubComponents/ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BrandCard from "../MainComponents/SubComponents/BrandCard";
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import Slider from "react-slick"; 
import { useParams } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf';
import '../fonts/Michroma.ttf';
import '../fonts/nicomoji-regular.ttf';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ReactSearchBox from "react-search-box";
import { DiscountIcon, EliteIcon, HeaderCartIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from "../MainComponents/SubComponents/MiniComponents/ProductItemCard";
import { useCart } from "../MainComponents/SubComponents/MiniComponents/CartContext";
import React from "react";
import Lottie from "lottie-react";
import singlearrow from '../assets/lottie/single-arrow.json';
import tripplearrow from '../assets/lottie/tripple-arrow-forward.json';
import hearticon from '../assets/lottie/heart-is-beating.json';
import {ExploreIcon} from "../MiniComponents/SvgIcons";
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton, ShimmerCategoryItems } from "shimmer-effects-react";
import "../SubCss/home.css"
import MetaTags from '../MainComponents/Metatags'
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
import axios from 'axios';
import { bottombarshow } from "../Service/ApiNames";
import { FaSmile, FaMeh, FaFrown } from 'react-icons/fa';
import smileClicked from '../assets/images/PNG/happysmile.png'; 
import mehClicked from '../assets/images/PNG/Confused.png'; 
import frownClicked from '../assets/images/PNG/sad.png';
import smileUClicked from '../assets/images/PNG/happy2.png'; 
import mehUClicked from '../assets/images/PNG/confusedplain.png'; 
import frownUClicked from '../assets/images/PNG/sadplain.png';
import StoryViewer from './../MainComponents/SubComponents/storyviewer';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
function Home() {
  const [TrendingProducts, setTrendingProducts] = useState([]);
  const [orderagainProducts, setorderagainProducts] = useState([]);
  const [brandPro, setbrandPro] = useState([]);
  const [brandid, setbrandid] = useState([]);
//   const [loading, setloading] = useState(true);

const navigate = useNavigate();

const [show, setShow] = useState(false);
const [brands, setBrands] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [is_subscribed,setIsSubscribed] = useState('');
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [error, setError] = useState(null);
  const [addClicked,setAddClicked] = useState(null);
  const [notifyClicked,setNotifyClicked] = useState(null);
  const { setCartCount } = useCart();
  const [sliders, setsliders] = useState([]);
  const[productofday,setproductofday] = useState([]);
  const [productloading, setloading] = useState(true);
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
 
  const main_shimmeritems = Array.from({ length: 2 });
  const [activeIndex, setActiveIndex] = useState(0);
  const [formattedAddress,setFormettedAddress] = useState(null);
  const [postalCode,setPostalCode] = useState(null);
  const fetchInfo=async ()=> {  
    let HomeApiData = await HomePage();
    if (HomeApiData.error==="0") {
      setsliders(HomeApiData.first_slider);
      setproductofday(HomeApiData.todays_collection_products)
      // setTrendingProducts(HomeApiData.trending_products)
      console.log("here1");
      TopGrosingProducts();
      OrderAgainProductsApiCall();
      home_screen_order_packed_imagesData();
      fetchAllBrandsData();
        setloading(false); 
      
    }else{
      // setloading(false);
    }
  }
  useEffect(() => {
    const fetchBrands = async () => {
      const allbrandsDataApiData = await allbrandsDataApi();
      if (allbrandsDataApiData.error === "0") {
        setBrands(allbrandsDataApiData.brand_list);
      }
    };
    fetchBrands();
  }, []);

  const deliveryacroosindia = {
    superLargeDesktop: {
    
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    },
    desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    },
    minidesktop: {
    breakpoint: { max: 1495, min: 1200 },
    items: 6,
    },
    tablet: {
    breakpoint: { max: 1200, min: 900 },
    items: 4,
    },
    minitablet: {
    breakpoint: { max: 900, min: 464 },
    items: 3,
    },
    mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    }}
  const sliderSettings = {
    infinite: true,    
    speed: 500,        
    slidesToShow: 3.5,
    slidesToScroll: 1, 
    arrows: true,     
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true, 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  

  const sliderSettings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const SAveLocation=async (location,address,pincode)=> {  
    let data = await save_session_loc(location,address,pincode);
    if (data.error==="0") {
     console.log(data.message);
      
    }else{
      // setloading(false);
    }
  }
  const handleSlideChange = (_, { currentSlide }) => {
    setActiveIndex(currentSlide);
  };

  const gotToNewPagesss = (hash_id,type,url) => {
        // navigate("/membership_details");
        // href={"/Products/Brand/"+element.id}
        if(type.toLowerCase() === "product collection"){
          window.location.href = `/${url}/${type.toLowerCase()}/${hash_id}`;
      } else if(type.toLowerCase() === "brand"){
          window.location.href = `/${url}/${type.toLowerCase()}/${hash_id}`;
      }
      if(process.env.NODE_ENV!=="production"){
        console.log(type.toLowerCase(), ":type");
      }
      
      
        
        
      }
 
      const fetchAllBrandsData = async () => {
        let allbrandsDataApiData = await allbrandsDataApi(); 
        if (allbrandsDataApiData.error === "0") {
            setbrandPro(allbrandsDataApiData.name); 
            setbrandid(allbrandsDataApiData.id) 
            setloading(false);  
        } else {
            console.error("Error fetching brands data:", allbrandsDataApiData.error);
            // setloading(false); 
        }
    };
    const [showStories, setShowStories] = useState(false);
    const [currentStories, setCurrentStories] = useState([]);
  
    const [orderImages, setOrderImages] = useState([]);
    const [orderlocation, setOrderlocation] = useState([]);
    const home_screen_order_packed_imagesData = async () => {
      let response = await home_screen_order_packed_images();
      if (response.error === "0") {
        setOrderImages(response.order_images);
        setOrderlocation(response.order_images.district)

      }
    };

    const handleImageClick = (imageData) => {
      const stories = [
        {
          url: imageData.image , 
          header: {
            heading: imageData.district || 'Unknown District',
            subheading: imageData.time_before_delivery || 'Delivery time not available', 
          },
          footer: {
            text: imageData.district || 'Unknown District', 
          },
        },
      ];
    
      console.log("Stories prepared:", stories); 
      setCurrentStories(stories);
      setShowStories(true); 
    };
    
  const TopGrosingProducts = async ()=>{
    let TopGrossingProductsApiData = await TopGrossingProductsApi();
    if (TopGrossingProductsApiData.error==="0") {
      
      setTrendingProducts(TopGrossingProductsApiData.products)
     
      setloading(false); 
    }
  }

  const OrderAgainProductsApiCall = async ()=>{
    let OrderAgainProductsApiData = await OrderAgainProductsApi();
    if (OrderAgainProductsApiData.error==="0") {
      setorderagainProducts(OrderAgainProductsApiData.products)
      setloading(false); 
    }
  }
 

  const AddToCartCall = async (p_id, qnt, clicked, index, max_qnt) => { 
    // AddToCart 
    console.log(qnt);
    
    if (clicked === true) {
        setAddClicked(index);
    }
           
    try {
      const utm_source = localStorage.getItem("utm_source") ?? "N/A";
      const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
      const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
        let addcartDatatemp = await AddToCart(p_id, qnt,utm_source,utm_medium,utm_campaign);
        
        if (addcartDatatemp.error === "0") {
          fetchInfo(); 
          ReactGA.event({
            category: 'add to cart',
            action: 'Add TO cart',
            label: 'Purchase',
            value:'',
          });
          ReactPixel.track('AddToCart');
          toast.success(addcartDatatemp.message, {
            autoClose: 3000,
          });
            const updatedTrendingProducts = TrendingProducts.map((item) => {
                if (item.product_id === p_id) {
                    return {
                        ...item, 
                        cart_quantity: addcartDatatemp.cart_quantity
                    };
                } 
                return item;

            });
            
            // Update trending products state
            setTrendingProducts(updatedTrendingProducts);
            
            // Update orderagain products state
            const updatedOrderagainProducts = orderagainProducts.map((item) => {
                if (item.item_id === p_id) {
                    return {
                        ...item, 
                        cart_quantity: addcartDatatemp.cart_quantity
                    };
                } 
                return item;
            });
            
            // Update the state for orderagainProducts
            setorderagainProducts(updatedOrderagainProducts);
            
            // Reset clicked state after a delay
            setTimeout(() => {
                setAddClicked(null);
            }, 1300);
            
            // Fetch and update header details
            const headerData = await GetHeaderDetailsApi();
            if (headerData.error === "0") {
                setCartCount(headerData.cart_count || 0);
                console.log(headerData.cart_count, "khfaosdufhuafs");
               
            }
         
        }
    } catch (error) {
        console.error('Error adding to cart:', error);
    }
};

  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked===true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = TrendingProducts.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100);
            return updatedItem;
          }
          return item;
        });
        // setorderagainProducts(newList);
        setTrendingProducts(newList);



        const newList1 = orderagainProducts.map((item) => {
          if (item.item_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100);
            return updatedItem;
          }
          return item;
        });
        setorderagainProducts(newList1);
        // setTrendingProducts(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const feedbackloc = useLocation();
  const [showform, setShowform] = useState(false);
  const { id } = useParams();
  const [feedbackId, setFeedbackId] = useState(null);
  const [ordernumber,setordernumber] = useState(null);
  useEffect(() => {
    const fetchFeedbackData = async () => {
      if (id) {  // Use the path parameter `id` directly
        const decodedId = atob(id);  // Decode the base64-encoded ID
        setFeedbackId(decodedId);
        setordernumber(decodedId);

        try {
          let response = await show_homepage_popup(1, id);  

          if (response && response.error === "0") {
            if (response.show_order_review_popup === 1) {
              setordernumber(decodedId);
             
              setShowform(true);
            } else {
              setordernumber(decodedId);
              setShowform(false);
            }
          } else {
            setShowform(false);
            setFeedbackId(null);
            setordernumber(null);
          }
        } catch (error) {
          console.error("Error fetching feedback data:", error);
        }
      } else {
        async function checkPopup() {
          try {
            let response = await show_homepage_popup(0,'');

            if (response.error === "0") {
              if (response.show_order_review_popup === 1) {
                setordernumber(response.order_number);
                console.log(response.order_number);
                setShowform(true);
              }
            }
          } catch (error) {
            console.error("Error checking popup status:", error);
          }
        }

        checkPopup();
      }

      console.log('Show form:', showform);
      console.log('Feedback ID:', feedbackId);
      console.log('Order Number:', ordernumber);
    };

    fetchFeedbackData();
  }, [id]);

  const [rating, setRating] = useState(null);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [clickedEmoji, setClickedEmoji] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {

}, []); 


  const handleCloseform = () => {
      setShowform(false);
      setFeedback(""); 
      setRating(null); 
      setClickedEmoji(null); 
      // if (feedbackId) {
      //   navigate('/');
      // } else {
      //   navigate('/');
      // }

  };
  const handleEmojiClick = async (emojiType) => {
    try {
        setClickedEmoji(emojiType); 
        setRating(emojiType);
        setShowFeedbackForm(true); 
       
        const encodedOrderNumber = btoa(ordernumber);
        const response = await app_review(emojiType, feedback,encodedOrderNumber);
        console.log('Rating submitted successfully:', response);
       
    } catch (error) {
        console.error('Error submitting rating:', error);
    }
};
const renderEmoji = (rating) => {
  const isClicked = clickedEmoji === rating; // Check if the emoji was clicked
  const emojiSrc = isClicked ? 
      (rating === 5 ? smileClicked : rating === 2.5 ? mehClicked : frownClicked) :
      (rating === 5 ? smileUClicked : rating === 2.5 ? mehUClicked : frownUClicked);

  return (
      <img 
          src={emojiSrc} 
          alt={`Rating ${rating}`} 
          onClick={() => handleEmojiClick(rating)} 
          style={{ width: "30px", cursor: "pointer", width: "32%" }} 
      />
  );
};


  const handleSubmit = async (e) => {
      e.preventDefault();
      
      try {
        const encodedOrderNumber = btoa(ordernumber);
          const response = await app_review(rating, feedback,encodedOrderNumber);
          console.log("Feedback submitted successfully:", response);
          if(response.error == 0) {
          handleCloseform(); 
        
          if (feedbackId) {
           
            navigate('/');
          } else {
           
            
          }}
      } catch (error) {
          console.error("Error submitting feedback:", error);
      }
  };
  useEffect(() => {
  

    setIsSubscribed(localStorage.getItem("is_subscribed"));
    
    console.log("is_subscribed",is_subscribed);
    fetchInfo(); 
    console.log("here");
    
    
  }, []); 

const productsresponsive = {
  superLargeDesktop: {
   
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  minidesktop: {
    breakpoint: { max: 1495, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
  items: 4,
},
minitablet: {
  breakpoint: { max: 900, min: 464 },
items: 3,
},
mobile: {
  breakpoint: { max: 464, min: 0 },
  items: 3, 
}}

const productsresponsive1 = {
  superLargeDesktop: {
   
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  minidesktop: {
    breakpoint: { max: 1495, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
  items: 4,
},
minitablet: {
  breakpoint: { max: 900, min: 464 },
items: 3,
},
mobile: {
  breakpoint: { max: 464, min: 0 },
  items: 3, 
}
}; 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };  
  const stayfitcarousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1, 
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3, 
    },}  

  const PamperHealthCarousel = {
    superLargeDesktop: {
    
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 900 },
      items: 3,
    },
    minitablet: {
      breakpoint: { max: 900, min: 768 },
    items: 3,
  },
    mobile: {
      breakpoint: { max: 767, min: 481 },
    items: 2,
  },
    minmobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };  
  
  
  const Productresponsive = {
    superLargeDesktop: {
    
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }; 

//   const firebaseConfig = {
// apiKey: "AIzaSyDyPuSoh9YxGXpQCrtUscQOX9R6KHD6Zac",
// authDomain: "nustby-mobile-app.firebaseapp.com",
// projectId: "nustby-mobile-app",
// storageBucket: "nustby-mobile-app.appspot.com",
// messagingSenderId: "655313227049",
// appId: "1:655313227049:web:d9294f22b32410b66f3b38",
// measurementId: "G-5TBP909THY"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
  
  if(!productloading){
  return (
    <>
     {/* <Container>
      <Row>
        <Col xs={12} md={6}>
          Column 1
        </Col>        
      </Row>
      <Row>
      <Col xs={12} md={6}>
          Column 2
        </Col>
      </Row>
    </Container> */}
    
   <Helmet  >
   <meta name="google-site-verification" content="xgkTLIx1gHPoEatzkv2hDJXBT3ioRG_AvrgdleE9Zic" />
   </Helmet>
<MetaTags

        title="Nutsby All in One Nutrition Store | Building a Healthy You"
        description="At Nutsby, we're dedicated to offering health & wellness products at the best prices. Our mission is to support your journey towards a healthier, happier you with a diverse selection of reliable products and unbeatable service. "
        keywords="Health Supplements,
        Fitness Supplements,
        Nuts & Dried Fruits,
        Protein Supplements,
        Sports Nutrition,
        protein powder,
        high quality dried fruits,
        protein supplements for weight loss,
        protein supplements for muscle gain,
        protein supplement store,
        Ayurvedic Products"
        canonical="https://nutsby.com"
        url="https://nutsby.com"
        image="https://nutsby.com/assets/nutsby.png" 
        alt="Nutsby Fitness Products"
        ogTitle="Nutsby All in One Nutrition Store | Building a Healthy You"
        ogUrl="https://nutsby.com"
        ogDescription="Join Nutsby in our journey to make a healthy and fit lifestyle the universal norm. Explore our wide range of fitness products."
        ogImage="https://nutsby.com/assets/nutsby.png" 

        indexing="index, follow"
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Nutsby Fitcare",
          "url": "https://nutsby.com",
          "logo": "https://nutsby.com/assets/nutsby.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-1234567890",
            "contactType": "Customer Service",
          },
        }}
      />
{




    <div style={{ }}>  

      <div onClick={()=>{}} >
        <div className="mainCarousel1 d-none d-md-block d-lg-block d-sm-block container " style={{paddingBottom: "20px" ,marginTop:'4rem',paddingTop:'0px'}} > 
        <Row>
  <Col lg={12} md={12} sm={12} col={12}>
    <Carousel responsive={responsive} autoPlay={true} infinite={true} arrows={true} draggable={true}>
      {sliders && sliders.length > 0 ? (
        sliders.map((slider, index) => (
          <div
            key={index}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <img
              src={slider.web_image}
              alt={slider.alt_text}
              draggable="false"
              style={{ width: '99%',objectFit: "fill"}} 
              onClick={() => {
                gotToNewPagesss(slider.hash_id, slider.ref_type,slider.url_title);
              }}
            />
          </div>
        ))
      ) : (
        <div>No slides available</div>
      )}
    </Carousel>
  </Col>
</Row>

        </div>
        <div className="mainCarousel1 d-block d-md-none d-lg-none d-sm-none container" style={{paddingBottom: "0px" ,paddingTop:'0px',marginTop:'5rem'}} >
            <Row>
              <Col lg={12} md={12} sm={12} col={12}>
                <Carousel responsive={responsive} arrows={true} draggable={true} autoPlay={true} infinite={true}>
                {sliders && sliders.length > 0 ? (
                  sliders.map((slider, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <img
                        src={slider.image}
                        draggable="false"
                        alt={slider.alt_text}
                        style={{ width: '97%', height: '90%' }}
                        onClick={() => {
                          gotToNewPagesss(slider.hash_id, slider.ref_type,slider.url_title);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div>No slides available</div> 
                )}

                </Carousel>  
              </Col>                      
            </Row> 
        </div>  
      </div>
  {/* </section> */}  
 
  {/* <!-- second section --> */}
  <section className="fitnessgoals container">
  <div
  style={{
    display: "flex",
    justifyContent: "center",
    padding: "10px",
   
  }}
>
  <span className="" style={{ width: "44%" }}>
    <img
      style={{ width: "100%", mixBlendMode: "" }}
      src={require("../assets/images/PNG/goals.png")}
      alt="Description of GIF" 
    />
  </span>
  <span style={{ width: "7%" }}>
    <Lottie className="" animationData={tripplearrow} />
  </span>
</div>

    <div className="px-3 mt-1">
        <Row>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">
              <NavLink to={'/maingoal/muscle-building'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG1.png")} alt="muscle building supplements for men"/>
              </div>
              </NavLink>
            </div>
          </Col>

          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">
              <NavLink  to={'/maingoal/athelete-journey'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG2.png")} alt="healthy-nutrition-for-athletes"/>
                </div>
              </NavLink>  
            </div>
          </Col>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">  
              <NavLink to={'/maingoal/happy-healthy'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG3.png")} alt="wellness-products-for-a-happy-and-healthy-lifestyle"/>
                </div>
              </NavLink>
            </div>
          </Col>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">   
              <NavLink to={'/maingoal/fitness-forever'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG4.png")} alt="Top rated supplements for fitness"/>
                </div>
              </NavLink>
            </div>
          </Col>
        </Row>
    </div>
  
  </section>  
  {/* <!--End second section --> */}

  {/* <!-- third section --> */}
  {orderagainProducts.length > 0 && ( // Render the entire section only if there are products
  <section className="PrevoiusOrders container">
    <div className="mx-3">
      <Row>
        <Col md={12}>
          <div className="newarrivalproducts mx-2 d-flex justify-content-between align-items-center">
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h2 style={{ fontFamily: 'Inter, sans-serif', textAlign: "start" }}>
                Previous Ordered Items
              </h2>
            </div>
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h5 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ flex: 1 }}>
                  {/* <Link to="/Products/Brand/11" style={{ fontFamily: "Inter", color: "#219EBC", marginBottom: "0", paddingRight: "6px" }}>
                    Explore All
                  </Link> */}
                </span>
                {/* <span>
                  <ExploreIcon style={{ width: "12px", height: "5px", paddingLeft: "6px" }} />
                </span> */}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ paddingBottom: "20px" }}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={false} infinite={true} className="stayfit1">
            {orderagainProducts.map((element) => (
              <Col style={{ padding: "5px" }}>
                <ProductItemCard
                   add_cart={AddToCartCall}
                   url_title={element.url_title}
                    image={element.image}
                   rate={element.average_rating}
                   dis={element.discount_percentage}
                  
                   cart_quantity={element.cart_quantity}
                   name={element.name}
                   diet_type={element.diet_type}
                   tag={element.item_tag}
                   elite_price={element.elite_price}
                   saleprice={element.price}
                   mrp={element.mrp}
                   cart_qnt={element.cart_quantity}
                   id={element.item_id}
                   notified={element.is_notified}
                   max_qnty={element.max_cart_quantity}
                   nuin={element.nuin}
                   stock={element.stock}
                   notify_me = {NotifyMeCall}
                   is_subscribed = {is_subscribed}
                   clicked = {addClicked === element.product_id}
                   notifyClicked = {notifyClicked === element.product_id}
                   index = {element.product_id}
                />
              </Col>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  </section>
)}

  {/* <!-- End of Third section --> */}

{/* <section className="d-none d-md-block d-lg-block d-sm-block container ">
<Container>
  <Row
    className="background"
    style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}
  >
    <Col
      lg={12}
      md={12}
      sm={12}
      col={12}
      style={{
        paddingTop: '50px',
        paddingBottom: '50px',
        display: 'flex',
      }}
    >
      <div style={{ margin: '5px' }}>
        <img
          className="deliveryacross_img"
          src={require("../assets/images/PNG/Grouptext.png")}
          style={{
            width: '150px',
            marginTop: '60px',
            marginLeft: '30px',
            height: '200px',
            marginRight: '20px',
          }}
        />
      </div>

      <Container className="container1">
        <Carousel
          responsive={deliveryacroosindia}
          arrows={true}
          draggable={true}
          autoPlay={true}
          infinite={true}
          className="stayfit1"
        >
          {orderImages.map((imageData, index) => (
            <NavLink key={index} onClick={() => handleImageClick(imageData)}>
              <div style={{ position: 'relative', padding: '5px' }}>
                <img
                  src={imageData.image}
                  alt={imageData.district}
                  draggable="false"
                  style={{
                    borderRadius: '5px',
                    width: '100%',
                    height: '270px', 
                    objectFit: 'cover', 
                  }}
                />

                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    width: '90%',
                    height: '90%',
                    borderRadius: '5px',
                    background:
                      'linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 70%)',
                  }}
                ></div>

             
                 
                 
     
      <div
        style={{
          position: "absolute",
         top:"5px",
          left: "10px",
          display: "flex",
          alignItems: "center",
          color: "#fff",
          padding: "5px 10px",
        }}
      >
        <svg width="46" height="46" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1175_14493)">
<path d="M10.8284 21.7037V10.8702L0.287109 5.73828V16.0022L10.8284 21.7037Z" fill="#FFCC80"/>
<path d="M21.3819 5.73438V11.1564H21.3503C20.5064 11.1564 19.7152 11.5151 19.1667 12.148C18.6498 12.7387 18.3966 13.4877 18.4494 14.2577C18.481 14.5953 18.5654 14.9223 18.7025 15.2177C16.9089 16.1872 15.4989 17.7386 14.7046 19.6165L10.8438 21.7051V10.8716L21.3819 5.73438Z" fill="#FFB74D"/>
<path d="M21.3826 5.73409L10.8444 10.8713L6.74096 8.87761L5.03207 8.04426L0.285156 5.73409L10.8233 0.607422L15.3171 2.791L17.1103 3.656L21.3826 5.73409Z" fill="#FFA726"/>
<path d="M16.881 3.5564L6.45466 8.75058V13.9975L4.80273 13.1557V7.94466L15.0877 2.69141L16.881 3.5564Z" fill="#795548"/>
<path d="M16.9005 25.9244C16.6907 25.9244 16.4895 25.8411 16.3411 25.6927C16.1927 25.5443 16.1094 25.3431 16.1094 25.1333V23.1195C16.1094 19.3526 19.1252 16.2871 22.8342 16.2871H24.8121C25.2488 16.2871 25.2488 17.8694 24.8121 17.8694H22.8342C19.9987 17.8694 17.6917 20.2249 17.6917 23.1195V25.1333C17.6917 25.3431 17.6083 25.5443 17.46 25.6927C17.3116 25.8411 17.1104 25.9244 16.9005 25.9244Z" fill="#4CAF50"/>
<path d="M21.3495 20.8894C21.1885 20.8893 21.0313 20.8401 20.899 20.7484C20.7667 20.6566 20.6655 20.5267 20.6089 20.3759C20.5524 20.2252 20.5432 20.0607 20.5826 19.9046C20.622 19.7485 20.7082 19.6081 20.8295 19.5022L23.608 17.0781L20.8295 14.653C20.7512 14.5846 20.6871 14.5015 20.641 14.4084C20.5949 14.3153 20.5675 14.214 20.5605 14.1103C20.5535 14.0066 20.567 13.9026 20.6002 13.8041C20.6334 13.7056 20.6857 13.6147 20.754 13.5364C20.8224 13.4581 20.9055 13.3941 20.9986 13.3479C21.0917 13.3018 21.193 13.2744 21.2967 13.2674C21.4004 13.2604 21.5044 13.2739 21.6029 13.3071C21.7014 13.3403 21.7923 13.3926 21.8706 13.461L25.3316 16.4821C25.4163 16.5566 25.4842 16.6484 25.5306 16.7512C25.577 16.854 25.601 16.9655 25.6009 17.0784C25.6008 17.1912 25.5766 17.3027 25.53 17.4054C25.4834 17.5081 25.4154 17.5997 25.3306 17.6741L21.8696 20.6942C21.7255 20.82 21.5407 20.8893 21.3495 20.8894Z" fill="#4CAF50"/>
</g>
<defs>
<clipPath id="clip0_1175_14493">
<rect width="25.3169" height="25.3169" fill="white" transform="translate(0.285156 0.607422)"/>
</clipPath>
</defs>
</svg>


      
        <span style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "600" }}>
          Deliver to <span style={{ fontSize: "14px", fontWeight: "700" }}>{imageData.district}</span>
        </span>
      </div>
               
              </div>
            </NavLink>
          ))}
        </Carousel>
      </Container>
    </Col>
  </Row>

  {showStories && (
    <StoryViewer
      stories={currentStories}
      isOpen={showStories}
      onClose={() => setShowStories(false)}
    />
  )}
</Container>;




</section> */}

{/* <section className="d-block d-md-none d-lg-none d-sm-none container">

<Container>
      <Row className="backgroundmobile" style={{ marginLeft: '0px', marginTop: '20px', marginBottom: '20px' }}>
        <Col lg={12} md={12} sm={12} col={12} style={{ paddingTop: '150px', paddingBottom: '50px', display: 'flex' }}>
          

          <Container className="container1">
            <Carousel
              responsive={deliveryacroosindia}
              arrows={true}
              draggable={true}
              autoPlay={true}
              infinite={true}
              className="stayfit1">

{orderImages.map((imageData, index) => (
  <NavLink key={index} onClick={() => handleImageClick(imageData)}>
    <div style={{ position: "relative", padding: "5px" }}>
     
      <img
        src={imageData.image}
        alt={imageData.district}
        draggable="false"
        style={{ borderRadius: '5px',
        width: '100%',
        height: '270px', 
        objectFit: 'cover',  }}
      />

    
      <div
        style={{
          position: "absolute",
          top: '5px',
          left: '5px',
          width: "90%", 
          height: "90%", 
          borderRadius: "5px",
          background: "linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 70%)", // Dark to transparent gradient
        }}
      ></div>

     
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          display: "flex",
          alignItems: "center",
          color: "#fff",
          padding: "5px 10px",
        }}
      >
        <svg width="46" height="46" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1175_14493)">
<path d="M10.8284 21.7037V10.8702L0.287109 5.73828V16.0022L10.8284 21.7037Z" fill="#FFCC80"/>
<path d="M21.3819 5.73438V11.1564H21.3503C20.5064 11.1564 19.7152 11.5151 19.1667 12.148C18.6498 12.7387 18.3966 13.4877 18.4494 14.2577C18.481 14.5953 18.5654 14.9223 18.7025 15.2177C16.9089 16.1872 15.4989 17.7386 14.7046 19.6165L10.8438 21.7051V10.8716L21.3819 5.73438Z" fill="#FFB74D"/>
<path d="M21.3826 5.73409L10.8444 10.8713L6.74096 8.87761L5.03207 8.04426L0.285156 5.73409L10.8233 0.607422L15.3171 2.791L17.1103 3.656L21.3826 5.73409Z" fill="#FFA726"/>
<path d="M16.881 3.5564L6.45466 8.75058V13.9975L4.80273 13.1557V7.94466L15.0877 2.69141L16.881 3.5564Z" fill="#795548"/>
<path d="M16.9005 25.9244C16.6907 25.9244 16.4895 25.8411 16.3411 25.6927C16.1927 25.5443 16.1094 25.3431 16.1094 25.1333V23.1195C16.1094 19.3526 19.1252 16.2871 22.8342 16.2871H24.8121C25.2488 16.2871 25.2488 17.8694 24.8121 17.8694H22.8342C19.9987 17.8694 17.6917 20.2249 17.6917 23.1195V25.1333C17.6917 25.3431 17.6083 25.5443 17.46 25.6927C17.3116 25.8411 17.1104 25.9244 16.9005 25.9244Z" fill="#4CAF50"/>
<path d="M21.3495 20.8894C21.1885 20.8893 21.0313 20.8401 20.899 20.7484C20.7667 20.6566 20.6655 20.5267 20.6089 20.3759C20.5524 20.2252 20.5432 20.0607 20.5826 19.9046C20.622 19.7485 20.7082 19.6081 20.8295 19.5022L23.608 17.0781L20.8295 14.653C20.7512 14.5846 20.6871 14.5015 20.641 14.4084C20.5949 14.3153 20.5675 14.214 20.5605 14.1103C20.5535 14.0066 20.567 13.9026 20.6002 13.8041C20.6334 13.7056 20.6857 13.6147 20.754 13.5364C20.8224 13.4581 20.9055 13.3941 20.9986 13.3479C21.0917 13.3018 21.193 13.2744 21.2967 13.2674C21.4004 13.2604 21.5044 13.2739 21.6029 13.3071C21.7014 13.3403 21.7923 13.3926 21.8706 13.461L25.3316 16.4821C25.4163 16.5566 25.4842 16.6484 25.5306 16.7512C25.577 16.854 25.601 16.9655 25.6009 17.0784C25.6008 17.1912 25.5766 17.3027 25.53 17.4054C25.4834 17.5081 25.4154 17.5997 25.3306 17.6741L21.8696 20.6942C21.7255 20.82 21.5407 20.8893 21.3495 20.8894Z" fill="#4CAF50"/>
</g>
<defs>
<clipPath id="clip0_1175_14493">
<rect width="25.3169" height="25.3169" fill="white" transform="translate(0.285156 0.607422)"/>
</clipPath>
</defs>
</svg>


        <span style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "600" }}>
          Deliver to <span style={{ fontSize: "1px", fontWeight: "700" }}>{imageData.district}</span>
        </span>
      </div>
    </div>
  </NavLink>
))}




            </Carousel>
          </Container>
        </Col>
      </Row>

      {showStories && (
      <StoryViewer
      stories={currentStories}
      isOpen={showStories}
      onClose={() => setShowStories(false)}
    />
      )}
    </Container> 



</section>
   */}
  <section className="stayfit container d-none d-md-block d-lg-block d-sm-block">
  <div>
  <Row>
    <Col lg={12} md={12} sm={12}>
      <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
      <img style={{width:"20%", mixBlendMode:""}} src="/assets/stayfit.gif" alt="Description of GIF" />

      </div>


      <Row className="container1" style={{paddingRight:'0px'}}>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/hair-care-supplements'}>
            
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Haircare.png")} alt="black haired men promoting hair care supplements" draggable="false" style={{width:'100%'}}/>
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/skin-care-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Skincare.png")} draggable="false" alt=" smooth skin women promoting skin care supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/eye-care-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Eyecare.png")} draggable="false" alt="men promoting eye care supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/sleep-support-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/SleepSupport.png")} draggable="false" alt="A red-haired woman sleeping on a pillow, promoting sleep supplements" />
            </div>
          </NavLink>
        </Col>
      </Row>

      <Row className="container1">
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/stress-relief-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/StressRelief.png")} draggable="false" alt="A man in a maroon t-shirt holding his head with his fingers, promoting stress relief supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/digestion-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/Digestion.png")}draggable="false" alt="A woman with brown hair holding her stomach, promoting digestion supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/joint-health-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/JiontHealth.png")} draggable="false" alt="A man holding his knees, promoting supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/liver-health-supplements'}>
            <div style={{marginBottom: "3px" }}>
              <img src={require("../assets/images/liver.png")} draggable="false" alt="A human liver with supplements" />
            </div>
          </NavLink>
        </Col>
      </Row>
    </Col>
  </Row>




</div>

</section>
<section className="d-block d-md-none d-lg-none d-sm-none container">
<div className="stayfit">
<Row>
<Col lg={12} md={12} sm={12} xs={12}>
<div style={{display:"flex", justifyContent:"center", padding:"10px"}}>
<img src="/assets/stayfit.gif"  alt="Description of GIF" draggable="false" style={{width:"30%",webkitUserDrag:"none"}} className="stayfitimg" />
</div>
<div className="container1">
<Carousel responsive={stayfitcarousel} arrows={false} draggable={true} autoPlay={true} infinite={true} className="stayfit1">
<NavLink to={'/hair-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Haircare.png")} alt="black haired men promoting hair care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/skin-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Skincare.png")} alt="smooth skin women promoting skin care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/eye-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Eyecare.png")} alt="men promoting eye care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
<NavLink to={'/sleep-support-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/SleepSupport.png")} draggable="false" alt="A red-haired woman sleeping on a pillow, promoting sleep supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
</Carousel>

</div>
<div className="container1">
<Carousel responsive={stayfitcarousel} arrows={false} draggable={true} autoPlay={true} infinite={true} className="stayfit1">

<NavLink to={'/stress-relief-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/StressRelief.png")} draggable="false" alt="A man in a maroon t-shirt holding his head with his fingers, promoting stress relief supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/digestion-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Digestion.png")} draggable="false" alt="A woman with brown hair holding her stomach, promoting digestion supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/joint-health-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/JiontHealth.png")} draggable="false" alt="A man holding his knees, promoting supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
<NavLink to={'/liver-health-supplements'}>
<div style={{padding:"5px"}}>

<img src={require("../assets/images/liver.png")} draggable="false" alt="A human liver with supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

</Carousel>
</div>
</Col>
</Row>


</div>
</section>

 {/* <!-- Third section --> */}
 <section className="mt-20 container d-none d-md-block d-lg-block d-sm-block ">
    <div className="">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
        <Link to ="/peanut-butter">
        <img src={require("../assets/images/PNG/peanutbanner.png")} alt="" style={{webkitUserDrag:"none"}}/>
        </Link>
        </Col>
      </Row>  
    </div>
  </section>
  {/* <!--End Third section --> */}
  <section className="mt-20 container d-block d-md-none d-lg-none d-sm-none">
    <div className="">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
        <Link to ="/peanut-butter">
        <img src={require("../assets/images/PeanutButter.png")} alt="biggest peanut butter sale" style={{webkitUserDrag:"none"}}/>
        </Link>
        </Col>
      </Row>  
    </div>
  </section>

  
  
<section className="container" style={{padding:"20px 19px 0px 19px"}}>
 
    <Row>
      <Col lg={6} md={6} sm={12} xs={12} style={{padding:"6px"}}>
        <NavLink to={'/weight-loss-supplements'}>
          <img src={require("../assets/images/PNG/weightloss.png")} alt="Top-best-weight-loss-supplements" style={{webkitUserDrag:"none"}}/>
        </NavLink>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12} style={{padding:"6px"}}>
        <NavLink to={'/weight-gain-supplements'}>
          <img src={require("../assets/images/PNG/Weightgain.png")} alt="Top-best-weight-gain-supplements" style={{webkitUserDrag:"none"}}/>
        </NavLink>
      </Col>
     
    </Row>  
 
</section>

<section className="container" style={{ borderRadius: "30px" }}>
  <div
    className="product_of_the_day mt-20 mb-20"
    style={{
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {/* Background Image */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${require("../assets/images/productofday.png")})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1,
        borderRadius: '30px',
      }}
    />
    
    <h3 className="productofday"
      style={{
        position: 'absolute',
        top: '8%', 
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
        fontFamily: 'Inter',
        fontWeight: '500',
        textAlign: 'center'
      }}
    >
      Product of the Day
    </h3>

    <Row style={{ width: '100%', position: 'relative', zIndex: 10 }}>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: '90px',
          paddingTop: '40px',
          margin: '30px 30px 0px 0px',
          marginBottom: '10px',
        }}
      >
  
          {/* Carousel Container */}
          <div 
            className="carousel-container container1"
            style={{
              position: 'relative',
              padding: '15px 0px 22px',
              
            }}
          >
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}  
              arrows={true}
              responsive={productsresponsive1}
              draggable={true}
              infinite={true}  
              className="stayfit1"
              
            >
              {productofday.map((element) => (
                <Col style={{ padding: '3px' }} key={element.product_id}>
                  <ProductItemCard style={{height:"300px"}}
                    add_cart={AddToCartCall}
                    url_title={element.url_title}
                   
                    image={element.image}
                    rate={element.average_rating}
                    dis={element.discount_percentage}
                    cart_quantity={element.cart_quantity}
                    name={element.name}
                    diet_type={element.diet_type}
                    tag={element.item_tag}
                    elite_price={element.elite_price}
                    saleprice={element.price}
                    mrp={element.mrp}
                    cart_qnt={element.cart_quantity}
                    id={element.product_id}
                    notified={element.is_notified}
                    max_qnty={element.max_cart_quantity}
                    nuin={element.nuin}
                    stock={element.stock}
                    notify_me = {NotifyMeCall}
                    is_subscribed = {is_subscribed}
                    clicked = {addClicked === element.product_id}
                    notifyClicked = {notifyClicked === element.product_id}
                    index = {element.product_id}
                   
                  />
                </Col>
              ))}

              {/* <Col className="view-more-card" style={{ padding: '5px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    borderRadius: '29px',
                    backgroundColor: 'white',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '100%',
                    height: '300px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Link to="/Products/Brand/11">
                    <img
                      src={require("../assets/images/view.png")}
                      alt="view more"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Link>
                </div>
              </Col> */}
            </Carousel>
          </div>
    
      </Col>
    </Row>
  </div>
</section>
<div className="mainCarousel3 container card" style={{ paddingBottom: "0px", overflow: 'hidden',borderRadius:"5px", paddingTop:"3px",marginTop:"10px",marginBottom:"20px" }}>
  <div className="RecentlySearch">
              <h2 className="RecentlySearch" style={{paddingTop:"15px",fontWeight:"600",fontFamily:"inter"}}>Top Brands At Nutsby</h2>
              </div>
              <div style={{ width: '100%', position: 'relative', zIndex: 10,paddingTop:"10px",paddingBottom:"10px" }}>
  <Col lg={12} md={12} sm={12} xs={12}>
    <Slider {...sliderSettings1}>
      {brands.map((brand) => (
        <div key={brand.id} style={{ padding: '5px', margin: '10px' }}> 
          <div style={{
           
            borderRadius: '10px',
            overflow: 'hidden', 
          }}>
            <a href={`/${brand.url_title}/brand/${brand.brand_hash_id}`}>
            <img
              src={brand.image}
              alt={brand.name}
              style={{
                height: '100%',
                width: '95%',
                padding: "2px",
                webkitUserDrag: 'none',
                borderRadius: '10px', 
                backgroundColor: '#F5F5F5',
              }}
            />
            </a>
          </div>
        </div>
      ))}
    </Slider>
  </Col>
</div>

            
    </div>

<section className="container">
    <div className="gradient-background  container" style={{borderRadius:"30px"}}>
      <Row>      
        <Col md={12}>
          <div className="pamperhealth">
              <h2 style={{fontFamily: "Inter", fontStyle:"italic", color: "#fff",textAlign:"center", fontWeight:"700"}}>Pamper Health</h2>
              <h3 style={{fontFamily: "Inter", fontStyle:"italic", color: "#fff",textAlign:"center", 
                padding:"0px", fontWeight:"400"}}>at any age</h3>
          </div>
        </Col>
      </Row>  
      <div className="container1">
        <Carousel responsive={PamperHealthCarousel} arrows={false} draggable={true} autoPlay={false} infinite={true} className="stayfit1">
          {/* <Carousel.Item>  */}
            <div style={{display:"flex"}}>
            <NavLink to={'/kids-8-7'}>
              <div>
                <img src={require("../assets/images/Age8-17.png")} alt="Best Vitamins and Supplements for Children and Teens" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow}/>
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
            <div style={{display:"flex"}}>
            <NavLink to={'/youngsters-18-27'}>
              <div>
                <img src={require("../assets/images/Age18-27.png")} alt="Top Protein and Supplements for Young Adults" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow}/>
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
         
          <div style={{display:"flex"}}>
          <NavLink to={'/youth-28-37'}>
              <div>
                <img src={require("../assets/images/Age28-37.png")} alt="Best Health Supplements for Adults" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
          
          {/* <Carousel.Item>  */}
          <div style={{display:"flex"}}>
          <NavLink to={'/mid-age-38-50'}>
              <div>
                <img src={require("../assets/images/Age38-50.png")} alt="Top Health Supplements for Adults Under Ages 38-50" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
          <div style={{display:"flex"}}>
          <NavLink to={'/senior-citizens-above50'}>
              <div>
                <img src={require("../assets/images/Age50+.png")} alt="Best Protein supplements for senior citizens" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
        </Carousel>  
      </div>
    </div>     
</section>


<section className="RecentlySearch mt-40 container" >
    <div>
      <Row>      
        <Col md={12}>
          <div className="Recentserachedprods mx-2 d-flex justify-content-between align-items-center"> 
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h2 style={{fontFamily: "Inter", fontWeight: "600", textAlign: ""}}>Recently Searched Products</h2>
            </div>
            <div className="d-block d-sm-block d-lg-block d-xl-block">
            <h5 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* <span style={{ flex: 1 }}>
       <Link to="/Products/Brand/11" style={{ fontFamily: "Inter", color: "#219EBC", marginBottom: "0" ,paddingRight: "6px"}}>
        Explore All
       </Link>
      </span>
     <span>
      <ExploreIcon style={{ width: "12px", height: "5px" }} />
    </span> */}
   </h5>

            </div>
          </div>
          
        </Col>
      </Row> 
      <div style={{paddingBottom: "20px"}}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">                 
            { TrendingProducts.map((element) =>
              <Col style={{padding:"3px"}}> 
                <ProductItemCard
                   add_cart={AddToCartCall}
                   url_title={element.url_title}
                  
                   image={element.image}
                   rate={element.average_rating}
                   dis={element.discount_percentage}
                   cart_quantity={element.cart_quantity}
                   name={element.name}
                   diet_type={element.diet_type}
                   tag={element.item_tag}
                   elite_price={element.elite_price}
                   saleprice={element.price}
                   mrp={element.mrp}
                   cart_qnt={element.cart_quantity}
                   id={element.product_id}
                   notified={element.is_notified}
                   max_qnty={element.max_cart_quantity}
                   nuin={element.nuin}
                   stock={element.stock}
                   notify_me = {NotifyMeCall}
                   is_subscribed = {is_subscribed}
                   clicked = {addClicked === element.product_id}
                   notifyClicked = {notifyClicked === element.product_id}
                   index = {element.product_id}
                />
              </Col> 
            )} 
                               
          </Carousel> 
        </div>
      </div>
    </div>              
</section>

<Modal 
            show={showform} 
            onHide={handleCloseform} 
            centered 
            style={{ borderRadius: "15px" }}  
        >
            <Modal.Header closeButton style={{borderBottom:"none",background: "#F0F4F5", paddingBottom:"0px"}}>
            <Modal.Title style={{ 
  fontWeight: "600", 
  fontSize: "18px", 
  color: "#676767", 
  marginBottom: "5px", 
  display: "flex", 
  flexDirection: "column" 
}}>
  Rate Your Experience
  <span style={{ fontSize: "14px", color: "#999999", marginTop: "0px" }}>#{ordernumber}</span>
</Modal.Title>

                </Modal.Header>
            <Modal.Body style={{ background: "#F0F4F5", padding: "10px 20px 20px", borderRadius: "15px" }} closeButton>
                {/* <div style={{ fontWeight: "600",fontSize:"19px", color: "#666666", marginBottom: "5px" }}>
                    Rate Your Experience
                </div> */}
              <div className="text-center mb-4" style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "white", borderRadius: "24px", padding: "15px" }}>
            <div style={{ color: "#2D2D2D", fontSize: "16px", fontWeight: "600" }}>How did we do?</div>
            <p style={{ color: "#999999", fontWeight: "600", fontSize: "14px", marginBottom: "5px" }}>Please rate us!</p>
            
            <div className="col-6" style={{ display: "flex", gap: "35px", alignItems: 'center', justifyContent: "center", padding: "10px", borderRadius: "24px" }}>
    {renderEmoji(0)}   
    {renderEmoji(2.5)} 
    {renderEmoji(5)}   
</div>
        </div>
                {showFeedbackForm && (
                  <>
    <div style={{  marginTop: "20px", textAlign: "start" }}>
        <div></div>
        
        <Form onSubmit={handleSubmit} >
          <div style={{background:"white", padding: "20px", borderRadius: "24px",}}>
        <div style={{ color: "#2D2D2D", fontSize: "16px", fontWeight: "600" }}>We want to hear from you</div>
        <p style={{ color: "#999999", fontWeight: "600", fontSize: "14px", marginBottom: "5px" }}>
            Your feedback is important! Let us know what you think or how we can improve.
        </p>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Write Here..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    style={{ borderRadius: "10px", background: "#EDEDED" }}
                />
            </Form.Group>
            </div>
            <Button
           variant="primary"
    type="submit"
    style={{ backgroundColor: "#023047", borderColor: "#023047", color: "white", width: "100%", marginTop: "10px" }}
>
    Submit
</Button>
        </Form>
    </div>
   
</>
)}


            </Modal.Body>
        </Modal>



  <section className="mt-40 mb-60 bottom_sliders1 container">
  <Row>
    <Col lg={6} md={6} sm={12} xs={12} className="mt-20">
      <NavLink to={'/fresh-and-healthy'}>
        <img
          src={require("../assets/images/PNG/HS1.png")}
          alt="fresh and healthy juiceh/ in the jar"
          style={{ maxWidth: "100%" }}
          draggable="false"
        />
      </NavLink>
    </Col>
    <Col lg={6} md={6} sm={12} xs={12} className="mt-20">
      <NavLink to={'/healthy-snacks'}>
        <img
          src={require("../assets/images/PNG/HS2.png")}
          alt="healthy biscuits with tea and butter"
          style={{ maxWidth: "100%" }}
          draggable="false"
        />
      </NavLink>
    </Col>
  </Row>
</section>



    <section className="mb-60 mt-50 nutrieats1_slider container" >
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} >
          <NavLink to={'/Nutrieats'}>
          <img src={require("../assets/images/PNG/NUTRI.png")} alt="high protein nuts and dried fruits up to 20% off"  style={{borderRadius:"18px"}} />
          </NavLink>
        </Col>
      </Row>  
    </section>
 

  {/* <!-- 8th section  --> */}

  <SubscribeForm/>
  {/* <!-- end of 8th section  --> */}

   {/* <!-- 9th section --> */}
   <DownloadTheApp/> 
  {/* <!-- end of 9th section --> */}
  

  <section className="mt-20 mb-120"  >
    <div className="container" style={{fontFamily: "Inter" , fontWeight:"300"}}>
      <div >
      <h1 style={{lineHeight:'20px',fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'
}
}>
  <strong>Nutsby: Your Premier Destination for Health and Fitness</strong>
</h1>

     
<p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
            Elevate your health and fitness journey with Nutsby, your ultimate destination for premium products. 
            We offer a diverse range of high-quality items, including fitness supplements, sports nutrition, and 
            weight management solutions. Don’t miss our Nutieats line, featuring meticulously picked dry fruits 
            sourced directly from the best farms. Our platform not only provides top-notch products but also offers 
            valuable insights on wellness and nutrition from our team of experts. Enjoy a seamless shopping 
            experience with personalized recommendations and convenient delivery straight to your door. Whether 
            you're looking to enhance overall health or achieve specific fitness goals, Nutsby has you covered. 
            Explore our selection today and take the next step towards your wellness journey with Nutsby!
          </p>
         <p className="mt-20" style={{lineHeight:'20px',fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> <strong>Health and Wellness Made Easy at Nutsby*</strong></p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}} className="mt-20">
            Looking for a reliable online store for all your health and wellness needs? Nutsby is your destination! 
            We offer an extensive range of products across several categories, each designed to support your 
            lifestyle. Check out what we have in store:
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>1.&nbsp;Health Foods &amp; Beverages&nbsp;</strong>-Explore a variety of healthy snacks, organic teas, and 
              nutritious options including biscuits, cookies, nuts, seeds, protein, health drinks, chocolates, 
              flours, oils, muesli, and cereals. Our Nutieats line features meticulously picked dry fruits, sourced 
              directly from the finest farms to ensure top quality and freshness. From quinoa and oats to 
              ready-to-cook meals and wellness coffee, discover everything you need to support a healthier lifestyle.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>2.&nbsp;Hair, Skin &amp; Nails&nbsp;</strong>-Enhance your beauty regimen with our supplements for hair, skin, 
              and nails. Choose from biotin, collagen, glutathione, vitamin E, and other essential nutrients that 
              help strengthen your hair, improve skin elasticity, and support healthy nails. Our products are 
              formulated to help you shine from within.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>3.&nbsp;Weight Management&nbsp;</strong>- Achieve your weight goals with our range of weight loss and 
              maintenance supplements. From Apple Cider Vinegar and Garcinia Cambogia to Green Coffee Beans, 
              Sugar Substitutes, and Slimming Shakes, we offer high-quality options to assist you in reaching 
              your fitness objectives.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong> 4.&nbsp;Sports Nutrition&nbsp;</strong>-  Boost your performance with our sports nutrition products. We offer 
              Whey Protein, Vegan & Plant-Based Protein, L-Carnitine, BCAA, Creatine, Pre & Post-Workout supplements,
              Protein Bars, Sports Drinks, and Mass Gainers. Our premium selection helps you maximize your workouts 
              and fitness goals.

          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>5.&nbsp;Women's Health&nbsp;</strong>-Support your unique health needs with our range of women's supplements, 
              including options for period pain relief, PCOD/PCOS, and specialized protein. Our high-quality products
              are designed to assist in your wellness journey.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>6.&nbsp;Kids Nutrition&nbsp;-&nbsp;</strong>Provide your children with nutritious options including cereals, 
              granola, protein, quick meals, shakes, juices, snacks, and essential vitamins like Calcium and D3. 
              Our products support healthy growth and development for your little ones.
          </p>
      </div>
      <div className="mt-20">
          <p className="mt-10" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          Welcome to Nutsby, your ultimate destination for all things health and wellness! Whether you're looking for fitness foods, supplements, or hair and skin care, we've curated a range of products to fit every lifestyle. From weight management to sports nutrition, women's health, and kids' nutrition, we cater to your unique needs with quality and care. Dive into our Ayurvedic and herbal supplements for a natural boost, and take advantage of our exciting seasonal discounts. At Nutsby, living healthy has never been more fun or accessible!
             
          </p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> <strong style={{paddingTop: "50px",fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> HISTORY OF NUTSBY</strong></p>
          <p className="" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
           Nutsby, your sanctuary for nutrition and nourishment on the path to a healthier, happier you. In today's fast-paced world, where uncertainties abound, health is paramount. That's where Nutsby steps in. We offer genuine products at fair prices, paving the path to wellness for all. Guided by transparency, we strive toward our vision of a thriving world of health. Join us on this passionate journey to a healthier tomorrow

          </p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}><strong>We offer a range of health and wellness products to help you achieve your best self. Here are just a few of our top products:</strong></p>
          <ul style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
              <li style={{listStyle: "inside"}}><strong>Multivitamin Mania:</strong>&nbsp;For all your nutrient needs in one convenient capsule</li>
              <li style={{listStyle: "inside"}}><strong>Omega 3 &amp; Fish Oil:&nbsp;</strong>Keep your heart healthy with our high-quality supplements</li>
              <li style={{listStyle: "inside"}}><strong>Collagen:&nbsp;</strong>Promote healthy skin and joints with our&nbsp;premium collagen powder</li>
              <li style={{listStyle: "inside"}}><strong>Biotin:&nbsp;</strong>Let your hair do the talking with our hair care essential supplement</li>
              <li style={{listStyle: "inside"}}><strong>Apple Cider Vinegar:&nbsp;</strong>Kickstart your weight loss journey with our natural remedy</li>
              <li style={{listStyle: "inside"}}><strong>Whey Protein:&nbsp;</strong>Build muscle and stay strong with our premium whey protein powder</li>
              <li style={{listStyle: "inside"}}><strong>Peanut Butter:&nbsp;</strong>Enjoy a healthy and delicious snack with our all-natural peanut butter</li>
          </ul>
      </div>
      <div className="mt-50 mb-80">
  <p className="mt-40" style={{ color: "#353535", borderTop: "1px solid", paddingTop: "50px", fontSize: "15px",fontWeight:"500" }}>
    TOP CATEGORIES ON NUTSBY
  </p>
  <p className="style123" style={{ paddingTop: "0px", fontSize: "14px",border:"none" }}>
    <a href="/peanut-butter" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Peanut Butter |</a>
    <a href="/maingoal/muscle-building" style={{ textDecoration: "none" , fontSize: "14px",fontWeight:"500"}}>Muscle Building |&nbsp;</a>
    <a href="/maingoal/athelete-journey" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Athlete journey |&nbsp;</a>
    <a href="/maingoal/happy-healthy" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Happy & Healthy |&nbsp;</a>
    <a href="/maingoal/fitness-forever" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Fitness Forever&nbsp;|&nbsp;</a>
    <a href="/hair-care-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Skin Care |&nbsp;</a>
    <a href="/skin-care-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Hair Care |&nbsp;</a>
    <a href="/eye-care-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Eye Care |&nbsp;</a>
    <a href="/sleep-support-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Sleep Support| &nbsp;|</a>
    <a href="/liver-health-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Liver Care&nbsp;|</a>
    <a href="/stress-relief-supplements" style={{ textDecoration: "none", fontSize: "14px",fontWeight:"500" }}>Stress Relief&nbsp;|&nbsp;</a>
    <a href="/digestion-supplements" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Digestion&nbsp;|</a>
    <a href="/joint-health-supplements" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>&nbsp;Joint Healthy&nbsp;|</a>
    <a href="/weight-loss-supplements" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Weight loss&nbsp;|</a>
    <a href="/weight-gain-supplements" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Weight Gain&nbsp;|</a>
    <a href="/fresh-and-healthy" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Fresh & Healthy&nbsp;|</a>
    <a href="/healthy-snacks" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Healthy Snacks&nbsp;|</a>
    <a href="/nutrieats" style={{ textDecoration: "none",fontSize: "14px",fontWeight:"500" }}>Nutrieats</a>
  </p>
</div>


    </div>
   
  </section>
  
  <BottomBar/>
   </div>
   }
    </>
  );
}else{
  return (
    <section className="">
    <Container className="mt-80 d-none d-md-block d-lg-block " style={{paddingBottom: "20px",height:"",alignContent:"center",marginTop:"10em"}}>
    <Row className="mt-10 ">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20 ">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" />
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
   display:"block"
  }} />
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" rounded={1}  height={290} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={190} width="auto" />      
        </Col>
        ))}
        </Row>

        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
    
    
  <Row className="mt-20">
        {shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
  display:"block"
  }}/>
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={190} width="auto" />      
        </Col>
        ))}
        </Row>
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
      
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-block mainCarousel1" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-0">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={120} width="auto" />
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
  display:"block"
  }} />
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" rounded={1}  height={120} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={90} width="auto" />      
        </Col>
        ))}
        </Row>

        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
    
    
  <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
   display:"block"
  }}/>
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={120} width="auto" />      
        </Col>
        ))}
        </Row>
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
      
      </Col>
    </Row>
</Container>
</section>
  )
}
}

export default Home;