import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { SubmitProductReview } from '../Service/ApiCalling';

export default function WriteReviewpopup({ detailsData = {}, handleSubmitReviewas, from, index,prodRating }) {
    const [rate, setRate] = useState(0);
    const [headline, setHeadline] = useState('');
    const [review, setReview] = useState('');
    const [file, setFile] = useState([]);
    const [prod_id, setProduct_id] = useState(null);
    const [productitemrating,setProductRating] = useState(null);

    useEffect(() => {
        // Ensure product ID is set based on the data source (product details or list)
        if (from === "product details" && detailsData.product_id) {
            setProduct_id(detailsData.product_id);
        } else if (detailsData[index] && detailsData[index].product_id) {
            setProduct_id(detailsData[index].product_id);
        }
        if(prodRating>0){
            // console.log(prodRating,"prodrating")            
            setRate(prodRating);
        }else{
            // console.log(prodRating,"prodrating")
        }
    }, [detailsData, from, index]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const fileURLs = files.map(file => URL.createObjectURL(file));
        setFile(prevFiles => [...prevFiles, ...fileURLs]);
    };

    const handleSubmitReview = async (Product_id) => {
        console.log(Product_id, "id");
        let data = await SubmitProductReview(Product_id, rate, review, headline, file);
        if (data.error === "0") {
            console.log("Review submitted");
            setRate(0);
            setHeadline('');
            setReview('');
            setFile([]);
            // Optionally trigger some success action or close the modal
        } else {
            console.log("Error submitting review");
        }
    };

    return (
        <Container className="d-flex align-content-center" style={{ backgroundColor: "white" }}>
            <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '10px' }}>
            <div className="d-flex align-items-center mt-0">
                    <div style={{fontWeight: "600",fontsize:"16px" }}>Review this product </div>
                </div>
               
   
        <span style={{ color: "#999999", fontWeight: "600",fontSize:"14px" }}>
            What’s your experience with this product? <br /> Share your thoughts!
        </span>
        <Row className="mt-10">
                    <Col lg={3} md={3} sm={3} xs={3}>
                        <img  style={{background:"#F5F5F5",borderRadius:"6px"}}
                            src={from === "product details" ? detailsData.images[0].image : detailsData[index].image} 
                            alt={from === "product details" ? detailsData.short_name : detailsData[index].short_name} 
                            fluid 
                        />
                    </Col>
                    <Col lg={9} md={9} sm={9} xs={9} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{fontSize:"14px",color:"#999999",fontWeight:"600"}} className="text-justify">{from === "product details" ? detailsData.short_name : detailsData[index].short_name}</div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <h5 style={{color:"#2D2D2D",fontSize:"14px",fontWeight:"500" }}>Rate this Product</h5>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <FontAwesomeIcon 
                                key={star} 
                                icon={faStar} 
                                onClick={() => setRate(star)} 
                                style={{ width: "35px", height: "35px", color: rate >= star ? '#219ebc' : 'lightgray' }} 
                            />
                        ))}
                    </Col>
                </Row>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    if (from === "product details") {
                        handleSubmitReview(detailsData.product_id);
                    } else {
                        handleSubmitReview(detailsData[index].product_id);
                    }
                }}>
                    <Row style={{ marginTop: "20px" }}>
                        <h5 style={{ fontWeight: "normal" }}>Add a Headline</h5>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="text" 
                                placeholder="Write a headline" 
                                style={{ borderColor: "black" }} 
                                value={headline}
                                onChange={(e) => setHeadline(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Container style={{ marginTop: "10px" }}>
                        <Row>
                            <Col lg={2} md={2} sm={2} xs={4}>
                                <div className="dotted-border-box" style={{ width: "100px", height: "100px" }}>
                                    <div className="centered-content">+</div>
                                    <input 
                                        type="file" 
                                        onChange={handleFileChange} 
                                        style={{ opacity: 0, position: "relative", zIndex: 1, width: "100px", height: "100px" }} 
                                    />
                                </div>
                            </Col>
                            {file.map((element, index) => (
                                <Col lg={2} md={2} sm={2} xs={4} key={index}>
                                    <img src={element} alt="Uploaded file" className="dotted-border-box" style={{ padding: 0, width: "100px", height: "100px" }} />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <h5 style={{ paddingTop: "10px", marginBottom: "0px" }}>Add a photo or video</h5>
                    <p style={{ fontSize: 'smaller', marginBottom: "5px" }}>Images and videos are more helpful than text</p>
                    <Row style={{ marginTop: "20px" }}>
                        <h5 style={{ fontWeight: "normal" }}>Write a review</h5>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                as="textarea"
                                rows={7} 
                                placeholder="Write something about the product" 
                                style={{ borderColor: "black" }} 
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                        <Button 
                            variant="primary" 
                            type="submit" 
                            style={{ borderColor: "orange", backgroundColor: "orange", color: "black", width: "100px" }}
                        >
                            Submit
                        </Button>
                    </Row>
                </Form>
            </Col>
        </Container>
    );
}
