 
import { Container, Row, Col, Button } from 'react-bootstrap';
import { NavLink,Link, useParams } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import React,{ useState,useEffect } from 'react';
import { PeanutbutterDataApi,AddToCart, NotifyMeApi, GetHeaderDetailsApi } from '../Service/ApiCalling';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf'; 
import { DiscountIcon, EliteIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from '../MainComponents/SubComponents/MiniComponents/ProductItemCard';
import { useCart } from '../MainComponents/SubComponents/MiniComponents/CartContext';
import "../SubCss/goals.css";
import "../SubCss/peanut_butter.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Slider from 'react-slick';
import MetaTags from '../MainComponents/Metatags';
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton } from "shimmer-effects-react";
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
export default function PeanutButter() {
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
  const ess_shimmeritems1 = Array.from({ length: 3 })
  const main_shimmeritems = Array.from({ length: 2 });
    const [is_subscribed,setIsSubscribed] = useState('');
    const [brands,setbrands] =useState([]);
    const [top_grossing, settop_grossing] = useState([]);
    const [main_goal,setmaingoal] =useState([])
    const [sub_goal,setsubgoal] =useState([])
    const { setCartCount } = useCart();
    const [addClicked,setAddClicked] = useState(null);
  const [notifyClicked, setNotifyClicked] = useState(null);
  const [productsLoading,setLoading] = useState(true);
  

    const params = useParams();


    const fetchInfo=async ()=> {  
        // if(params.id==="1"){
            let PeanutbutterDataApiData = await PeanutbutterDataApi();
            if (PeanutbutterDataApiData.error==="0") {  
                
                settop_grossing(PeanutbutterDataApiData.top_grossing);
                setmaingoal(PeanutbutterDataApiData.cards);
                setsubgoal(PeanutbutterDataApiData.cards_new);
                setbrands(PeanutbutterDataApiData.brands);
                setLoading(false);
            }else{
              setLoading(false);
            }
            
            
        // }

    
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
       
        {
          breakpoint: 1024, 
          settings: {
            slidesToShow: 4, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 300, 
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
const AddToCartCall=async (p_id,qnt)=> { 
    // AddToCart 
    console.log(qnt)
    // if(qnt>0)
    //     {
      const utm_source = localStorage.getItem("utm_source") ?? "N/A";
const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
       
        
            let addcartDatatemp = await AddToCart(p_id,qnt,utm_source,utm_medium,utm_campaign);
            if (addcartDatatemp.error === "0") {
              ReactGA.event({
                category: 'add to cart',
                action: 'Add TO cart',
                label: 'Purchase',
                value:'',
              });
              ReactPixel.track('AddToCart');
              toast.success(addcartDatatemp.message, {
                autoClose: 3000,
              });
                // fetchInfo(); 
                toast.success(addcartDatatemp.message, {
                  autoClose: 3000,
                }); 
                    const newList = top_grossing.map((item) => {
                        if (item.product_id === p_id) {
                          const updatedItem = {
                            ...item, 
                            cart_quantity: addcartDatatemp.cart_quantity,
  
                          };
                  
                          return updatedItem;
                        } 
                        return item;
                      });
                  
                      settop_grossing(newList); 
                    // setcartCount(addcartDatatemp.items_count);
  
                    // const newupdatedItem = {
                    //     ...cartCalculationData,
                    //     items_total: addcartDatatemp.cart_price,
                    //     total_price: addcartDatatemp.grand_total,
                    //     total_price: addcartDatatemp.grand_total_raw,
  
                    //   };
                    //   setcartCalculationData(newupdatedItem);
                // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
                // this.setState( detailsData.name="AKash");
          
              const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
            }
        // }
   
  };

  const NotifyMeCall = async (p_id, type) => {
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = top_grossing.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            return updatedItem;
          }
          return item;
        });
        settop_grossing(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {
//   setIsSubscribed(localStorage.getItem("is_subscribed"));
  
    fetchInfo(); 
  }, []);




  

  
  const productsresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    minidesktop: {
      breakpoint: { max: 1495, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
    items: 4,
  },
  minitablet: {
    breakpoint: { max: 900, min: 700 },
  items: 3,
},
  minitablet: {
    breakpoint: { max: 700, min: 464 },
  items: 3,
},
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, 
  },
}; 

  
 

  
  if(!productsLoading){
    return (
      <>
       
       <MetaTags
      
      title="Buy Peanut Butter online at best prices | Nutsby"
      description="Shop our peanut butter and enjoy nutritious products at unbeatable prices. Don’t miss out on great deals on peanut butter at Nutsby. 100% Authentic"
      keywords="peanut butter sale 1kg,peanut butter sale online,peanut butter sale open now,peanut butter for sale in bulk,peanut butter sale in india,top rated peanut butter sale,my fitness peanut butter,pintola peanut butter,peanut butter 1kg,peanut butter flavours"
      canonical={""}
      url="https://www.nutsby.com"
      image="https://www.nutsby.com/images/logo.jpg" 
      alt={""}
      ogTitle="Shop Now for Big Savings"
      ogUrl={""}
      ogDescription="Shop our peanut butter sale and enjoy delicious, nutritious products at unbeatable prices. Don’t miss out on great deals on peanut butter at Nutsby."
      ogImage={""}
      indexing="index, follow"
      schema={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Nutsby Fitcare LLP",
        "url": "https://nutsby.com",
        "logo": "https://nutsby.com/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-1234567890",
          "contactType": "Customer Service",
        },
      }}
    />
  
  {
  
  <div className="" style={{marginBottom:'20px'}}> 
          <section>
          <div onClick={()=>{}} >
            <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px" ,paddingTop: "10rem"}} > 
            <Row style={{display:'flex',justifyContent:'center'}}>
                  <Col lg={12} md={12} sm={12} col={12} >
                    <img src={require("../assets/images/PNG/wbpb1.png")} alt="biggest peanut butter sale" /> 
                    </Col>                    
                </Row> 
            </div>
            <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "10rem",paddingLeft:"10px"}} > 
                <Row>
                <Col lg={12} md={12} sm={12} col={12}>
                <img src={require("../assets/images/PNG/peanutsmall.png")} alt="biggest peanut butter sale" /> 
                    </Col>                      
                </Row> 
            </div>  

            <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px"}}>
                 
                  <Breadcrumb>
                   <Breadcrumb.Item
                    role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                   <Breadcrumb.Item href="#">
                      Peanut Butter
                      </Breadcrumb.Item>
                   
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
                  <Breadcrumb>
                  <Breadcrumb.Item
                    role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                   <Breadcrumb.Item href="#">
                      Peanut Butter
                      </Breadcrumb.Item>
                   </Breadcrumb>
                </Col>
                </div> 
          </div>
        </section>
  
  <div>
    <div>
    <h4 style={{display:'flex',justifyContent:'center',paddingTop:'10px'}}>What’s your taste!</h4>
    <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', paddingLeft: '5px',marginBottom:'20px' }}>
     
     <Row className='container'>
       {main_goal && main_goal.length > 0 && (
         <>
         
           <Col xs={12} sm={12} md={6}  className="mb-1" style={{paddingRight:'2px'}}>
           <NavLink className="cardcategory" to={`/${main_goal[0].url_title}/collection/${main_goal[0].hash_id}`}>
             <div className="image-container" >
               <img src={main_goal[0].web_image} alt="different flavours of peanut butter" style={{ width: '100%', height: '100%',paddingRight:'8px' }} />
             </div>
             </NavLink>
           </Col>
           
          
           <Col xs={12} sm={12} md={6} className="mb-1" style={{paddingLeft:'2px'}}>
           <NavLink className="cardcategory" to={`/${main_goal[1].url_title}/collection/${main_goal[1].hash_id}`}>
             <div className="image-container" >
               <img src={main_goal[1].web_image} alt="sugar free peanut butters" style={{ width: '100%', height: '100%',paddingLeft:'8px' }} />
             </div>
             </NavLink>
           </Col>
           
         </>
       )}
     </Row>
   </section>
    
    </div>
  </div>


    {/* <!-- Fourth section --> */}
    <section className="container" style={{marginBottom:'20px'}} >
         
         <Row>      
             <Col md={12}>
                 <div className="newarrivalproducts" > 
                 <h4 style={{ textAlign: "start", margin: "20px 10px 10px 0px" }}>Top Grossing Products</h4>
                 </div>
             </Col>
         </Row>  
  
         <div style={{  paddingBottom: "20px"}}>
         <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">   

  
                 { top_grossing.map((element) =>
                                 <Col style={{padding:"2px"}}> 
                                  <ProductItemCard
                                    add_cart={AddToCartCall}
                                    url_title={element.url_title}
                                   
                                    image={element.image}
                                    rate={element.average_rating}
                                    dis={element.discount_percentage}
                                    cart_quantity={element.cart_quantity}
                                    name={element.name}
                                    diet_type={element.diet_type}
                                    tag={element.item_tag}
                                    elite_price={element.elite_price}
                                    saleprice={element.price}
                                    mrp={element.mrp}
                                    cart_qnt={element.cart_quantity}
                                    id={element.product_id}
                                    notified={element.is_notified}
                                    max_qnty={element.max_cart_quantity}
                                    nuin={element.nuin}
                                    stock={element.stock}
                                    notify_me = {NotifyMeCall}
                                    is_subscribed = {is_subscribed}
                                    clicked = {addClicked === element.product_id}
                                    notifyClicked = {notifyClicked === element.product_id}
                                    index = {element.product_id}
                                     /> 
                                
                                 </Col> 
   )}
               
             </Carousel> </div>
         </div> 
       
   </section>
  {/*<!--third section --->*/}
  <div>
    <div >
    <h4 style={{display:'flex',justifyContent:'center',paddingTop:'0px'}}>Feel the Butter</h4>
    <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', paddingLeft: '5px',marginBottom:'20px' }}>
      
      <Row className='container'>
        {sub_goal && sub_goal.length > 0 && (
          <>
         
            <Col xs={12} sm={12} md={6} className="mb-1" style={{paddingRight:'2px'}}>
            <NavLink className="cardcategory" to={`/${sub_goal[0].url_title}/collection/${sub_goal[0].hash_id}`}>
              <div className="image-container">
                <img src={sub_goal[0].web_image} alt="healthiest creamy peanut butter" style={{ width: '100%', height: '100%',paddingRight:"8px"}} />
              </div>
              </NavLink>
            </Col>
            
            
            <Col xs={12} sm={12} md={6}className="mb-1" style={{paddingLeft:'2px'}}>
            <NavLink className="cardcategory" to={`/${sub_goal[1].url_title}/collection/${sub_goal[1].hash_id}`}>
              <div className="image-container">
                <img src={sub_goal[1].web_image} alt="crunchy peanut butter sale" style={{ width: '100%', height: '100%',paddingLeft:"8px" }} />
              </div>
              </NavLink>
            </Col>
            
          </>
        )}
      </Row>
    </section>
    </div>
  </div>
  
 
  <div style={{marginTop:'30px'}}>
   <h4 style={{ textAlign: "center", margin: "20px 40px" }}>Top Brands</h4>
    <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,}}>
    {/* Heading */}
   
  
    {/* Content */}
    <div className="container d-none lg-block md-block d-sm-block" style={{ cursor: "pointer",}}>
      <Row style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
    
        {brands.length > 0 && brands.map((item, index) => (
          <Col key={index} style={{ padding: '5px' }} xs={4} sm={4} lg={3} md={3}>
            <NavLink className="cardcategory" to={`/${item.url_title}/brand/${item.hash_id}`}>
              <div className="image-container" style={{ overflow: "hidden", margin: 0 ,padding:'2px'}}>
                <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "100%"}} />
              </div>
              <h5 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h5>
            </NavLink>
          </Col>
        ))}
        
      </Row>
    </div>

    <div className="container d-block d-lg-none d-md-none d-sm-none" style={{ cursor: "pointer",}}>
      <Row style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
      <Slider {...settings}>
        {brands.length > 0 && brands.map((item, index) => (
          <Col key={index} style={{ padding: '5px' }} xs={4} sm={4} lg={3} md={3}>
            <NavLink className="cardcategory" to={`/${item.url_title}/brand/${item.hash_id}`}>
              <div className="image-container" style={{ overflow: "hidden", margin: 0 ,padding:'2px'}}>
                <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "100%"}} />
              </div>
              <h5 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h5>
            </NavLink>
          </Col>
        ))}
          </Slider>
      </Row>
    </div>
  </section>
  <ToastContainer />
    <BottomBar/>
  </div>
     </div>
     }
      </>
    );
  }else{
    return <section>
    <Container className="mt-100 d-none d-md-block d-lg-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
<Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={12} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems1.map((_, index) => (
        <Col xs={4} sm={4} md={4} lg={4} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={120} width="auto" />      
        </Col>
        ))}
        </Row>  
       
      </Col>
    </Row>
</Container>
</section>
  }

  
}
 
