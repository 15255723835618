import React, { useEffect, useState,useRef } from "react";
import {Container, Row, Col,Button,Form  } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { GenerateOtp,UserRegistration,VerifyOtp,verify_referral_code } from '../Service/ApiCalling';
import Carousel from "react-multi-carousel";
import "../SubCss/Login.css";
import { CloseButtonModal } from "../MiniComponents/SvgIcons";
import LoginClose from "../assets/images/loginclose.png";
import men from '../assets/images/PNG/men.png';
import women from '../assets/images/PNG/women.png';

export default function Login(param){

    const [showOTP, setOTPShow] = useState(false);
    const [showRegistration, setRegShow] = useState(false);
    const [showRegistration2, setReg2Show] = useState(false);
    const [otp, setOtp] = useState(null);
    const [mobile, setmobile] = useState('');
    const [referal,setreferalcode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showTimer, setShowTimer] = useState(false);
    const [seconds, setSeconds] = useState(30); 
    const [gender,setGender] = useState('Male');

    const [otperror,setOTPError] = useState('');
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [validated, setValidated] = useState(false);
    
    const inputRef = useRef(null);
    const hasError = validated && !mobile;
  
  useEffect(() => {
    let interval = null;
    if (showTimer && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [showTimer, seconds]);
  const handleButtonClick1 = (selectedGender) => {
    setGender(selectedGender);
  };
  
 
  const GenerateOTPcall=async ()=> { 
    
    if(mobile === "" || mobile.length<10){
        // console.log("Please enter Mobile")
    }else{
      setOTPError("");
      setOtp(null);
        let GenerateOTPDatatemp = await GenerateOtp(mobile);
        if (GenerateOTPDatatemp.error === "0") { 
            setOTPShow(true); 
            setSeconds(30);
            setShowTimer(true);   
            // inputRef.current.focus(); 
        } 
    }
    setValidated(true);


    
  };
  const handleInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    const regex = /^[A-Z]*$/; // Only allows uppercase letters

    if (regex.test(input)) {
      setreferalcode(input);
    }
  };

  const handleNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

    // Validate the input
    if (value.length <= 10) {
      if (value.length === 0 || /^[6-9]/.test(value)) {
        setmobile(value);
      }
    }
  };
  const VerifyOTPcall=async (otpValue)=> { 
    const utm_source = localStorage.getItem("utm_source") ?? "N/A";
    const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
    const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
    setOtp(otpValue); 
    let  VerifyOTPDatatemp = await VerifyOtp(mobile,otpValue,utm_source,utm_medium,utm_campaign);
    if ( VerifyOTPDatatemp.error === "0") { 
         
        localStorage.setItem('is_guest', '0');
        localStorage.setItem('access_expiry_timestamp', VerifyOTPDatatemp.access_expiry_timestamp); 
        localStorage.setItem('access_token', VerifyOTPDatatemp.access_token);
        localStorage.setItem('refresh_token', VerifyOTPDatatemp.refresh_token); 
        // window.close();
        //  window.open("/");
        window.location.reload();
    } else if(VerifyOTPDatatemp.error === "2"){
        setRegShow(true); 
    }else if(VerifyOTPDatatemp.error === "1"){
        // console.log("ereorewojowi");
        setOTPError("Please Enter a valid OTP")
    }
    
    // setRegShow(true);

};


const handleSubmit = (e) => {
    e.preventDefault();
    GenerateOTPcall();
};

const [focusedGender, setFocusedGender] = useState(null);

const handleFocus = (buttonGender) => {
  setFocusedGender(buttonGender);
};

const handleBlur = () => {
  setFocusedGender(null);
};

 

  const getButtonStyles = (buttonGender) => {
    return {
      borderRadius: '50%', // Makes the button round
      border: `3px solid ${
        focusedGender === buttonGender || gender === buttonGender ? '#008EB0' : '#fff'
      }`, // Sky blue border when focused or when gender matches buttonGender
      backgroundColor: '#fff', // White background
      color: focusedGender === buttonGender || gender === buttonGender ? '#fff' : '#008EB0',
      padding: '0',
      marginRight: '20px',
      fontFamily: 'Inter',
      width: 'auto',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow:
        focusedGender === buttonGender || gender === buttonGender
          ? '0 4px 8px rgba(0, 0, 0, 0.2)'
          : 'none', // Adds shadow when focused or selected
      transition: 'border 0.3s #008EB0, box-shadow 0.3s', // Smooth transition on state change
    };
  };


const UserRegistrationCall = async () => {  
  // console.log("reg1");
  let data = await verify_referral_code(referal);
    if (data.error === "0") { 
  setReg2Show(true);  // Assuming setReg2Show is a state setter
    }
  // console.log("showRegistration2", showRegistration2);
};

const UserRegistrationCall2=async ()=> {  
  const utm_source = localStorage.getItem("utm_source") ?? "N/A";
  const utm_medium = localStorage.getItem("utm_medium") ?? "N/A";
  const utm_campaign = localStorage.getItem("utm_campaign") ?? "N/A";
    let data = await UserRegistration(name,email,mobile,otp,gender,referal,utm_source,utm_medium,utm_campaign);
    if (data.error === "0") { 
      localStorage.setItem("access_expiry_timestamp", data.access_expiry_timestamp);
localStorage.setItem("access_token", data.access_token);
localStorage.setItem("refresh_token", data.access_token);
localStorage.setItem("is_guest", "0");
        window.location.reload();
    } 
};

 const handleOTPChange = (otpValue) => {
    // console.log("otpValue0:",otpValue);
    setOtp(otpValue);
    
    if (otpValue.length === 4) {
        VerifyOTPcall(otpValue); 
    }
  };
  const getInputStyle = (index) => {
    // Check if otp is defined and is a string
    const otpValue = otp ? otp[index] : '';
    const isFilled = otpValue !== undefined && otpValue !== '';
    const isFocused = index === focusedIndex;
    return {
      textAlign: "center",
      width: "50px",
      height: "50px",
      marginLeft: "10px",
      marginRight: "10px",
      borderRadius: "42px",
      border: isFocused ? "2px solid #008EB0" : "1px solid rgb(0 0 0 / 2%)", 
      outline: "none",
      fontSize: "24px",
      fontFamily: "Inter",
      backgroundColor: isFilled ? "#008EB0" : "#F2F2F2", // Blue background if filled
      color: isFilled ? "#fff" : "#000", // White text if filled
      transition: "background-color 0.3s", // Smooth transition
      caretColor:"transparent"
    };
  };
  
const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }; 

    return(
        <> 
      <div >
        <div>
          <Row className="no-gutters" style={{backgroundColor:"#001F2E",borderRadius:"30px"}}>
          <Col lg={6} md={6} style={{padding:0}}>
                <div className='imageContainer' style={{ overflow:"hidden",borderRadius: '30px'}}>
                
                    <div>
                        <img src={require("../assets/images/login-image-new.png")} alt="Slide 1" style={{
            maxWidth: "100%",
            
          }}/>
                    </div>
                    
                
                </div>
            </Col>
            <Col lg={6} md={6} style={{ borderRadius: '30px', 
                backgroundColor:"white",
                padding: "0px"}}>
            {/* className="d-flex align-items-center justify-content-center"  */}
        <div className="text-light text-end" style={{marginTop:"25px",marginRight:"30px",cursor:"pointer"}} onClick={()=>{param.clss()}} >
            {/* <CloseButtonModal /> */}
            <img
                                                src={LoginClose} // Use the same URL for the image
                                                alt="button"
                                                style={{height:"22px",width:"23px"}}
                                                />
       </div>
            <div className='Loginform' style={{paddingTop:"15%",borderRadius: '42px 42px 42px 42px'}}>
                <Container>
                    <Row className="justify-content-center" style={{display:"contents"}}> 
                        <Col >
                            <Form className="d-flex flex-column align-items-center">
                                {showOTP ? (
                                    showRegistration2 ?(
                                        <Container> 
                                        <Form.Group className="mb-3 mt-20" controlId="formBasicPhone">
                                        <Form.Label
                                          style={{
                                              color: "#023047",
                                              fontFamily: "Inter",
                                              
                                              fontSize: "25px",
                                              fontWeight: "bold",
                                              lineHeight: "1",
                                              display: "block",
                                              textAlign: "center",
                                              marginBottom: "15px",
                                          }}
                                      >
                                          {/* Register once<br></br> to stay <span style={{color: "#9CDCFC",borderBottom:"2px solid #FFCF0D"}}>fit forever</span> */}
                                          Choose Your Avatar
                                      </Form.Label>
                        
                                      </Form.Group>

                                   <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Button
                                      className="male"
                                      style={getButtonStyles('Male')}
                                      onFocus={() => handleFocus('Male')}
                                      onBlur={handleBlur}
                                      onClick={() => handleButtonClick1('Male')}
                                    >
                                      <img src={men} alt="Male" style={{ borderRadius: '50%' }} />
                                    </Button>

                                    <Button
                                      className="female"
                                    
                                      style={getButtonStyles('Female')}
                                      onFocus={() => handleFocus('Female')}
                                      onBlur={handleBlur}
                                      onClick={() => handleButtonClick1('Female')}
                                    >
                                      <img src={women} alt="Female" style={{ borderRadius: '50%' }} />
                                    </Button>

                                    </div>                                                                            
                                          <Button variant="primary" className="mt-10" style={{ backgroundColor:"#023047", color:"white",letterSpacing:"1px",fontSize:"20px",width: "100%",borderRadius:"42px",height:"50px",border:"none",fontFamily: "Inter",}} onClick={UserRegistrationCall2}>
                                          Continue
                                          </Button>
    
                                          {/* <p className="mt-3 text-center" style={{color:"#219EBC",fontFamily:"Inter",fontSize:"17px"}}>
                                      You may receive SMS updates from Nutsby and can opt out at any time.
                                      </p> */}
                                      </Container>
                                       ):
                                   showRegistration?(
                                    <Container> 
                                      <Form.Group className="mb-3 mt-20" controlId="formBasicPhone">
                                      <Form.Label
                                        style={{
                                            color: "#023047",
                                            fontFamily: "Inter",
                                            
                                            fontSize: "36px",
                                            fontWeight: "bold",
                                            lineHeight: "1",
                                            display: "block",
                                            textAlign: "center",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {/* Register once<br></br> to stay <span style={{color: "#9CDCFC",borderBottom:"2px solid #FFCF0D"}}>fit forever</span> */}
                                        Let’s Be A Friend !
                                    </Form.Label>
                                    <Form.Label
                                        style={{
                                            color: "#494949",
                                            fontFamily: "Inter",
                                            
                                            fontSize: "24px",                                            
                                            lineHeight: "1",
                                            display: "block",
                                            textAlign: "center",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {/* Register once<br></br> to stay <span style={{color: "#9CDCFC",borderBottom:"2px solid #FFCF0D"}}>fit forever</span> */}
                                        Tell us About Yourself
                                    </Form.Label>

                                    {/* <Form.Label
                                           style={{
                                               color: "white",
                                               fontFamily: "Inter",
                                               fontSize: "16px",
                                               fontWeight: "400",
                                           }}
                                       >
                                           Name
                                       </Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                value={name}
                                                placeholder="Enter your name"
                                                
                                                onChange={(e) => setName(e.target.value)}
                                                style={{
                                                    backgroundColor: "#F4F4F4",
                                                    border: "1px solid #D9D9D9",
                                                    paddingLeft: "20px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "13px center",
                                                    backgroundSize: "auto 16px",
                                                    color: "black",
                                                    height: "47px",
                                                    borderRadius: "42px",
                                                    
                                                    fontSize: "20px",
                                                    fontFamily: "Inter",
                                                    outline: "none",
                                                    marginBottom: "10px",
                                                    '::placeholder': {
                                                      color: '#B6B5B5' 
                                                  }
                                                }}
                                                className="reg-placeholder"
                                            />

                                        {/* <Form.Label
                                           style={{
                                               color: "white",
                                               fontFamily: "Inter",
                                               fontSize: "16px",
                                               fontWeight: "400",
                                               marginTop:"10px"
                                        
                                           }}
                                       >
                                           Email Id
                                       </Form.Label> */}
                                            <Form.Control
                                             type="email"
                                                placeholder="Enter your email Id"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                style={{
                                                    backgroundColor: "#F4F4F4",
                                                    border: "1px solid #D9D9D9",
                                                    paddingLeft: "20px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "13px center",
                                                    backgroundSize: "auto 16px",
                                                    color: "black",
                                                    height: "47px",
                                                    borderRadius: "42px",
                                                     
                                                    fontSize: "20px",
                                                    fontFamily: "Inter",
                                                    outline: "none",
                                                    marginBottom: "10px", // Center the input horizontally
                                                    '::placeholder': {
                                                      color: '#B6B5B5' 
                                                  }
                                                }}
                                                className="reg-placeholder"
                                            />

                                        {/* <Form.Label
                                           style={{
                                               color: "white",
                                               fontFamily: "Inter",
                                               fontSize: "16px",
                                               fontWeight: "400",
                                               marginTop:"10px"
                                           }}
                                       >
                                           Phone No.
                                       </Form.Label> */}
                                       

                                            <Form.Control
                                                type="text"
                                                /*disabled */
                                                value={referal}
                                                placeholder="Enter Email Id Referral Code (Optional)"
                                                maxLength={6}
                                                onChange={handleInputChange}
                                                style={{
                                                    backgroundColor: "#F4F4F4",
                                                    border: "1px solid #D9D9D9",
                                                    paddingLeft: "20px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "13px center",
                                                    backgroundSize: "auto 16px",
                                                    color: "black",
                                                    height: "47px",
                                                    borderRadius: "42px",
                                                     
                                                    fontSize: "20px",
                                                    fontFamily: "Inter",
                                                    outline: "none",
                                                    marginBottom: "10px", // Center the input horizontally

                                              
                                                }}
                                                
                                                className="reg-placeholder"
                                            />

                                    </Form.Group>

                                        <Button variant="primary" className="mt-10" style={{ backgroundColor:"#023047", color:"white",letterSpacing:"1px",fontSize:"20px",width: "100%",borderRadius:"42px",height:"50px",border:"none",fontFamily: "Inter",}} onClick={UserRegistrationCall}>
                                        Continue
                                        </Button>

                                        {/* <p className="mt-3 text-center" style={{color:"#219EBC",fontFamily:"Inter",fontSize:"17px"}}>
                                    You may receive SMS updates from Nutsby and can opt out at any time.
                                    </p> */}
                                    </Container>
                                   
                                   ):

                                   (<div className="text-center my-4">
                                  <Form.Group className="mb-3" controlId="formBasicPassword">
                                           <Form.Text
                                             style={{
                                               color: "#023047",
                                               fontFamily: "'Inter', sans-serif",
                                               fontSize: "24px",
                                               lineHeight: "29.05px",
                                               fontWeight: "400",
                                               caretColor: "transparent",
                                             }}
                                           >
                                             OTP Verification
                                           </Form.Text>
                                           <Form.Label
                                             style={{
                                               color: "#494949",
                                               fontFamily: "'Inter', sans-serif",
                                               fontSize: "16px",
                                               fontWeight: "400",
                                               lineHeight: "19px",
                                               marginBottom: "25px",
                                               marginTop: "20px"
                                             }}
                                           >
                                             OTP sent to +91{mobile}
                                           </Form.Label>
                                           <div className="justify-content-center mx-3">
                                             <OtpInput
                                               value={otp}
                                               onChange={handleOTPChange}
                                               numInputs={4}
                                               separator={<span>&nbsp;</span>}
                                               isInputNum={true}
                                               containerStyle={{ display: "flex", justifyContent: "center" }}
                                               shouldAutoFocus={true}
                                               inputType="number"
                                               renderInput={(props, index) => (
                                                 <input
                                                   {...props}
                                                   style={getInputStyle(index)}
                                                   onFocus={() => setFocusedIndex(index)}
                                                   onBlur={() => setFocusedIndex(null)}
                                                 />
                                               )}
                                             />
                                           </div>
                                         </Form.Group>
                                   <div>
                                   {otperror.length>0?(<Form.Text className="forgot" style={{color: "red",fontSize: "13px", fontFamily: "Inter"}}>Please Enter a valid OTP</Form.Text>):
                                   <></>}
                                   </div>
                                   {showTimer ?(<Form.Text className="forgot" style={{color: "#494949",fontSize: "18px", fontFamily: "Inter"}}>Resend OTP in {seconds}s</Form.Text>):
                                   (<Form.Text className="forgot" style={{color: "#494949",fontSize: "18px", fontFamily: "Inter",textDecoration: "underline"}} onClick={GenerateOTPcall}>Resend OTP</Form.Text>)}
                                   
                               </div>)
                               
                                ) : (
                                   
                                    <div style={{ textAlign: "center",borderRadius: '30px',  }}>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                      <Form.Group controlId="formBasicPhone">
                                        <Form.Label style={{
                                          color: "#023047",
                                          fontFamily: "Inter",
                                          fontSize: "24px",
                                          fontWeight: "bold",
                                          marginBottom: "10px"
                                        }}>
                                          The All-In-One <br /> Nutrition Superstore
                                        </Form.Label>
                          
                                        <Form.Label style={{
                                          color: "#023047",
                                          fontFamily: "Inter",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          marginBottom: "20px"
                                        }}>
                                          Login with Mobile Number
                                        </Form.Label>
                          
                                        <div style={{ position: "relative", marginBottom: "20px" }}>
                                      
                                          <span style={{
                                            position: "absolute", 
                                            left: "15px", 
                                            top: hasError ? "32%" : "50%",
                                            transform: "translateY(-50%)", 
                                            color: "black", 
                                            fontSize: "16px"
                                          }}>+91</span>
                          
                                          <Form.Control
                                            required
                                            type="tel"
                                            value={mobile}
                                            placeholder="Enter the Mobile Number"
                                            maxLength={10}
                                            pattern={'[6-9]{1}[0-9]{9}'}
                                            title="Please provide a valid mobile number"
                                            onChange={handleNumberChange}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevent form submission if it exists
                                                GenerateOTPcall();
                                                
                                              }
                                            }}
                                            style={{
                                              paddingLeft: "45px", 
                                              backgroundColor: "#F2F2F2",
                                              border: "0px solid #D9D9D9",
                                              borderRadius: "42px",
                                              height: "45px",
                                              fontSize: "16px",
                                              fontFamily: "Inter",
                                              width: "100%",
                                              color: "#000",
                                            }}
                                          />
                                          <Form.Control.Feedback type="invalid" style={{fontFamily: "Inter"}}>
                                            Please provide a valid mobile number
                                          </Form.Control.Feedback>
                                        </div>
                          
                                        <Button onClick={GenerateOTPcall}
                                         style={{
                                          backgroundColor: "#023047", 
                                          color: "#FFFFFF",
                                          fontSize: "17px",
                                          width: "100%",
                                          borderRadius: "42px",
                                          height: "45px",
                                          border: "none",
                                          fontFamily: "Inter",
                                          marginBottom: "10px"
                                        }}>
                                          Continue
                                        </Button>
                          
                                        <p style={{
                                          color: "#7B7B7B", 
                                          fontFamily: "Inter", 
                                          fontSize: "14px", 
                                          marginTop: "20px"
                                        }}>
                                          By Continuing, you agree to our <a href="/terms-and-conditions" style={{color: "#023047", textDecoration: "underline"}}>Terms & Conditions</a>
                                        </p>
                                      </Form.Group>
                                    </Form>
                                  </div>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Col>
          </Row>

          
        </div>
      </div>
     
        
        </>

    );
}
