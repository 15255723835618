import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './MainComponents/Header';
import FooterJs from './MainComponents/FooterJs';
import "./SubCss/mediaqueries.css";
import "./SubCss/main.css";
import "./SubCss/HeaderCss.css";
import { BrowserRouter } from 'react-router-dom';


import App from './App.js';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from './MainComponents/SubComponents/MiniComponents/CartContext.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
      <HelmetProvider> 
    <CartProvider>
    
        <App />
     
    </CartProvider>
    </HelmetProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
