import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import profileImage from '../../assets/images/1.png';
import { blog_details ,blog_comment} from '../../Service/ApiCalling';
import { Row,Col,Image } from 'react-bootstrap';
import {Link } from "react-router-dom";

const ArticleDetails = () => {
    const [blog, setBlogsData] = useState([]);
    const params = useParams();
    const [article, setArticle] = useState({});
    const [comments, setComments] = useState([]);
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [authors, setAuthors] = useState([]);
  
    const [archive, setArchive] = useState([]);
    const [recent, setrecent] = useState([]);
    const [formData, setFormData] = useState({
        comm_name: '',
        comm_email: '',
        comm_message: '',
    });

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };
    const getDetails = async () => {
        try {
            let blog_detailsData = await blog_details(params.id);
            if (blog_detailsData.error === "0") {
                
                setArticle(blog_detailsData.blog);
                setComments(blog_detailsData.comments || []); 
                setTags(blog_detailsData.tags || []); 
                setAuthors(blog_detailsData.articles_author || []);
                setCategories(blog_detailsData.Categories || []);
                setArchive(blog_detailsData.articles_archive || []);
                setrecent(blog_detailsData.recent || []);
            } else {
                console.error('Error fetching blog details:', blog_detailsData.message);
            }
        } catch (error) {
            console.error('Error fetching blog details:', error);
        }
    };

    useEffect(() => {
        getDetails();
    }, [params.id]); 

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const getMonthName = (monthNumber) => {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthNumber]; // monthNumber is 0-based
      };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        
        if (!formData.comm_name || !formData.comm_email || !formData.comm_message) {
            console.error("All fields are required.");
            return; 
        }
    
        try {
         
            const response = await blog_comment(article.id, formData.comm_email, formData.comm_message, formData.comm_name);
            console.log('Comment posted:', response);
    
            
            setFormData({
                comm_name: '',
                comm_email: '',
                comm_message: '',
            });
    
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };
    



    return (
        <div>
         

            <div className="blog-details-area pt-100 pb-100">
                <div className="container" style={{paddingTop:"5rem"}}>
                    <div className="row">
                        <div className="col-lg-9 col-md-12" style={{padding:"0 30px"}}>
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img src={`${process.env.PUBLIC_URL}/${article.image}`}
 />
                                </div>
                                <div className="article-content" style={{borderRadius:"10px"}}>
                                <ul style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', listStyleType: 'none', padding: 0, margin: 0 }}>
    <li style={{ display: 'flex', gap: '10px' }}>
        <span style={{color:"#2D2D2D",fontSize:"14px"}}>By <a style={{color:"#2D2D2D",fontSize:"14px"}} href="author.html">{article.author_name}</a></span>
        <span style={{color:"#999999",fontSize:"14px"}}>({new Date(article.created_datetime).toLocaleDateString()})</span>
    </li>
    <li style={{ display: 'flex', alignItems: 'center', gap: '5px',color:"#008EB0" }}>
    <span style={{display:"flex"}}>
                             <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_1043_13568)">
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.23242 9.16341C1.23242 9.16341 4.62467 2.37891 10.5611 2.37891C16.4976 2.37891 19.8898 9.16341 19.8898 9.16341C19.8898 9.16341 16.4976 15.9479 10.5611 15.9479C4.62467 15.9479 1.23242 9.16341 1.23242 9.16341Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                               <path d="M10.5618 11.7075C11.9669 11.7075 13.106 10.5684 13.106 9.16333C13.106 7.75821 11.9669 6.61914 10.5618 6.61914C9.15665 6.61914 8.01758 7.75821 8.01758 9.16333C8.01758 10.5684 9.15665 11.7075 10.5618 11.7075Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                               </g>
                              <defs>
                               <clipPath id="clip0_1043_13568">
                               <rect width="21" height="20" fill="white" transform="translate(0.0527344 0.53125)"/>
                               </clipPath>
                               </defs>
                                </svg>
                               </span> {article.views} views
    </li>
</ul>


                                    <h4 style={{marginTop:"20px",color:"#2D2D2D"}}>{article.title}</h4>
                                    <p style={{color:"#2D2D2D",fontSize:"14px"}} dangerouslySetInnerHTML={{ __html: article.description }} />

                                    <div style={{  justifyContent: 'center', padding: '10px 0',backgroundColor:"#F5F5F5",borderRadius:"5px"}} className="article-share">
                                        
                                        <ul className="share-social " style={{display:"flex",justifyContent:"center",margin:"0.1rem "}}>
                                            <li style={{display:"flex",padding:"4px"}}><a style={{display:"flex"}} href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.3347 0C7.27749 0 0.746094 6.5314 0.746094 14.587C0.746094 22.6427 7.27749 29.1741 15.3331 29.1741C23.3888 29.1741 29.9202 22.6427 29.9202 14.587C29.9202 6.5314 23.3903 0 15.3347 0ZM21.0761 7.36463H19.3679C17.6844 7.36463 17.1604 8.40966 17.1604 9.48096V12.0224H20.9184L20.3171 15.9397H17.1604V25.4083H12.9262V15.9397H9.48657V12.0224H12.9262V9.03729C12.9262 5.64096 14.9498 3.76579 18.0446 3.76579C19.5271 3.76579 21.0777 4.03014 21.0777 4.03014V7.36463H21.0761Z" fill="url(#paint0_linear_1067_11281)"/>
<defs>
<linearGradient id="paint0_linear_1067_11281" x1="25.6489" y1="24.9028" x2="5.01894" y2="4.27285" gradientUnits="userSpaceOnUse">
<stop stop-color="#1677F1"/>
<stop offset="0.18" stop-color="#176EE9"/>
<stop offset="0.46" stop-color="#1D55D3"/>
<stop offset="0.82" stop-color="#252EB0"/>
<stop offset="1" stop-color="#2B189C"/>
</linearGradient>
</defs>
</svg>
</a></li>

<li  style={{display:"flex",padding:"4px"}}><a style={{display:"flex"}} href={`https://twitter.com/intent/tweet?text=${window.location.href}`} target="_blank" rel="noopener noreferrer"><svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.507 0C7.44977 0 0.919922 6.53139 0.919922 14.587C0.919922 22.6427 7.45132 29.1741 15.507 29.1741C23.5626 29.1741 30.094 22.6427 30.094 14.587C30.094 6.53139 23.5626 0 15.507 0ZM24.3479 18.1179C24.3479 21.0473 21.9642 23.4295 19.0362 23.4295H11.9761C9.04668 23.4295 6.66446 21.0473 6.66446 18.1179V11.0578C6.66446 8.1283 9.04668 5.74608 11.9761 5.74608H19.0362C21.9657 5.74608 24.3479 8.1283 24.3479 11.0578V18.1179ZM18.3684 14.587C18.3684 16.1252 17.1178 17.3774 15.5796 17.3774C14.0415 17.3774 12.7893 16.1267 12.7893 14.587C12.7893 13.0473 14.0415 11.7967 15.5796 11.7967C17.1178 11.7967 18.3684 13.0473 18.3684 14.587ZM19.0362 7.40792H11.9761C9.96494 7.40792 8.32784 9.04502 8.32784 11.0562V18.1163C8.32784 20.1275 9.96339 21.7646 11.9761 21.7646H19.0362C21.0474 21.7646 22.6845 20.1275 22.6845 18.1163V11.0562C22.6845 9.04502 21.0474 7.40792 19.0362 7.40792ZM15.5796 18.9418C13.1789 18.9418 11.2248 16.9878 11.2248 14.587C11.2248 12.1863 13.1789 10.2323 15.5796 10.2323C17.9804 10.2323 19.9344 12.1863 19.9344 14.587C19.9344 16.9878 17.9804 18.9418 15.5796 18.9418ZM20.1879 10.9758C19.6159 10.9758 19.1506 10.5121 19.1506 9.93854C19.1506 9.36501 19.6144 8.90125 20.1879 8.90125C20.7615 8.90125 21.2252 9.36501 21.2252 9.93854C21.2252 10.5121 20.7615 10.9758 20.1879 10.9758Z" fill="url(#paint0_linear_1067_11282)"/>
<defs>
<linearGradient id="paint0_linear_1067_11282" x1="5.19122" y1="4.2713" x2="25.8212" y2="24.9028" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE185"/>
<stop offset="0.21" stop-color="#FFBB36"/>
<stop offset="0.38" stop-color="#FF5176"/>
<stop offset="0.52" stop-color="#F63395"/>
<stop offset="0.74" stop-color="#A436D2"/>
<stop offset="1" stop-color="#5F4EED"/>
</linearGradient>
</defs>
</svg></a></li>


                                            <li  style={{display:"flex",padding:"4px"}}><a style={{display:"flex"}} href={`https://twitter.com/intent/tweet?text=${window.location.href}`} target="_blank" rel="noopener noreferrer"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6808 0C6.6236 0 0.09375 6.53139 0.09375 14.587C0.09375 22.6427 6.62515 29.1741 14.6808 29.1741C22.7364 29.1741 29.2678 22.6427 29.2678 14.587C29.2678 6.53139 22.7364 0 14.6808 0Z" fill="url(#paint0_linear_1067_11283)"/>
<path d="M16.142 13.3852L21.8618 6.73633H20.5061L15.5391 12.5087L11.5723 6.73633H6.99805L12.9961 15.466L6.99805 22.438H8.35379L13.599 16.3425L17.7884 22.438H22.3627L16.142 13.3852ZM14.2854 15.5433L13.6779 14.6745L8.8423 7.75816H10.9246L14.8264 13.3404L15.434 14.2092L20.5061 21.4656H18.4237L14.2838 15.5448L14.2854 15.5433Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_1067_11283" x1="4.36505" y1="4.27285" x2="24.995" y2="24.9028" gradientUnits="userSpaceOnUse">
<stop stop-color="#23004A"/>
<stop offset="0.96" stop-color="#010003"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>
</a></li>
<li  style={{display:"flex",padding:"4px"}}><a style={{display:"flex"}} href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noopener noreferrer"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.8546 0C6.79743 0 0.267578 6.53139 0.267578 14.587C0.267578 22.6427 6.79897 29.1741 14.8546 29.1741C22.9103 29.1741 29.4417 22.6427 29.4417 14.587C29.4417 6.53139 22.9103 0 14.8546 0ZM15.1561 22.7061C13.7215 22.7061 12.3054 22.3443 11.0378 21.6564L6.32129 22.9194L7.57965 18.2276C6.86699 16.943 6.49289 15.5038 6.49289 14.0429C6.49289 9.26608 10.3793 5.3797 15.1561 5.3797C19.9329 5.3797 23.8193 9.26608 23.8193 14.0429C23.8193 18.8197 19.9329 22.7061 15.1561 22.7061ZM15.1561 7.01681C11.2821 7.01681 8.12999 10.1689 8.12999 14.0429C8.12999 15.3229 8.48554 16.5812 9.15801 17.685L9.34042 17.9834L8.63859 20.6037L11.2728 19.8972L11.5696 20.075C12.6595 20.7242 13.9008 21.069 15.1561 21.069C19.0301 21.069 22.1822 17.9169 22.1822 14.0429C22.1822 10.1689 19.0301 7.01681 15.1561 7.01681ZM19.2836 16.6956C19.0223 17.331 18.3731 17.7376 17.7145 17.8628C17.0853 17.9818 16.5659 17.7824 15.9831 17.5892C15.4637 17.4176 14.9613 17.1671 14.496 16.8827C13.5638 16.3123 12.7584 15.5486 12.1122 14.669C11.9143 14.4 11.7319 14.1202 11.5619 13.8326C11.3377 13.4508 11.1398 13.0489 11.0347 12.6191C10.9914 12.4398 10.9682 12.2558 10.9667 12.0703C10.959 11.418 11.2079 10.761 11.7226 10.3405C12.021 10.0978 12.4631 9.9911 12.7986 10.1812C13.1634 10.3869 13.352 10.9063 13.5205 11.2711C13.6535 11.5556 13.8034 11.9297 13.7478 12.2497C13.6937 12.5697 13.4401 12.8386 13.2376 13.0767C13.1016 13.2375 13.0815 13.3735 13.1943 13.5467C13.8622 14.6597 14.7881 15.4667 15.9986 15.949C16.1624 16.0155 16.2908 15.9954 16.399 15.8624C16.6077 15.6042 16.8133 15.1003 17.1364 14.9689C17.5568 14.7973 18.0206 15.0354 18.3777 15.2487C18.7101 15.4466 19.2651 15.6908 19.3547 16.102C19.398 16.2999 19.3594 16.5101 19.2821 16.6972L19.2836 16.6956Z" fill="url(#paint0_linear_1067_11286)"/>
<defs>
<linearGradient id="paint0_linear_1067_11286" x1="25.1688" y1="24.9028" x2="4.53888" y2="4.27284" gradientUnits="userSpaceOnUse">
<stop stop-color="#00FB73"/>
<stop offset="1" stop-color="#00B25B"/>
</linearGradient>
</defs>
</svg>
</a></li>
                                            <li  style={{display:"flex",padding:"4px"}}><a style={{display:"flex"}} href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noopener noreferrer"><svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1067_11289)">
<path d="M20.8967 29.0378C28.5566 25.8837 32.2093 17.1173 29.0553 9.45739C25.9012 1.79751 17.1348 -1.8552 9.47492 1.29885C1.81503 4.4529 -1.83767 13.2193 1.31638 20.8792C4.47043 28.5391 13.2369 32.1918 20.8967 29.0378Z" fill="url(#paint0_linear_1067_11289)"/>
<path d="M7.64032 11.6478H10.8783V22.05H7.64032V11.6478ZM9.2601 6.47852C10.2949 6.47852 11.1342 7.31781 11.1342 8.35103C11.1342 9.38425 10.2949 10.2267 9.2601 10.2267C8.22529 10.2267 7.38281 9.38584 7.38281 8.35103C7.38281 7.31622 8.22211 6.47852 9.2601 6.47852Z" fill="white"/>
<path d="M12.9082 11.6481H16.0095V13.0708H16.0524C16.4847 12.2522 17.5402 11.3906 19.1139 11.3906C22.3868 11.3906 22.9909 13.5429 22.9909 16.3453V22.0503H19.7593V16.9939C19.7593 15.7874 19.7354 14.236 18.0791 14.236C16.4227 14.236 16.1398 15.5505 16.1398 16.9064V22.0519H12.9082V11.6497V11.6481Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_1067_11289" x1="25.7938" y1="25.7752" x2="4.581" y2="4.56236" gradientUnits="userSpaceOnUse">
<stop stop-color="#007BB8"/>
<stop offset="1" stop-color="#0044E9"/>
</linearGradient>
<clipPath id="clip0_1067_11289">
<rect width="30" height="30" fill="white" transform="translate(0.1875 0.167969)"/>
</clipPath>
</defs>
</svg>
</a></li>

                                        </ul>
                                        <ul style={{marginBottom:"0px"}}> <h6 style={{display:"flex",justifyContent:"center",marginBottom:"0px"}}>share the article</h6></ul>
                                    </div>

                                   

<div className="comments-area" style={{ borderTop: 'none', borderBottom: '1px solid #ececec', marginTop: '0' }}>
  <h5 style={{ marginTop: '30px',fontSize:"16px",fontWeight:"700" }}>
    Comments ({comments.length > 0 ? comments[0].articles_count : '0'})
  </h5>
  <hr className="dashed-hr" style={{ border: '0.5px dashed' }} />

  <div className="comments-list">
    {comments.length > 0 && comments[0].articles_count !== 0 ? (
      comments.map((comment) => (
        <div key={comment.id} className="single-comment justify-content-between d-flex mb-10">
          <div className="col-2 text-center">
  
  <Image src={profileImage} style={{ objectFit: "cover", borderRadius: '100px' }} alt="Profile"
                   id="image_profile"   roundedCircle/>
          </div>
          <div className="user justify-content-between d-flex col-10">
            <div className="desc">
              <div className="d-flex justify-content-between mb-10">
                <div className="d-flex align-items-center">
                  <a style={{color:"black"}} className="font-heading text-brand">
                    {comment.name}
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <span className="font-xs text-muted">{formatDate(comment.created_datetime)}</span>
                </div>
              </div>
              <p className="mb-10">{comment.comment}</p>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p>No comments available.</p>
    )}
  </div>
</div>
                                    <h5 style={{marginTop:'30px',fontSize:"16px",fontWeight:"700",marginBottom:"15px"}}>Leave a reply</h5>
                                    <form onSubmit={handleSubmit}>
    <div className="form-container mb-30" >
        <input type="hidden" name="article_id" value={article.id} />

        <div className="form-group">
            <Row>
                <Col xs={6} style={{ paddingRight: "0px" }}> 
                    <input 
                        type="text" 
                        name="comm_name" 
                        className='form-control' 
                        placeholder="Enter name" 
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                        value={formData.comm_name} 
                        onChange={handleChange} 
                        required 
                    />
                </Col>
                <Col xs={6}>
                    <input 
                        type="email" 
                        name="comm_email" 
                        className='form-control' 
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                        placeholder="Email address" 
                        value={formData.comm_email} 
                        onChange={handleChange} 
                        required 
                    />
                </Col>
            </Row>
        </div>

        <textarea 
            name="comm_message" 
            placeholder="Your message" 
            className='form-control '
            style={{ backgroundColor: "rgb(245, 245, 245)" }}
            value={formData.comm_message} 
            onChange={handleChange} 
            required
        ></textarea>
        
        <button className="btn" style={{
            backgroundColor: "#023047",
            color: "white",
            borderRadius: "5px",
            marginRight: "14px",
            marginTop:"10px",
            fontFamily: "Inter",
            margin: "10px 0px"
        }} type="submit">Submit</button>
    </div>
</form>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 primary-sidebar sticky-sidebar">
                            <div className="theiaStickySidebar">
                                <div className="widget-area">
                                    {/* <div className="sidebar-widget-2 widget_search mb-50">
                                        <div className="search-form">
                                            <form action="/blog" method="GET">
                                                <input type="text" name="title" placeholder="Search Blogs…" />
                                                <button type="submit"><i className="fi-rs-search"></i></button>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Recent Posts</h5>
                                        <ul>
                                            {recent.length > 0 ? (
                                                recent.map((re) => (
                                                    <li key={re.id} style={{paddingBottom:"10px"}}>
                                                         • <a  style={{color:"#242424",fontWeight:"400",fontStyle:"Montserrat",fontSize:"15px"}} href={`/blog_details/${re.id}/${re.url_title}`}>{re.title}</a>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No recent posts.</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Categories</h5>
                                        <ul>
                    {categories.map((cat) => (
                        <li key={cat.id}  style={{paddingBottom:"10px"}} >
                            <Link to ={`/blog-list?category_id=${cat.id}`} style={{color:"#242424",fontSize:"15px",fontWeight:"600"}}>{cat.name}</Link>
                            <span style={{color:"#9B9999"}} className="count"> ({cat.articles_count})</span>
                        </li>
                    ))}
                </ul>
                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Archives</h5>
                                        <ul>
    {archive.length > 0 ? (
        archive.map((b) => {
            const createdDate = new Date(b.created_datetime); 
            const year = createdDate.getFullYear(); 
            const month = getMonthName(createdDate.getMonth()); 
            return (
                <li key={b.created_datetime}  style={{color:"#9B9999",fontSize:"15px",fontWeight:"600",paddingBottom:"10px"}} >
                   <Link to ={`/blog-list?month=${month}&year=${year}`} style={{ color: "#242424" }}>
                        {createdDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </Link>
                    <span style={{ color: "#9B9999" }} className="count"> ({b.articles_count})</span>
                </li>
            );
        })
    ) : (
        <li>No archives available.</li>
    )}
</ul>

                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Authors</h5>
                                        <ul>
                                            {authors.length > 0 ? (
                                                authors.map((e) => (
                                                    <li key={e.user_id}  style={{color:"#9B9999",fontSize:"15px",fontWeight:"600",paddingBottom:"10px"}}  >
                                                       <Link to ={`?author_id=${e.user_id}`} style={{color:"#242424"}} >
                                                            {e.name}
                                                        </Link>
                                                        <span style={{color:"#9B9999"}} className="count"> ({e.articles_count})</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No authors available.</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget widget-tags mb-50 pb-10">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Popular Tags</h5>
                                        <ul className="tags-list" style={{ display: 'flex', flexWrap: 'wrap', padding: '0' }}>
    {tags.length > 0 ? (
        tags.map((tag) => (
            <li key={tag.id} className="hover-up" style={{ 
                background: "#F3F3F3", 
                borderRadius: "6px", 
                margin: "5px", 
                padding: "5px", 
                display: 'flex', 
                alignItems: 'center', 
                fontSize:"15px",
                fontWeight:"600"
            }} >
                <span style={{ marginRight: '5px' }}> 
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4401 6.87203L14.1259 3.3285C14.1016 3.05976 14.0136 2.80068 13.8693 2.57268C13.725 2.34467 13.5285 2.1543 13.296 2.01729L12.8228 1.73901L11.3371 4.26545C11.5946 4.57164 11.7356 4.95902 11.7352 5.35913C11.7348 5.75924 11.5929 6.14631 11.3347 6.45194C11.0765 6.75757 10.7185 6.96208 10.3241 7.02931C9.92968 7.09655 9.52419 7.02218 9.1793 6.81936C8.8344 6.61654 8.57231 6.29833 8.43935 5.92096C8.30638 5.54359 8.31111 5.13136 8.45269 4.75713C8.59426 4.38291 8.86358 4.07078 9.21303 3.87592C9.56248 3.68106 9.96957 3.616 10.3623 3.69226L11.8481 1.16581L11.3748 0.88753C11.1421 0.750911 10.8801 0.671717 10.6106 0.656507C10.3411 0.641297 10.0719 0.690509 9.82524 0.800067L6.5761 2.24753C6.03714 2.48605 5.58687 2.88807 5.28904 3.39667L0.784331 11.0569C0.556301 11.4447 0.49165 11.9071 0.604598 12.3426C0.717547 12.778 0.998843 13.1507 1.38661 13.3788L7.23486 16.8179C7.62262 17.0459 8.08509 17.1106 8.52052 16.9976C8.95595 16.8847 9.32868 16.6034 9.55671 16.2156L14.0614 8.55539C14.3611 8.0479 14.4936 7.45898 14.4401 6.87203Z" fill="#9A9A9A"/>
                    </svg>
                </span>

                <span>
                    <Link to={`/blog-list?tag_id=${tag.id}`} style={{
                        color: "#242424",
                        fontWeight: "600",
                        marginLeft: "5px"
                    }}>
                        {tag.name}
                    </Link>
                </span> 
            </li>
        ))
    ) : (
        <li>No tags available.</li>
    )}
</ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleDetails;