import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ScratchCard from 'react-scratchcard-v2';
import { useLocation } from 'react-router-dom';
import "../../SubCss/scratchcard.css";
import { scratch_card_list, scratch_card_details, redeem_scratch_card } from "../../Service/ApiCalling";
import image1 from '../../assets/images/scratchcard_orange.png';
import image2 from '../../assets/images/scrachcard_yellow.png';
import image3 from '../../assets/images/scratchcard_red.png';
import image4 from '../../assets/images/scratchcard_blue.png';
import Lottie from "lottie-react";
import celebrations from "../../assets/lottie/celebrations.json";
import { useNavigate } from 'react-router-dom';





function Scratchcard() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const images = [image1, image2, image3, image4];
  const scratchBannerImage = images[Math.floor(Math.random() * images.length)];
  const [scratchcardslist, setscrachcardlist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
const [totalfitcash , settotalfitcash] = useState(null);
const [hasWon, setHasWon] = useState(false);
  const getInfo = async () => {
    let scratch_card_listData = await scratch_card_list();
    if (scratch_card_listData.error === "0") {
      setscrachcardlist(scratch_card_listData.scratch_cards);
      settotalfitcash(scratch_card_listData.total_redemmed_amount);
    }
  };
  useEffect(() => {
 
    const updatedCards = scratchcardslist.map(card => {
      // Randomly select an image for each card and store it as a property of the card
      const randomImage = images[Math.floor(Math.random() * images.length)];
      return { ...card, randomImage };
    });
    setscrachcardlist(updatedCards);
  }, []); // Empty dependency array ensures this runs only once on initial load

  const fetchCardDetails = async (cardId) => {
    const details = await scratch_card_details(cardId);
    setCardDetails(details); 
  };

  const redeemCard = async (cardId) => {
    const res = await redeem_scratch_card(cardId); 
    if (res.error === "0") {
        console.log('Card redeemed successfully!');
        
        
        
        setTimeout(() => {
          setShowModal(false); 
            const encodedCardId = btoa(cardId);
            navigate(`/scratchcardsdetails/${encodedCardId}`);
        }, 5000);  
        
        
    } else {
        console.log('Error redeeming card!');
    }
};

  const handleCardClickredirect = (id) => {
    
    const encodedCardId = btoa(id); 
    navigate(`/scratchcardsdetails/${encodedCardId}`);
  };

  useEffect(() => {
    if (pathname === "/scratchcards") {
      document.body.style.backgroundColor = "#F4F5FA";
    } else {
      document.body.style.backgroundColor = "white";
    }
    getInfo();
  }, [pathname]);

  const handleCardClick = (card) => {
    setSelectedCard(card); 
    fetchCardDetails(card.id); 
    setShowModal(true); 
  };

  const settings = {
    width: 150,
    height: 150,
    image: scratchBannerImage,
    finishPercent: 50,
    onComplete: () => {
        console.log('Congratulations! You have scratched enough!');
        setHasWon(true);
        
      redeemCard(selectedCard.id); 
      console.log(selectedCard.id,'this is id')
    },
  };

  return (
    <>

      <div className="mainCarousel d-none d-md-block d-lg-block container" style={{ paddingBottom: "0px", paddingTop: "10rem", borderRadius: '30px', position: 'relative' }}>
      <Row>
  <Col lg={12} md={12} sm={12}>
    <div style={{ position: 'relative' }}>
      <img 
        src={require("../../assets/images/fitcashreward.png")} 
        alt="" 
        style={{ borderRadius: '20px', webkitUserDrag: "none", width: '100%' }} 
      />
      
      <div 
        style={{ 
          position: 'absolute', 
          left: '20px', 
          bottom: '20px', 
          color: '#FFFFFF', 
          fontSize: '16px', 
          fontWeight: '600',
          padding: '5px 10px', 
          borderRadius: '5px',
          display: 'flex', 
          flexDirection: 'column',  // Stack content vertically
          alignItems: 'flex-start'
        }}
      >
        <div style={{ fontSize: '28px', marginBottom: '5px' }}>
          ₹ {Math.round(totalfitcash)}
        </div>
        <div style={{ fontSize: '14px' }}>
          Fitcash Earned
        </div>
      </div>
    </div>
  </Col>
</Row>

</div>
<div className="mainCarousel d-block d-md-none d-lg-none container" style={{ paddingBottom: "0px", paddingTop: "10rem", borderRadius: '30px', position: 'relative' }}>
      <Row>
  <Col lg={12} md={12} sm={12}>
    <div style={{ position: 'relative',display:"flex",justifyContent:"center", }}>
      <img 
        src={require("../../assets/images/mobilescratchcard1.png")} 
        alt="" 
        style={{ borderRadius: '20px', webkitUserDrag: "none", width: '100%' }} 
      />
      
      <div 
        style={{ 
          position: 'absolute', 
          left: '20px', 
          bottom: '20px', 
          color: '#FFFFFF', 
          fontSize: '16px', 
          fontWeight: '600',
          padding: '5px 10px', 
          borderRadius: '5px',
          display: 'flex', 
          flexDirection: 'column',  // Stack content vertically
          alignItems: 'flex-start'
        }}
      >
        <div style={{ fontSize: '28px', marginBottom: '5px' }}>
          ₹ {Math.round(totalfitcash)}
        </div>
        <div style={{ fontSize: '14px' }}>
          Fitcash Earned
        </div>
      </div>
    </div>
  </Col>
</Row>

</div>

   
<div className="container" style={{marginBottom:"30px",padding:"0px"}}>
  <Row className="container" style={{margin:"0px"}}>
    {scratchcardslist.map((card) => {
      // Move the random image selection inside the map function
      const images = [image1, image2, image3, image4];
      const scratchBannerImage = images[Math.floor(Math.random() * images.length)];

      return (
        <Col lg={3} md={4} sm={6} xs={6} key={card.id} style={{ padding: '10px', display: "flex", justifyContent: "center" }}>
          <div style={{
            borderRadius: '10px',
            cursor: 'pointer',
            display: "flex",
            justifyContent: "center",
            width: '150px',
            height: '150px',
          }}>
            {card.is_redeemed === "1" ? (
              <div
              onClick={() => handleCardClickredirect(card.id)} 
                style={{
                  background: '#9D9D9D',
                  borderRadius: '19px',
                  width: '150px',
                  height: '150px',
                  padding: '10px',
                  position: 'relative',
                }}
              >
                <div style={{ padding: "20px 10px" }}>
                  <h6 style={{ color: "#414141", fontWeight: "600", fontSize: "28px", marginBottom: "3px" }}>
                    ₹{Math.round(card.fitcash_amount)}
                  </h6>
                  <div style={{ color: "#5B5B5B", fontWeight: "600", fontSize: "12px" }}>Fitcash Received</div>
                </div>
                <div style={{ flex: 1, textAlign: '' }}>
                  <img
                    src={require("../../assets/images/Walletgray.png")}
                    alt=""
                    style={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '19%',
                      objectFit: 'cover',
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                    }}
                  />
                  <div
                    style={{
                      color: "#5B5B5B",
                      fontSize: "11px",
                      fontWeight: "400",
                      position: "absolute",
                      right: "5px",
                      bottom: "20px",
                    }}
                  >
                    Nutsby Fitcash
                  </div>
                </div>
              </div>
            ) : (
              <div onClick={() => handleCardClick(card)} style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={scratchBannerImage}
                  alt="Scratch Card"
                  style={{ width: '150px', height: '150px', borderRadius: '10px' }}
                />
              </div>
            )}
          </div>
        </Col>
      );
    })}
  </Row>
</div>



     
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          backgroundColor: 'transparent',
          border: 'none',
        }}
      >
        {hasWon && (
          <>
          <Lottie 
    animationData={celebrations} 
    style={{
        height: "100vh",  
        width: "100%",    
        position: 'fixed', 
        top: '0',         
        left: '0',       
        zIndex: '1'    
    }} 
/>
          <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          marginBottom: "15px",
          fontSize: "24px"}} >Congratulations</div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          marginBottom: "15px",
          fontSize: "18px"
        }}>
          You Won
        </div>
        </>
      )}
        <Modal.Body
          style={{
            backgroundColor: 'transparent', 
            color: 'white',
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none', 
          }}
        >
         
          {selectedCard && cardDetails && (
            <ScratchCard {...settings}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: 'white',
                borderRadius: '19px',
                width: '140px', 
                height: '150px', 
                padding: '10px',
              }}
            >
       
             
          
              {/* Text content */}
              <div style={{ flex: 1, textAlign: 'center' ,borderRadius: '19px',}}>
                <h6 style={{ color: "#000000", fontWeight: "600", fontSize: "48px" }}>
                  ₹{Math.round(selectedCard.fitcash_amount)}
                </h6>
                <div>
                <div>
                <img
                src={require("../../assets/Wallet.png")} 
                alt=""
                style={{
                  width: '70px', 
                  height: '70px',
                  marginleft: '0px', 
                  position:"absolute",
                  left:"0",
                  bottom:"0",
                  borderRadius: '19px', 
                  objectFit: 'cover', 
                }}
              />
              
              </div>
              <div  style={{color:"#9B9B9B",fontSize:"10px",fontWeight:"400",position:"absolute",
                  right:"13px",
                  bottom:"20px",}}> Nutsby Fitcash</div>
              </div>
              </div>
            </div>
          </ScratchCard>
          
          )}
        </Modal.Body>
        {!hasWon && (
          <>
          
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          marginTop: "8px",
          fontSize: "16px"
        }}>
          Scratch to get Reward
        </div>
        </>
      )}
      </Modal>
    </>
  );
}

export default Scratchcard;
