import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar, Button, Form, Modal,Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { AverageRatingListApi, SubmitProductReview } from '../Service/ApiCalling';
import '../SubCss/customerratings.css'
const CustomerRatings = (props) => {
    const [show, setShowWriteReviewModal] = useState(false);
    const handleClose = () => setShowWriteReviewModal(false);
    const handleShow = () => setShowWriteReviewModal(true);

    const [avgReviewRating, setAvgReviewRatings] = useState({});
    const [rate, setRate] = useState(0);
    const [file, setFile] = useState([]);
    const [headline, setHeadline] = useState('');
    const [review, setReview] = useState('');
    const [totalReview, setTotalReviews] = useState(null);

    const AverageRatingListFunction = async () => {  
        let data = await AverageRatingListApi(props.id);    
        if (data.error === "0") {
            setAvgReviewRatings(data.avg_review_ratings);
        }
    };

    const AddProductRatingApi = async () => {
        let data = await SubmitProductReview(props.id, rate, review, headline, file);
        if (data.error === "0") {
            // console.log("Review submitted");
            setRate(0);
            setHeadline('');
            setReview('');
            setFile([]);
            handleClose();
            
            // Optionally refresh the average ratings
            AverageRatingListFunction();
        } else {
            // console.log("Error submitting review");
        }
    }

    const handleFileChange = (e) => {
        // console.log(e.target.files);
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
    }

    const handleSubmitReview = (e) => {
        e.preventDefault();
        AddProductRatingApi();
    }

    useEffect(() => {
        setTotalReviews(localStorage.getItem("AvgReviews"));
        AverageRatingListFunction();
    }, []);

    return (
        <>
            <div id="rating_id">
                {avgReviewRating.avg_rate !== 0.0 && (
                    <Row className="">
                         <Row className="mt-20">
  <Col lg={12} md={12} sm={12} xs={12} style={{padding:"0 0px 0 30px"}}>
 
                <div className="d-flex align-items-center mt-0">
                    <div style={{fontWeight: "600",fontsize:"16px" }}>Review this product </div>
                </div>
                <Row className="mt-10 align-items-center">
  
    <Col lg={8} md={8} sm={8} xs={8}>
        <span style={{ color: "#999999", fontWeight: "600",fontSize:"14px" }}>
            What’s your experience with this product? <br /> Share your thoughts!
        </span>
    </Col>
    <Col lg={4} md={4} sm={4} xs={4} className="text-end">
    <Button 
      className="mt-0" 
      onClick={handleShow} 
      
      style={{ 
        fontFamily: "Inter", 
        border: "0.5px solid #CFCFCF",
        padding:"12px 0px",
        backgroundColor: "white", 
        color: "#2D2D2D", 
        borderRadius: "15px",
        width: "100%",
        fontSize:"14px"  
      }}
    >
     Leave a Review
    </Button></Col>
    </Row>
    <Row className="mt-20 d-none d-md-block d-lg-block" style={{marginTop:"20px",color:"#E9E9E9"}}>
                                <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#E9E9E9"}}></hr>    
                            </Row> 
  </Col>
 
</Row>

                        <div id="customer_avg_ratings">
                           
                            
                            <Col lg={12} md={12} sm={12} xs={12} className=" align-content-center justify-content-center align-items-center container
                            ">
                            <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: "center",background:"#FFF9E2",borderRadius:"15px",marginBottom:"20px" }}>
    {Number(avgReviewRating.avg_rate) > 0 ? (
        <Col className="" style={{ display: "inline-block", marginLeft: "2px"  }}>
            <div className="rating-container" style={{ paddingLeft: "14px",textAlign: "center",fontSize:'x-large',padding:"20px"}}>
                <FontAwesomeIcon icon={faStar} style={{ color: 'gold', }} />
                <span className="prod-rating1" style={{marginTop:"3px",fontWeight:"700",marginRight:"20px"}}> {avgReviewRating.avg_rate}</span>
                <span className="" style={{fontSize:"14px",color:'#999999'}} >( {Number(totalReview)} {(Number(totalReview) > 1) ? "Reviews" : "Review"})</span>
            </div>

        
        </Col>
    ) : (
        <></>
    )}
</Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                {[5, 4, 3, 2, 1].map((star) => (
                                    <Row className="mb-10" key={star}>
                                        <Col lg={2} md={2} sm={2} xs={2}>
                                            <span style={{ fontFamily: "Inter",paddingRight:"2px" }}>{star}</span><span><FontAwesomeIcon icon={faStar} style={{ color: 'gold',paddingLeft:"3px" }} /></span>
                                        </Col>
                                        <Col lg={8} md={8} sm={8} xs={8}>
                                            <ProgressBar variant="info" now={avgReviewRating[`r${star}`]} className="mb-10" style={{ marginLeft: "-20px", alignSelf: "center", marginTop: "3px" }} />
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2}>
                                         <span style={{ marginLeft: "-10px", fontFamily: "Inter" }}>
                                           {Math.round(avgReviewRating[`r${star}`])}%
                                             </span>
                                          </Col>

                                    </Row>
                                ))}
                                </Col>
                            </Col>
                          
                        </div>
                    </Row>
                )}
             

            </div>

            <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: "30rem" }}>
    <Offcanvas.Header closeButton>
        <Offcanvas.Title>Review The Product</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
        <Container className="d-flex align-content-center" style={{ backgroundColor: "white" }}>
            <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '20px' }}>
            <div className="d-flex align-items-center mt-0">
                    <div style={{fontWeight: "600",fontsize:"16px" }}>Review this product </div>
                </div>
               
   
        <span style={{ color: "#999999", fontWeight: "600",fontSize:"14px" }}>
            What’s your experience with this product? <br /> Share your thoughts!
        </span>

                <Row className="mt-40">
                    <Col lg={3} md={3} sm={3} xs={3} >
                        <img style={{background:"#F5F5F5",borderRadius:"10px"}} src={props.image} alt={props.name} fluid />
                    </Col>
                    <Col lg={9} md={9} sm={9} xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 className="text-justify" style={{ fontWeight: "400" }}>{props.name}</h5>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <h5>Rate this Product</h5>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <FontAwesomeIcon
                                key={star}
                                icon={faStar}
                                onClick={() => setRate(star)}
                                style={{ width: "35px", height: "35px", color: rate >= star ? '#219ebc' : 'lightgray' }}
                            />
                        ))}
                    </Col>
                </Row>
                <Form onSubmit={handleSubmitReview}>
                    <Row style={{ marginTop: "20px" }}>
                        <h5>Add a Headline</h5>
                        <Form.Group className="mb-3">
                            <Form.Control
                                placeholder="Write a headline"
                                style={{ border: '1px solid black' }}
                                className="headline"
                                value={headline}
                                onChange={(e) => setHeadline(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Container style={{ marginTop: "20px" }}>
                        <Row>
                            <Col lg={2} md={2} sm={2} xs={4}>
                                <div className="dotted-border-box" style={{ width: "100px", height: "100px" }}>
                                    <div className="centered-content">+</div>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ opacity: 0, position: "relative", zIndex: 1, width: "100px", height: "100px" }}
                                    />
                                </div>
                            </Col>
                            {file.map((element, index) => (
                                <Col lg={2} md={2} sm={2} xs={4} key={index}>
                                    <img src={element} alt="Uploaded file" className="dotted-border-box" style={{ padding: 0, width: "100px", height: "100px" }} />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <h5 style={{ marginTop: '15px' }}>Add a photo or video</h5>
                    <h6 style={{ fontWeight: '400', color: "#5e6164" }}>Images and videos are more helpful than text</h6>
                    <Row style={{ marginTop: "20px" }}>
                        <h5> Write a review</h5>
                        <Form.Group className="">
                            <Form.Control
                                as="textarea"
                                rows={10}
                                placeholder="Write something about the product"
                                style={{ border: '1px solid black', background: 'white' }}
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                        <Button variant="primary" type="submit" style={{ borderColor: "orange", backgroundColor: "orange", color: "black", width: "100px" }}>
                            Submit
                        </Button>
                    </Row>
                </Form>
            </Col>
        </Container>
    </Offcanvas.Body>
</Offcanvas>

        </>
    );
}

export default CustomerRatings;