import React, { useEffect, useRef, useState } from "react";

function CircleCountdown({ timeout, onComplete }) {
  const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState(timeout);
  const [d, setD] = useState("");
  const timer = useRef(null);
  const startTime = useRef(Date.now());
  const radius = 80;

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    const x = centerX + radius * Math.cos(angleInRadians);
    const y = centerY + radius * Math.sin(angleInRadians);
    return { x, y };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
    return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${arcSweep} 0 ${end.x} ${end.y} L ${x} ${y} L ${start.x} ${start.y}`;
  };

  useEffect(() => {
    // Calculate time remaining based on the start time
    const updateCountdown = () => {
      const elapsed = (Date.now() - startTime.current) / 1000;
      const remaining = Math.max(0, timeout - Math.floor(elapsed));
      setTimeRemainingInSeconds(remaining);

      // Update the arc path
      setD(describeArc(radius, radius, radius, 0, (remaining / timeout) * 360));

      if (remaining <= 0) {
        clearInterval(timer.current);
        if (onComplete) onComplete();
        startTime.current = Date.now(); // Reset start time for next countdown
      }
    };

    updateCountdown(); // Initial call to set the countdown

    timer.current = setInterval(updateCountdown, 1000); // Update countdown every second

    return () => clearInterval(timer.current); // Cleanup interval on component unmount
  }, [timeout, onComplete]);

  return (
    <svg className="countdowncircle" viewBox={`0 0 ${2 * radius} ${2 * radius}`} style={{ height: "30px", width: "30px", padding:"3px" }}>
      {timeRemainingInSeconds === timeout ? (
        <circle fill="skyblue" cx={radius} cy={radius} r={radius - 1} />
      ) : (
        <path fill="skyblue" d={d} />
      )}
      <circle fill="none" stroke="grey" cx={radius} cy={radius} r={radius - 1} />
      {/* Uncomment and adjust the text if needed */}
      {/* <text x={radius} y={radius} textAnchor="middle" fontWeight="bold" fontSize={radius / 3}>
        {Math.ceil(timeRemainingInSeconds)}
      </text> */}
      {/* <text x={radius} y={radius + 13} textAnchor="middle" fontSize={radius / 8}>
        Seconds
      </text> */}
    </svg>
  );
}

export default CircleCountdown;
