import React, { useEffect, useState }  from 'react';
import { Container, Row, Col, Button, Breadcrumb, Form } from 'react-bootstrap';
import { blog_list } from '../../Service/ApiCalling';
import {useLocation,useNavigate,Link } from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Blog = () => {
    const [blog, setBlogsData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [tags, setTags] = useState([]);
    const [archive, setArchive] = useState([]);
    const [recent, setRecent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const query = useQuery(); 

    const [category_hash_id, setCategoryID] = useState(query.get('category') || "");
    const [year, setYear] = useState(query.get('year') || "");
    const [month, setMonth] = useState(query.get('month') || "");
    const [tag_hash_id, setTagID] = useState(query.get('tag') || "");
    const [title, setTitle] = useState(query.get('title') || "");
    const [author_hash_id, setAuthorID] = useState(query.get('author') || "");

    const getInfo = async (category_hash_id, year, month, tag_hash_id, title, author_hash_id) => {
        try {
            const blogListData = await blog_list(category_hash_id, year, month, tag_hash_id, title, author_hash_id);
            if (blogListData.error === "0") {
                const { blog = [], Categories = [], articles_archive = [], articles_author = [], recent = [], tags = [] } = blogListData;
                setRecent(recent);
                setBlogsData(blog);
                setCategories(Categories);
                setAuthors(articles_author);
                setTags(tags);
                setArchive(articles_archive);
            } else {
                setError('Failed to load blog data.');
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred while fetching data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInfo(category_hash_id, year, month, tag_hash_id, title, author_hash_id);
    }, [category_hash_id, year, month, tag_hash_id, title, author_hash_id]);

    const navigate = useNavigate();
    
    // Effect to synchronize state with URL query parameters
    useEffect(() => {
        setCategoryID(query.get('category') || "");
        setYear(query.get('year') || "");
        setMonth(query.get('month') || "");
        setTagID(query.get('tag') || "");
        setTitle(query.get('title') || "");
        setAuthorID(query.get('author') || "");
    }, [query]);

    const updateUrlAndFetch = (newParams) => {
        const searchParams = new URLSearchParams(newParams);
        navigate(`?${searchParams.toString()}`);

        // Update state variables based on newParams
        setCategoryID(newParams.category_hash_id || "");
        setYear(newParams.year || "");
        setMonth(newParams.month || "");
        setTagID(newParams.tag_hash_id || "");
        setTitle(newParams.title || "");
        setAuthorID(newParams.author_hash_id || "");

        // Fetch new data based on the new parameters
        getInfo(newParams.category_hash_id, newParams.year, newParams.month, newParams.tag_hash_id, newParams.title, newParams.author_hash_id);
    };

    const getMonthName = (monthNumber) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthNumber];
    };
  
    return (
        <main className="main" style={{ transform: 'none' }}>
            <div className="page-header mb-75" style={{ marginTop: "4rem" }}>
                <div className="mainCarousel d-none d-md-block d-lg-block  container" style={{ paddingBottom: "0px" ,paddingTop: "4rem"}}>
                    <Row>
                        <Col lg={12} md={12} sm={12} col={12}>
                            <img src={require("../../assets/images/PNG/nutsbyarticles.png")} alt="" style={{  webkitUserDrag: "none",borderRadius:"5px"}} />
                        </Col>
                    </Row>
                </div>
                <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container " style={{ paddingBottom: "0px", paddingTop: "4rem" }}>
                    <Row>
                        <Col lg={12} md={12} sm={12} col={12}>
                            <img src={require("../../assets/images/PNG/blog_mb.png")} alt="" style={{  webkitUserDrag: "none",borderRadius:"5px" }} />
                        </Col>
                    </Row>
                </div>
                <Row className='d-block d-md-none d-lg-none d-sm-none' style={{ paddingTop: "1rem" }}>
                    <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{ marginBottom: "0px" }}>
                        
                    </Col>
                </Row>

                <div className='d-none d-md-block d-lg-block d-sm-block container' style={{ paddingTop: "2rem" }}>
                    <Col className='breadcrumb' style={{ marginBottom: "0px" }}>
                       
                    </Col>
                </div>
            </div>
            <div className="page-content mb-50" style={{ transform: 'none' }}>
                <div className="container" style={{ transform: 'none' }}>
                    <div className="row" style={{ transform: 'none' }}>
                    <div className="col-lg-9 col-md-12" style={{padding:"0 20px"}}>
                        <h4 style={{paddingBottom:"20px"}}>Blogs</h4>
    <div className="loop-grid loop-list pr-30 mb-50">
        {blog.length > 0 ? (
           <div className="row">
          
          {blog.map((c) => (
                   <div className="col-lg-6 col-md-6 mb-30" key={c.id}>
                       <article className="wow fadeIn animated hover-up">
                       <div className="blog-image">
                      <a href={`/blog_details/${c.id}/${c.url_title}`}>
                      <img src={c.image} alt={c.title} style={{ borderRadius: "15px", width: "100%", height: "auto" }} />
                    </a>
                     </div>
                           <div className="entry-content-2 pl-50" style={{ border: 'none' }}>
                             
                              
                               <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10" style={{ display: 'flex', justifyContent: 'space-between',fontSize:"14px" }}>
                               <div className="post-author" style={{ fontSize: '14px', color: 'grey',fontStyle:"Montserrat" }}>
                                   {c.author_name} 
                               </div>
                               <div style={{ display: 'flex', alignItems: 'center',fontSize:"14px" }}>
                              <span className="hit-count has-dot" style={{ marginRight: '5px',color:"#008EB0",fontStyle:"Montserrat" }}>
                              {c.views} Views
                              </span>
                              <span style={{display:"flex"}}>
                             <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_1043_13568)">
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.23242 9.16341C1.23242 9.16341 4.62467 2.37891 10.5611 2.37891C16.4976 2.37891 19.8898 9.16341 19.8898 9.16341C19.8898 9.16341 16.4976 15.9479 10.5611 15.9479C4.62467 15.9479 1.23242 9.16341 1.23242 9.16341Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                               <path d="M10.5618 11.7075C11.9669 11.7075 13.106 10.5684 13.106 9.16333C13.106 7.75821 11.9669 6.61914 10.5618 6.61914C9.15665 6.61914 8.01758 7.75821 8.01758 9.16333C8.01758 10.5684 9.15665 11.7075 10.5618 11.7075Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                               </g>
                              <defs>
                               <clipPath id="clip0_1043_13568">
                               <rect width="21" height="20" fill="white" transform="translate(0.0527344 0.53125)"/>
                               </clipPath>
                               </defs>
                                </svg>
                               </span>
                               </div>

                                 
                               </div>
                               <div>
                               <span className="post-on" style={{fontStyle:"Montserrat",fontSize:"14px"}}>
                              {new Date(c.created_datetime).toLocaleDateString('en-US', {
                               day: '2-digit',
                               month: 'long',
                               year: 'numeric',
                               })}
                               </span>

                                   </div>
                               <h5 className="post-title mb-10 mt-2" >
                                   <a style={{color:"#2D2D2D",fontWeight:"700" }} href={`/blog_details/${c.id}/${c.url_title}`}>{c.title}</a>
                               </h5>
                               <p style={{color:"#999999",fontSize:"14px",marginBottom:"5px"}} className="post-excerpt">{c.short_description}</p>
                               <div className="entry-meta meta-1 font-xs color-grey pb-10">
                                   <a style={{color:"#219EBC",fontSize:"14px"}} href={`/${c.url_title}/blog/${c.id}}`} className="text-brand font-heading font-weight-bold">
                                   View Full Article <i className="fi-rs-arrow-right"></i>
                                   </a>
                               </div>
                           </div>
                       </article>
                   </div>
               ))}
           </div>
           
          
       
        ) : (
            <p>No blog posts available.</p>
        )}
    </div>
</div>

                        <div className="col-lg-3 col-md-12 primary-sidebar sticky-sidebar" style={{ paddingTop: "2rem" }}>
                            <div className="theiaStickySidebar">
                                <div className="widget-area">
                                    {/* <div className="sidebar-widget-2 widget_search mb-50">
                                        <div className="search-form">
                                            <form action="/blog" method="GET">
                                                <input type="text" name="title" placeholder="Search Blogs…" />
                                                <button type="submit"><i className="fi-rs-search"></i></button>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{color:"#242424" ,fontStyle:"Montserrat",fontWeight:"600",fontSize:"20px"}}>Recent Posts</h5>
                                        <ul>
                                            {recent.length > 0 ? (
                                                recent.map((re) => (
                                                    <li key={re.id} style={{paddingBottom:"5px"}}>
                                                         • <a  style={{color:"#242424",fontWeight:"400",fontStyle:"Montserrat",fontSize:"15px"}} href={`/${re.url_title}/blog/${re.id}`}>{re.title}</a>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No recent posts.</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{fontWeight:"600",fontSize:"20px"}}>Categories</h5>
                                        <ul>
                    {categories.map((cat) => (
                        <li key={cat.hash_id} style={{paddingBottom:"5px"}} >
                            <Link  to ={`?category=${cat.hash_id}`} style={{color:"#242424",fontWeight:"600",fontStyle:"Montserrat",fontSize:"15px"}}>{cat.name}</Link>
                            <span style={{color:"#9B9999"}} className="count"> ({cat.articles_count})</span>
                        </li>
                    ))}
                </ul>
                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{fontWeight:"600",fontSize:"20px"}}>Archives</h5>
                                        <ul>
    {archive.length > 0 ? (
        archive.map((b) => {
            const createdDate = new Date(b.created_datetime); 
            const year = createdDate.getFullYear(); 
            const month = getMonthName(createdDate.getMonth()); 
            return (
                <li key={b.created_datetime} style={{paddingBottom:"5px"}}>
                    <Link to={`?month=${month}&year=${year}`} style={{color:"#242424",fontWeight:"600",fontStyle:"Montserrat",fontSize:"15px"}}>
                        {createdDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </Link>
                    <span style={{ color: "#9B9999" }} className="count"> ({b.articles_count})</span>
                </li>
            );
        })
    ) : (
        <li>No archives available.</li>
    )}
</ul>

                                    </div>
                                    <div className="sidebar-widget widget-category-2 mb-50">
                                        <h5 className="section-title style-1 mb-10" style={{fontWeight:"600",fontSize:"20px"}} >Authors</h5>
                                        <ul>
                                            {authors.length > 0 ? (
                                                authors.map((e) => (
                                                    <li key={e.user_id} style={{paddingBottom:"5px"}}>
                                                       <Link to ={`?author=${e.hash_user_id}`} style={{color:"#242424",fontWeight:"600",fontStyle:"Montserrat",fontSize:"15px"}} >
                                                            {e.name}
                                                        </Link>
                                                        <span style={{color:"#9B9999"}} className="count"> ({e.articles_count})</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No authors available.</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget widget-tags mb-50 pb-10">
                                        <h5 className="section-title style-1 mb-10" style={{fontWeight:"600",fontSize:"20px"}}>Popular Tags</h5>
                                        <ul className="tags-list" style={{ display: 'flex', flexWrap: 'wrap', padding: '0' }}>
    {tags.length > 0 ? (
        tags.map((tag) => (
            <li key={tag.id} className="hover-up" style={{ 
                background: "#F3F3F3", 
                borderRadius: "6px", 
                margin: "5px 5px 5px 0px", 
                padding: "5px", 
                display: 'flex', 
                alignItems: 'center' ,fontSize:"15px",
                fontWeight:"600"
            }} >
                <span style={{ marginRight: '5px' }}> 
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4401 6.87203L14.1259 3.3285C14.1016 3.05976 14.0136 2.80068 13.8693 2.57268C13.725 2.34467 13.5285 2.1543 13.296 2.01729L12.8228 1.73901L11.3371 4.26545C11.5946 4.57164 11.7356 4.95902 11.7352 5.35913C11.7348 5.75924 11.5929 6.14631 11.3347 6.45194C11.0765 6.75757 10.7185 6.96208 10.3241 7.02931C9.92968 7.09655 9.52419 7.02218 9.1793 6.81936C8.8344 6.61654 8.57231 6.29833 8.43935 5.92096C8.30638 5.54359 8.31111 5.13136 8.45269 4.75713C8.59426 4.38291 8.86358 4.07078 9.21303 3.87592C9.56248 3.68106 9.96957 3.616 10.3623 3.69226L11.8481 1.16581L11.3748 0.88753C11.1421 0.750911 10.8801 0.671717 10.6106 0.656507C10.3411 0.641297 10.0719 0.690509 9.82524 0.800067L6.5761 2.24753C6.03714 2.48605 5.58687 2.88807 5.28904 3.39667L0.784331 11.0569C0.556301 11.4447 0.49165 11.9071 0.604598 12.3426C0.717547 12.778 0.998843 13.1507 1.38661 13.3788L7.23486 16.8179C7.62262 17.0459 8.08509 17.1106 8.52052 16.9976C8.95595 16.8847 9.32868 16.6034 9.55671 16.2156L14.0614 8.55539C14.3611 8.0479 14.4936 7.45898 14.4401 6.87203Z" fill="#9A9A9A"/>
                    </svg>
                </span>

                <span>
                <Link to={`?tag=${tag.hash_id}`} style={{
                                                            color: "#242424",
                                                            fontWeight: "600",
                                                            marginLeft: "5px"
                                                        }}>
                                                            {tag.name}
                                                        </Link>
                </span> 
            </li>
        ))
    ) : (
        <li>No tags available.</li>
    )}
</ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Blog;