import React, { useState } from "react";
import {Container,Row,Col,Modal} from 'react-bootstrap'; 
import {ReactComponent as Certificate} from "../assets/images/certificate_svg.svg";
import { RightArrow} from "../MiniComponents/SvgIcons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../SubCss/AuthorozationCss.css";
import Lottie from "lottie-react";
import certified from "../assets/lottie/genuine-trusted.json";
import truck from "../assets/lottie/delivery.json";


const AuthorizationCertificate = (certificateImages)=> {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1  ,
        },
      }; 
      const imagesToShow = Array.isArray(certificateImages) 
      ? certificateImages.map(item => item.certificateImages).filter(image => image !== null && image !== '') 
      : [certificateImages.certificateImages].filter(image => image !== null && image !== ''); // Handle non-array cases
    
    console.log(imagesToShow, 'Images to show');
    
      
    const [show, setShowWriteReviewModal] = useState(false);
    const handleClose = () => setShowWriteReviewModal(false);  
    

    return(
        < >   
            <div onClick={setShowWriteReviewModal} style={{padding:'0px'}}>
                {/* <Row style={{backgroundColor:"#ECE3FF"}}> */}
                    {/* <Col lg={12} md={12} sm={12} xs={12} className=""> */}
                  
                    <div style={{   }}>
                    <Row className="" style={{ margin: 0 }}>
            <Col xs={12} style={{ padding: 0 }}>
              <img 
                src={'/assets/authentic.png'} 
                alt="WhatsApp Image" 
                style={{ width: '100%', height: 'auto', padding:"25px 5px"}} 
              />
            </Col>
          </Row>


</div>

            </div> 
            <div>
            {imagesToShow.length > 0 && ( // Only render the Modal if there are images
            <Modal className="CertificatesCard special_modal" show={show} onHide={handleClose}   
                aria-labelledby="contained-modal-title-vcenter"  
                classNames={{ overlay: { background: 'black' } }} 
                centered
            >
                <Modal.Body>
                    <Carousel responsive={responsive}>
                        {imagesToShow.map((image, index) => (
                            <div key={index}>
                                <img alt="Certificate" src={image} style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'contain' }} />
                            </div>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        )}
            </div>
        </>
    );
    
}

export default AuthorizationCertificate