import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card, Modal, Button, Form, FloatingLabel, CarouselCaption, CarouselItem, Image } from "react-bootstrap";
  
// import { ReactComponent as FreeDelivery } from '../assets/images/free_delivery.svg'; 
// import { ReactComponent as EliteBadge } from '../assets/images/elite_big.svg';
import '../SubCss/Elitememembership.css';
import { AddSubScriptionPlan, GetHeaderDetailsApi, MembershipDetails, MembershipreviewList, SubmitMembershipFeedback } from "../Service/ApiCalling";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { Elite,GlimpseStars,Cashback,FreeDelivery,Nutsby,ExtraDiscnt,EliteBadge } from "../MiniComponents/SvgIcons.jsx";
import useRazorpay from "react-razorpay";
export default function EliteMembership() {

    const [Razorpay] = useRazorpay();

    const [details, setDetails] = useState([]);
    const [reviews, setReview] = useState([]);

    const [membership_data, setMembershipData] = useState([]);
    const [AvgPremiumSaved, setAvgPremiumSaved] = useState("");
    const [TotalSaved, setTotalSaved] = useState("");
    const [TotalSavedPremium, setTotalPremiumSaved] = useState("");

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
        },
        tablet: {
            breakpoint: { max: 1024, min: 728 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 728, min: 509 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 509, min: 0 },
            items: 2,
        },
    };

    const [subscribed, setSubscribed] = useState("");
    const [isreviewAdded, setReviewAdded] = useState('0');
    const [id, setId] = useState('');

    const [showmodal, setJoinModal] = React.useState(false);

    const handleClose = () => setJoinModal(false);
    const handleShow = () => setJoinModal(true);

    //   useEffect(() => {
    //     getHeaderData();
    //   }, [])

    const getMembershipDetails = async () => {

        let data = await MembershipDetails();
        if (data.error === "0") {
            setMembershipData(data.membership_data);
            setAvgPremiumSaved(data.premium_saved_avg);
            setTotalSaved(data.total_saved);
            setTotalPremiumSaved(data.total_saved_premium);

            {
                data.membership_data.map((data, index) => (
                    setReviewAdded("1"),
                    setId(data.id)
                ))
            };
            // if(localStorage.getItem('is_subscribed')!==null)
            //     {
            //         let a = localStorage.getItem('is_subscribed');
            //         setSubscribed(a);
            //         console.log(a,"abcd")

            //     }else{
            //         console.log("a","abcd")
            //     }

        }
    }

    const getHeaderData = async () => {
        // console.log("called header");
        try{
        let AccountDetails = await GetHeaderDetailsApi();
        if (AccountDetails.error === "0") {

            // if (AccountDetails.is_subscribed === "1") {
                setSubscribed(AccountDetails.is_subscribed);
                // console.log(subscribed,"subscribed1");

            // }else if(AccountDetails.is_subscribed === "2"){
            //     setSubscribed(AccountDetails.is_subscribed);
            //     console.log(subscribed,"subscribed2");
            // }else{
            //     setSubscribed("0");
            //     console.log(subscribed,"subscribed3");
            // }


        }
    }catch (error){
        console.log(error,"error");
    }

    }

    const getReviews = async () => {

        let data = await MembershipreviewList();
        if (data.error === "0") {
            setReview(data.reviews);

        }
    }

    const [review, setreview] = useState('');
    const [reviewError, setreviewError] = useState("");

    const submitReview = async () => {
        if (review === "") {
            setreviewError("Please Enter your valuble feedback!");
        } else {
            // console.log(review, id)
            // console.log("submitted");
            let data = await SubmitMembershipFeedback(review, id);
            if (data.error === "0") {
                // console.log("submitted");
            }
        }

    }

    const AddSubScriptionPlanApi = async() =>{
        let Data = await AddSubScriptionPlan("897");
        if(Data.error==="0"){
            // console.log("success");
            handlePayment(Data.razorpay_key,Data.order_id,199);
            
        }else{
            // console.log("error");
        }

    }
    const handlePayment = async (key,order_id,amount) => {   

        const options = {
          key: key, // Enter the Key ID generated from the Dashboard
          amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Nutsby",
          description: "Order Placing", 
          order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            getMembershipDetails();
            getReviews();
            getHeaderData();
            handleClose();
            // handleAddwalletClose();
            // handlewalletpaymentClose();
            
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
          },
          prefill: { 
            email: "youremail@example.com",
            contact: "9999999999",
          }
        };
      
        const rzp1 = new Razorpay(options);
      
        rzp1.on("payment.failed", function (response) {
            // alert("Payment Failed");
            handleClose();
            // handleAddwalletClose();
            // handlewalletpaymentClose();
        //   alert(response.error.code);
        //   alert(response.error.description);
        //   alert(response.error.source);
        //   alert(response.error.step);
        //   alert(response.error.reason);
        //   alert(response.error.metadata.order_id);
        //   alert(response.error.metadata.payment_id);
        });
      
        rzp1.open();
      };

    useEffect(() => {
        getMembershipDetails();
        getReviews();
        getHeaderData();
    }, []);

    const navigate = useNavigate()
    const gotToNewPage = () => {
        navigate("/");
    }
    return (
        <>


            <div className="membership mt-50">


                <Container className="mt-50 d-flex justify-content-center">

                    <Col lg={12} md={12} sm={12} xs={12} className="row" style={{ marginTop: "6rem" }}>

                     
                            <Col lg={12} md={12} sm={12} xs={12}  style={{ margin: "0rem" }}>
                                <div className="d-flex justify-content-center"><Nutsby style={{ height: "30px" }} /></div>
                                <div className="d-flex justify-content-center" style={{color:"#F8CC7E",fontSize:"60px",fontWeight:"600"}}>VOYAGE</div>
                                <div  className="d-flex justify-content-center" style={{color:"white",fontSize:"16px",fontWeight:"600"}}>Online Store | Offline Store</div>
                            </Col>
                          

                            <Col lg={12} md={12} sm={12} xs={12}  style={{ margin: "0rem" }}>
                            {membership_data.map((data, index) => (
                                <>
                                <div style={{display:"flex",justifyContent:"center",color:"#C6A667",fontWeight:"600",fontSize:"14px",margin:"10px"}}>{data.user_name}</div>
                                  <div className="  " style={{display:"flex",justifyContent:"center",color:"#C6A667",fontWeight:"600",fontSize:"14px"}}>{subscribed === "1" ? (<span className="membership-text">Expires on {data.expiry_date}</span>) : (subscribed === "2" ? <span className="membership-text">Expired on {data.expiry_date}</span> : <span className="membership-text"></span>)}</div>
                                <div className="d-flex justify-content-center mt-20">
                                    


                                <div className="col-5">
    <div className="plancard membership-card" style={{width:"100%" , background: subscribed === 0 ? "#1D282D" : "#32373A",padding:"20px"}}>
        <div className="price-section d-flex justify-content-between">
            {subscribed === "0" ? (
                <>
                    <div className="savings-section d-flex align-items-center" align="start">
                       <div> <img src={require("../assets/images/PNG/Rupee.png")} alt="Rupee Icon" className="rupee-icon" style={{width:"50px",marginRight:"10px"}}/></div>
                      <div> <div className="you-saved-text" style={{color:"white"}}>You Saved!</div>
                      <div style={{color:"#C6A667"}}>from the first membership</div>
                      </div> 
                    </div>
                    <span className="savings-amount" style={{color:"#C6A667"}} align="end">₹{TotalSavedPremium}</span>
                </>
            ) : (
                <div className="">
                
                    <div style={{color:"#F8CC7E",fontSize:"24px",fontWeight:"600", display:"flex",justifyContent:"center"}}>for 3 months</div>
                    <span className="original-price" style={{ display:"flex",justifyContent:"center"}}>{data.plan_mrp}</span>
                    <span className="discounted-price" style={{ display:"flex",justifyContent:"center"}}>{data.plan_amount}</span>
                
                </div>
            )}
            {subscribed === "0" && (
      <button className="join-button" onClick={handleShow}>Join Now</button>
)}

        </div>
        {/* Optionally add more content here */}
        {/* <div className="savings-info membership-card">Avg. <span className="membership-text">₹{AvgPremiumSaved}</span> has been saved by elite members.</div> */}
    </div>
</div>

                                </div>
                                </>
                            ))
                            }
   </Col>
                      
                        {/* <Col className="secondCard" lg={6} md={6} sm={12} xs={12} style={{ alignContent: "center", alignItems: "center",padding:"5px" }}>


                            <Card className="memberCard content-p">
                                <Col lg={12} md={12} sm={12} xs={12} className="d-flex justify-content-center">
                                    <GlimpseStars style={{ width: "25px", height: "25px" }} />
                                    <span id="" className='elite_subscribed_text' style={{ color: "white", marginBottom: "1.5rem" }}>{"Benefits of Joining ELITE"}&nbsp;</span>

                                    <GlimpseStars style={{ width: "25px", height: "25px" }} />
                                </Col>
                                <Row className="mb-3 ">
                                    <Col xs={2} md={2} sm={2} lg={2} style={{textAlign:"right",alignContent:"center"}}>
                                        <FreeDelivery />
                                    </Col>

                                    <Col xs={10} md={10} sm={10} lg={10} className=''>
                                        <Col>
                                            <span className="span-row" style={{ color: "white" }}>Completely Free delivery</span>
                                        </Col>
                                        <Col style={{ lineHeight: "1.2" }}>
                                            <span className="grey-text">Elite members get free delivery on all orders, no minimum purchase required.</span>
                                        </Col>
                                    </Col>

                                </Row>
                                

                                <Row className="mb-3 ">
                                    <Col xs={2} md={2} sm={2} lg={2} style={{textAlign:"right",alignContent:"center"}}>
                                        <ExtraDiscnt />
                                    </Col>
                                    <Col xs={10} md={10} sm={10} lg={10} className=''>
                                        <Col>
                                            <span className="span-row" style={{ color: "white" }}>Save 5%, Upto 15% Extra Off</span></Col>
                                        <Col style={{ lineHeight: "1.2" }}>
                                            <span className="grey-text">Get a guaranteed 5% discount on all purchases with your elite membership! Plus, enjoy up to 15% off select items.</span>
                                        </Col>
                                    </Col>

                                </Row>

                                <Row className="mb-3 ">
                                    <Col xs={2} md={2} sm={2} lg={2} style={{textAlign:"right",alignContent:"center"}}>
                                        <Cashback />
                                    </Col>
                                    <Col xs={10} md={10} sm={10} lg={10} className=''>
                                        <Col>
                                            <span className="span-row" style={{ color: "white" }}>Membership Cashback</span>
                                        </Col>
                                        <Col style={{ lineHeight: "1.2" }}>
                                            <span className="grey-text">If you don't use your membership benefits, your fee will be refunded as Fit Cash Wallet.</span>
                                        </Col>
                                    </Col>

                                </Row>
                            </Card>
                        </Col> */}


                    </Col>

                </Container>

                {/* <Container className="mt-20 ">
                    <Col lg={12} md={12} sm={12} xs={12} className="d-flex justify-content-center mb-20">
                        <GlimpseStars style={{ width: "25px", height: "25px" }} />
                        <span id="" className='elite_subscribed_text' style={{ color: "white" }}>{"ELITE Happy Members"}&nbsp;</span>

                        <GlimpseStars style={{ width: "25px", height: "25px" }} />
                    </Col>

                    <Carousel autoPlay={false} arrows={false} infinite={true} responsive={responsive} style={{marginBottom:"20px"}}  >
                        {reviews.map((reviews, index) => (
                            <div>
                                <Col style={{padding:"3px"}}>
                                    <Card className="mreviews memberCard" style={{borderRadius:'10px'}}>
                                        <Row className="aaaaaaaaaa d-flex justify-content-center" style={{overflow:'auto'}}>
                                         <span className="elite_reviews span-row grey-text">{reviews.review}</span>    
                                        </Row>
                                        <Row className=" d-flex justify-content-end" align="end">
                                            <Col sm={8} md={8} xs={8} className='d-flex justify-content-end'>                                              
                                            <span style={{ color: "white",fontSize:"12px"}}>{reviews.user_name}</span>
                                            </Col>
                                            <Col>
                                            <Image
                                                src={reviews.profile_image}
                                                className="review-logo-img img-fluid"
                                                style={{ width: "25px", height: "25px", objectFit: 'cover', borderRadius: '100px' }}
                                                alt={`Profile ${index + 1}`}
                                                id="image"
                                                roundedCircle
                                            />
                                        </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </div>
                        ))

                        }
                    </Carousel>
                </Container> */}


                {/* {isreviewAdded === "" ? <></> : <Container className="membership">
                    <h3 className="feedback" style={{ color: "white", marginTop: "10px", marginBottom: "0rem" }}>Elite Feedback</h3>
                    <span className="grey-text" style={{marginBottom:"10px"}}>Write your experience being Elite Member And Would you recommend to others ?</span>
                    <FloatingLabel className="feedback-label" controlId="floatingTextarea2" label="Give Feedback" style={{ color: "#838383", backgroundColor: "#232323",marginTop:'14px' }}>
                        <Form.Control
                            as="textarea"
                            placeholder="Give Feedback" 
                            value={review} 
                            onChange={(e) => setreview(e.target.value)}
                            onKeyUp={(e) => setreviewError("")}
                            className="feedback-textarea"
                            style={{ height: '230px', backgroundColor: "#232323", color: "#838383",border:'1px solid #838383' }}
                        />
                         <span
                                style={{
                                color: "red"
                            }}>{reviewError}</span>
                    </FloatingLabel>
                    <Row lg={6} md={3} sm={3} xs={3} style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                        <Button variant="primary" type="" style={{ backgroundImage: "linear-gradient(to right, #FBE4BA, #9E7829)", border: "transparent", color: "black", borderRadius: "25px", marginRight: "14px", marginBottom: "3vh" }} align="end" onClick={() => { submitReview() }}>
                            Submit
                        </Button>
                    </Row>

                </Container>} */}


                <Modal className="eliteJoinModal" show={showmodal} onHide={handleClose} style={{  borderRadius: "30px" }}aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body className="eliteModal d-flex justify-content-center" style={{display:'flex',alignItems:'center'}}>
                        <div>
                            <Container className="d-flex justify-content-center" style={{padding:'0px'}}>
                                <EliteBadge style={{width:"90%",height:"100%"}}/>
                            </Container>
                            {membership_data.map((data, index) => (
                                <Col className="d-flex justify-content-between container  mt-10" style={{paddingRight:"15px",paddingLeft:"15px"}}>
                                    
                                        <span className='popup-text'>
                                            3 months Membership
                                        </span>
                                        <div>
                                        <span className="original-price1">{data.plan_mrp}</span>
                                        <span className="discounted-price1">{data.plan_amount}</span>
                                        </div>                                

                                    

                                </Col>
                            ))}
                            <Container className="d-flex justify-content-center mt-20">
                                <Button onClick={AddSubScriptionPlanApi} variant="primary" type="submit" style={{ width:"100%",fontWeight: "500", fontSize: "18px", border: "transparent", backgroundImage: "linear-gradient(to right, #FBE4BA, #9E7829)", color: "black", borderRadius: "30px",padding:'10px' }}>
                                    Buy Now
                                </Button>
                            </Container>
                        </div>

                    </Modal.Body>

                </Modal>


            </div>
        </>
    );
}