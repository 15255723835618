import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col,Button,Form,Card,Image} from 'react-bootstrap';

function PrivacyJS() {
    return(
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Container className="privacy">
                    <div className='about' style={{margin:"100px 0px"}}>
                        <h2 className='about_h2' style={{textAlign:"center"}}>PRIVACY POLICY</h2>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                Last updated December 11, 2023
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                This privacy notice for Nutsby Fitcare LLP (doing business as Nutsby) 
                                ('we', 'us', or 'our'), describes how and why we might collect, store, use, 
                                and/or share ('process') your information when you use our services ('Services'), 
                                such as when you:
                            </p>
                            <ul>
                                <li>
                                    Visit our website at nutsby.com, or any website of ours that links to this privacy notice
                                </li>
                                <li>
                                    Download and use our mobile application (Nutsby), or any other application of ours that links to this privacy notice
                                </li>
                                <li>
                                    Engage with us in other related ways, including any sales, marketing, or events
                                </li>
                            </ul>
                            <a href='/terms-and-conditions' style={{color:"#219EBC",fontWeight:"500"}}>
                                https://nutsby.com/terms-and-conditions
                            </a>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>Questions or concerns?</b>Reading this privacy notice will help you understand your 
                                privacy rights and choices. If you do not agree with our policies and practices, 
                                please do not use our Services. If you still have any questions or concerns, please 
                                contact us at
                                <a href='mailto: care@nutsby.com'> care@nutsby.com.</a>
                            </p>
                            <h5>SUMMARY OF KEY POINTS</h5>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                This summary provides key points from our privacy notice, but you can find out 
                                more details about any of these topics by clicking the link following each key 
                                point or by using our table of contents below to find the section you are looking for.
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                <b>What personal information do we process? </b>When you visit, use, or navigate our 
                                Services, we may process personal information depending on how you interact with us 
                                and the Services, the choices you make, and the products and features you use. 
                                Learn more about<a href='#1'>personal information you disclose to us.</a>
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>Do we process any sensitive personal information?</b> We do not process 
                               sensitive personal information.
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>Do we receive any information from third parties?</b> 
                                We do not receive any information from third parties.
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>How do we process your information?</b> 
                                We process your information to provide, improve, and administer our Services, 
                                communicate with you, for security and fraud prevention, and to comply with law. 
                                We may also process your information for other purposes with your consent. We 
                                process your information only when we have a valid legal reason to do so. Learn 
                                more about <a href='#2'> how we process your information.</a>
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>In what situations and with which types of parties do we share personal information?</b> 
                                We may share information in specific situations and with specific categories of third 
                                parties. Learn more about <a href='#3'>when and with whom we share your personal information.</a>
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>How do we keep your information safe?</b> 
                                We have organisational and technical processes and procedures in place to protect 
                                your personal information. However, no electronic transmission over the internet 
                                or information storage technology can be guaranteed to be 100% secure, so we cannot 
                                promise or guarantee that hackers, cybercriminals, or other unauthorised third 
                                parties will not be able to defeat our security and improperly collect, access, 
                                steal, or modify your information. Learn more about
                                <a href='#7'> how we keep your information safe.</a>
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>What are your rights?</b> 
                                Depending on where you are located geographically, the applicable privacy law may mean
                                you have certain rights regarding your personal information. Learn more about 
                                <a href='#9'> your privacy rights.</a>
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                               <b>How do you exercise your rights?</b> 
                                The easiest way to exercise your rights is by visiting 
                                <a href='#'>https://nutsby.com/contact-us</a>, r by contacting us. We will consider 
                                and act upon any request in accordance with applicable data protection laws.
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                Want to learn more about what we do with any information we collect?
                                <a href='/privacy-policy'>Review the privacy notice in full.</a>
                            </p>
                           <h3>TABLE OF CONTENTS</h3>
                           <p><a href='#1' style={{color:"#219EBC", fontWeight:"500"}}><u>1. WHAT INFORMATION DO WE COLLECT?</u></a></p>
                           <p><a href='#2' style={{color:"#219EBC", fontWeight:"500"}}><u>2. HOW DO WE PROCESS YOUR INFORMATION?</u></a></p>
                           <p><a href='#3' style={{color:"#219EBC", fontWeight:"500"}}><u>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</u></a></p>
                           <p><a href='#4' style={{color:"#219EBC", fontWeight:"500"}}><u>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES??</u></a></p>
                           <p><a href='#5' style={{color:"#219EBC", fontWeight:"500"}}><u>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u></a></p>
                           <p><a href='#6' style={{color:"#219EBC", fontWeight:"500"}}><u>6. HOW LONG DO WE KEEP YOUR INFORMATION?</u></a></p>
                           <p><a href='#7' style={{color:"#219EBC", fontWeight:"500"}}><u>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</u></a></p>
                           <p><a href='#8' style={{color:"#219EBC", fontWeight:"500"}}><u>8. DO WE COLLECT INFORMATION FROM MINORS?</u></a></p>
                           <p><a href='#9' style={{color:"#219EBC", fontWeight:"500"}}><u>9. WHAT ARE YOUR PRIVACY RIGHTS?</u></a></p>
                           <p><a href='#10' style={{color:"#219EBC", fontWeight:"500"}}><u>10. CONTROLS FOR DO-NOT-TRACK FEATURES</u></a></p>
                           <p><a href='#11' style={{color:"#219EBC", fontWeight:"500"}}><u>11. DO WE MAKE UPDATES TO THIS NOTICE?</u></a></p>
                           <p><a href='#12' style={{color:"#219EBC", fontWeight:"500"}}><u>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u></a></p>
                           <p><a href='#13' style={{color:"#219EBC", fontWeight:"500"}}><u>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</u></a></p>
                    </div>
                    <div>
                        <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
                        <h5>Personal information you disclose to us</h5>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>In Short:</b> 
                            We collect personal information that you provide to us.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            We collect personal information that you voluntarily provide to us when you register on 
                            the Services, express an interest in obtaining information about us or our products and 
                            Services, when you participate in activities on the Services, or otherwise when you 
                            contact us.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>Personal Information Provided by You. </b> 
                                The personal information that we collect depends on the context of your interactions 
                                with us and the Services, the choices you make, and the products and features you use.
                                The personal information we collect may include the following:
                                <ul>
                                    <li>names</li>
                                    <li>phone numbers</li>
                                    <li>email addresses</li>
                                    <li>mailing addresses</li>
                                    <li>usernames</li>
                                    <li>passwords</li>
                                    <li>contact preferences</li>
                                    <li>contact or authentication data</li>
                                    <li>billing addresses</li>
                                </ul>
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>Sensitive Information.</b> 
                            We do not process sensitive information.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>Payment Data.</b> 
                                We may collect data necessary to process your payment if you make purchases, such 
                                as your payment instrument number, and the security code associated with your 
                                payment instrument. All payment data is stored by PhonePe. You may find their 
                                privacy notice link(s) here:
                                <a href='/privacy-policy'>https://www.phonepe.com/privacy-policy</a>
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>Application Data.</b> 
                                If you use our application(s), we also may collect the following information if you 
                                choose to provide us with access or permission:
                        </p>
                        <ul>
                            <li>Geolocation Information. We may request access or permission to track location-based 
                                information from your mobile device, either continuously or while you are using our 
                                mobile application(s), to provide certain location-based services. If you wish to 
                                change our access or permissions, you may do so in your device's settings.
                            </li>
                            <li>
                                Mobile Device Data. We automatically collect device information (such as your mobile 
                                device ID, model, and manufacturer), operating system, version information and 
                                system configuration information, device and application identification numbers, 
                                browser type and version, hardware model Internet service provider and/or mobile 
                                carrier, and Internet Protocol (IP) address (or proxy server). If you are using our 
                                application(s), we may also collect information about the phone network associated 
                                with your mobile device, your mobile device’s operating system or platform, the 
                                type of mobile device you use, your mobile device’s unique device ID, and information 
                                about the features of our application(s) you accessed.
                            </li>
                            <li>
                                Push Notifications. We may request to send you push notifications regarding your 
                                account or certain features of the application(s). If you wish to opt out from 
                                receiving these types of communications, you may turn them off in your device's 
                                settings.
                            </li>
                        </ul>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            This information is primarily needed to maintain the security and operation of our 
                            application(s), for troubleshooting, and for our internal analytics and reporting 
                            purposes.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            All personal information that you provide to us must be true, complete, and accurate, 
                            and you must notify us of any changes to such personal information.
                        </p>
                        <h4>Information automatically collected</h4>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            <b>In Short:</b> 
                            Some information — such as your Internet Protocol (IP) address and/or browser and 
                            device characteristics — is collected automatically when you visit our Services.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            We automatically collect certain information when you visit, use, or navigate the 
                            Services. This information does not reveal your specific identity (like your name 
                            or contact information) but may include device and usage information, such as your 
                            IP address, browser and device characteristics, operating system, language preferences, 
                            referring URLs, device name, country, location, information about how and when you 
                            use our Services, and other technical information. This information is primarily needed 
                            to maintain the security and operation of our Services, and for our internal analytics 
                            and reporting purposes.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            Like many businesses, we also collect information through cookies and similar 
                            technologies.
                        </p>
                        <p className='about_p' style={{margin:"10px 0px"}}>
                            The information we collect includes:
                            <ul>
                                <li>
                                    Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and 
                                    performance information our servers automatically collect when you access or use 
                                    our Services and which we record in log files. Depending on how you interact with 
                                    us, this log data may include your IP address, device information, browser type, 
                                    and settings and information about your activity in the Services (such as the 
                                    date/time stamps associated with your usage, pages and files viewed, searches, 
                                    and other actions you take such as which features you use), device event 
                                    information (such as system activity, error reports (sometimes called 'crash 
                                    dumps'), and hardware settings).
                                </li>
                                <li>
                                    Device Data. We collect device data such as information about your computer, 
                                    phone, tablet, or other device you use to access the Services. Depending on the 
                                    device used, this device data may include information such as your IP address 
                                    (or proxy server), device and application identification numbers, location, 
                                    browser type, hardware model, Internet service provider and/or mobile carrier, 
                                    operating system, and system configuration information.
                                </li>
                                <li>
                                    Location Data. We collect location data such as information about your device's 
                                    location, which can be either precise or imprecise. How much information we 
                                    collect depends on the type and settings of the device you use to access the 
                                    Services. For example, we may use GPS and other technologies to collect 
                                    geolocation data that tells us your current location (based on your IP address). 
                                    You can opt out of allowing us to collect this information either by refusing 
                                    access to the information or by disabling your Location setting on your device. 
                                    However, if you choose to opt out, you may not be able to use certain aspects 
                                    of the Services.
                                </li>
                            </ul>
                        </p>
                        <p id="1">
                                        <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
                                        <h4>Personal information you disclose to us</h4>
                                        <p>
                                            <b>In Short:</b>
                                            We collect personal information that you provide to us.
                                        </p>
                                        <p>
                                            We collect personal information that you voluntarily provide to us when you register on the Services, 
                                            express an interest in obtaining information about us or our products and Services, when you participate 
                                            in activities on the Services, or otherwise when you contact us.
                                        </p>
                                        <p>
                                            <b>Personal Information Provided by You. </b>
                                            The personal information that we collect depends on the context of your interactions with us and the 
                                            Services, the choices you make, and the products and features you use. The personal information we collect 
                                            may include the following:
                                        </p>
                                        <ul>
                                            <li>names</li>
                                            <li>phone numbers</li>
                                            <li>email addresses</li>
                                            <li>mailing addresses</li>
                                            <li>usernames</li>
                                            <li>passwords</li>
                                            <li>contact preferences</li>
                                            <li>contact or authentication data</li>
                                            <li>billing addresses</li>
                                        </ul>
                                        <p>
                                            <b>Sensitive Information.</b>We do not process sensitive information.
                                        </p>
                                        <p>
                                            <b>Payment Data.</b>We may collect data necessary to process your payment if you make purchases, such as 
                                            your payment instrument number, and the security code associated with your payment instrument. All payment 
                                            data is stored by PhonePe. You may find their privacy notice link(s) here: 
                                            <a href="/privacy-policy">https://www.phonepe.com/privacy-policy</a>
                                        </p>
                                        <p>
                                            <b>Application Data.</b>If you use our application(s), we also may collect the following information if 
                                            you choose to provide us with access or permission:
                                            <ul>
                                                <li>
                                                    Geolocation Information. We may request access or permission to track location-based information 
                                                    from your mobile device, either continuously or while you are using our mobile application(s), 
                                                    to provide certain location-based services. If you wish to change our access or permissions, you
                                                    may do so in your device's settings.
                                                </li>
                                                <li>
                                                    Mobile Device Data. We automatically collect device information (such as your mobile device ID, 
                                                    model, and manufacturer), operating system, version information and system configuration 
                                                    information, device and application identification numbers, browser type and version, hardware 
                                                    model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy 
                                                    server). If you are using our application(s), we may also collect information about the phone 
                                                    network associated with your mobile device, your mobile device’s operating system or platform, 
                                                    the type of mobile device you use, your mobile device’s unique device ID, and information about 
                                                    the features of our application(s) you accessed.
                                                </li>
                                                <li>
                                                    Push Notifications. We may request to send you push notifications regarding your account or 
                                                    certain features of the application(s). If you wish to opt out from receiving these types of 
                                                    communications, you may turn them off in your device's settings.
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            This information is primarily needed to maintain the security and operation of our application(s), for 
                                            troubleshooting, and for our internal analytics and reporting purposes.
                                        </p>
                                        <p>
                                            All personal information that you provide to us must be true, complete, and accurate, and you must notify 
                                            us of any changes to such personal information.
                                        </p>
                                        <h4>Information automatically collected</h4>
                                        <p>
                                            <b>In Short:</b>Some information — such as your Internet Protocol (IP) address and/or browser and device 
                                            characteristics — is collected automatically when you visit our Services.
                                        </p>
                                        <p>
                                            We automatically collect certain information when you visit, use, or navigate the Services. This information 
                                            does not reveal your specific identity (like your name or contact information) but may include device and 
                                            usage information, such as your IP address, browser and device characteristics, operating system, language 
                                            preferences, referring URLs, device name, country, location, information about how and when you use our 
                                            Services, and other technical information. This information is primarily needed to maintain the security 
                                            and operation of our Services, and for our internal analytics and reporting purposes.
                                        </p>
                                        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                                        <p>The information we collect includes:</p>
                                        <ul>
                                            <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</li>
                                            <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                                            <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
                                        </ul>
                                    </p>
                                    <p id="2">
                                        <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
                                        <p>
                                            <b>In Short: </b>We process your information to provide, improve, and administer our Services, communicate 
                                            with you, for security and fraud prevention, and to comply with law. We may also process your information 
                                            for other purposes with your consent.
                                        </p>
                                        <p><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>
                                        <ul>
                                            <li>
                                                <b>To facilitate account creation and authentication and otherwise manage user accounts. </b>
                                                We may process your information so you can create and log in to your account, as well as keep your account in working order.
                                            </li>
                                            <li>
                                                <b>To deliver and facilitate delivery of services to the user.</b>
                                                We may process your information to provide you with the requested service.
                                            </li>
                                            <li>
                                                <b>To respond to user inquiries/offer support to users. </b>
                                                We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                                            </li>
                                            <li>
                                                <b>To send administrative information to you.</b>
                                                We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                                            </li>
                                            <li>
                                                <b>To fulfil and manage your orders. </b>
                                                We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.
                                            </li>
                                            <li>
                                                <b>To request feedback. </b>
                                                We may process your information when necessary to request feedback and to contact you about your use of our Services.
                                            </li>
                                            <li>
                                                <b>To send you marketing and promotional communications. </b>
                                                We may process the personal information you send to us for our marketing purposes, if this is in 
                                                accordance with your marketing preferences. You can opt out of our marketing emails at any time. For 
                                                more information, see
                                                <a href="#9">'WHAT ARE YOUR PRIVACY RIGHTS?'</a>below.
                                            </li>
                                            <li>
                                                <b>To deliver targeted advertising to you. </b>
                                                We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.
                                            </li>
                                            <li>
                                                <b>To post testimonials. </b>
                                                We post testimonials on our Services that may contain personal information.
                                            </li>
                                            <li>
                                                <b>To protect our Services. </b>
                                                We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                                            </li>
                                            <li>
                                                <b>To administer prize draws and competitions. </b>
                                                We may process your information to administer prize draws and competitions.
                                            </li>
                                            <li>
                                                <b>To evaluate and improve our Services, products, marketing, and your experience. </b>
                                                We may process your information when we believe it is necessary to identify usage trends, determine 
                                                the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, 
                                                marketing, and your experience.
                                            </li>
                                            <li>
                                                <b>To identify usage trends.</b>
                                                We may process information about how you use our Services to better understand how they are being used so we can improve them.
                                            </li>
                                            <li>
                                                <b>To determine the effectiveness of our marketing and promotional campaigns. </b>
                                                We may process your information to better understand how to provide marketing and promotional campaigns 
                                                that are most relevant to you.
                                            </li>
                                            <li>
                                                <b>To comply with our legal obligations.</b>
                                                We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.
                                            </li>
                                        </ul>
                                    </p>
                                    <p id="3">
                                        <h4>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
                                        <p>
                                            <b>In Short: </b>
                                            We may share information in specific situations described in this section and/or with the following categories of third parties.
                                        </p>
                                        <p>
                                            <b>Vendors, Consultants, and Other Third-Party Service Providers.</b>
                                            We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') 
                                            who perform services for us or on our behalf and require access to such information to do that work. We have 
                                            contracts in place with our third parties, which are designed to help safeguard your personal information. 
                                            This means that they cannot do anything with your personal information unless we have instructed them to do 
                                            it. They will also not share your personal information with any organisation apart from us. They also commit 
                                            to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of 
                                            third parties we may share personal information with are as follows:
                                        </p>
                                        <ul>
                                            <li>Ad Networks</li>
                                            <li>Payment Processors</li>
                                        </ul>
                                        <p>We also may need to share your personal information in the following situations:</p>
                                        <ul>
                                            <li>
                                                <b>Business Transfers.</b>
                                                We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                                            </li>
                                            <li>
                                                <b>When we use Google Maps Platform APIs.</b>
                                                We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                                            </li>
                                            <li>
                                                <b>Offer Wall.</b>
                                                Our application(s) may display a third-party hosted 'offer wall'. Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.
                                            </li>
                                        </ul>
                                    </p>
                                    <p id="4">
                                        <h4>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h4>
                                        <p>
                                            <b>In Short: </b>
                                            We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
                                        </p>
                                        <p>
                                            The Services, including our offer wall, may link to third-party websites, online services, or mobile 
                                            applications and/or contain advertisements from third parties that are not affiliated with us and which 
                                            may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding 
                                            any such third parties, and we will not be liable for any loss or damage caused by the use of such 
                                            third-party websites, services, or applications. The inclusion of a link towards a third-party website, 
                                            service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy 
                                            of data you provide to any third parties. Any data collected by third parties is not covered by this 
                                            privacy notice. We are not responsible for the content or privacy and security practices and policies of 
                                            any third parties, including other websites, services, or applications that may be linked to or from the 
                                            Services. You should review the policies of such third parties and contact them directly to respond to 
                                            your questions.
                                        </p>

                                    </p>
                                    <p id="5">
                                        <h4>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                                        <p>
                                            <b>In Short: </b>We may use cookies and other tracking technologies to collect and store your information.
                                        </p>
                                        <p>
                                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store 
                                            information. Specific information about how we use such technologies and how you can refuse certain 
                                            cookies is set out in our Cookie Notice.
                                        </p>
                                    </p>
                                    <p id="6">
                                        <h4>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
                                        <p>
                                            <b>In Short:</b>
                                            We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
                                        </p>
                                        <p>
                                            We will only keep your personal information for as long as it is necessary for the purposes set out in this 
                                            privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, 
                                            or other legal requirements). No purpose in this notice will require us keeping your personal information 
                                            for longer than   three (3) months past the termination of the user's account.
                                        </p>
                                        <p>
                                            When we have no ongoing legitimate business need to process your personal information, we will either 
                                            delete or anonymise such information, or, if this is not possible (for example, because your personal 
                                            information has been stored in backup archives), then we will securely store your personal information 
                                            and isolate it from any further processing until deletion is possible.
                                        </p>
                                    </p>
                                    <p id="7">
                                        <h4>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
                                        <p>
                                            <b>In Short: </b>We aim to protect your personal information through a system of organisational and technical security measures.
                                        </p>
                                        <p>
                                            We have implemented appropriate and reasonable technical and organisational security measures designed to 
                                            protect the security of any personal information we process. However, despite our safeguards and efforts 
                                            to secure your information, no electronic transmission over the Internet or information storage technology 
                                            can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or 
                                            other unauthorised third parties will not be able to defeat our security and improperly collect, access, 
                                            steal, or modify your information. Although we will do our best to protect your personal information, 
                                            transmission of personal information to and from our Services is at your own risk. You should only access 
                                            the Services within a secure environment.
                                        </p>
                                    </p>
                                    <p id="8">
                                        <h4>8. DO WE COLLECT INFORMATION FROM MINORS?</h4>
                                        <p>
                                            <b>In Short:</b>We do not knowingly collect data from or market to children under 18 years of age.
                                        </p>
                                        <p>
                                            We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, 
                                            you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent 
                                            to such minor dependent’s use of the Services. If we learn that personal information from users less than 
                                            18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly 
                                            delete such data from our records. If you become aware of any data we may have collected from children 
                                            under age 18, please contact us at 
                                            <a href="mailto:care@nutsby.com">care@nutsby.com.</a>
                                        </p>

                                    </p>
                                    <p id="9">
                                        <h4>9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
                                        <p>
                                            <b>In Short: </b>You may review, change, or terminate your account at any time.
                                        </p>
                                        <p>
                                            <b><u>Withdrawing your consent: </u></b>
                                            If we are relying on your consent to process your personal information, which may be express and/or implied 
                                            consent depending on the applicable law, you have the right to withdraw your consent at any time. You can 
                                            withdraw your consent at any time by contacting us by using the contact details provided in the section 
                                            <a href="#12">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
                                        </p>
                                        <p>
                                            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, 
                                            when applicable law allows, will it affect the processing of your personal information conducted in 
                                            reliance on lawful processing grounds other than consent.
                                        </p>
                                        <p>
                                            <b><u>Opting out of marketing and promotional communications:</u></b>
                                            You can unsubscribe from our marketing and promotional communications at any time by clicking on the 
                                            unsubscribe link in the emails that we send, or by contacting us using the details provided in the section
                                            <a href="#12">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a>
                                            below. You will then be removed from the marketing lists. However, we may still communicate with you — for 
                                            example, to send you service-related messages that are necessary for the administration and use of your 
                                            account, to respond to service requests, or for other non-marketing purposes.
                                        </p>
                                        <h4>Account Information</h4>
                                        <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                                        <ul>
                                            <li>Contact us using the contact information provided.</li>
                                            <li>Log in to your account settings and update your user account.</li>
                                        </ul>
                                        <p>
                                            Upon your request to terminate your account, we will deactivate or delete your account and information 
                                            from our active databases. However, we may retain some information in our files to prevent fraud, 
                                            troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with 
                                            applicable legal requirements.
                                        </p>
                                        <p>
                                            <b><u>Cookies and similar technologies:</u></b>
                                            Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your 
                                            browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this 
                                            could affect certain features or services of our Services. You may also
                                            <a href="">opt out of interest-based advertising by advertisers</a>
                                            on our Services.
                                        </p>
                                        <p>
                                            If you have questions or comments about your privacy rights, you may email us at 
                                            <a href="mailto:care@nutsby.com">care@nutsby.com.</a>
                                        </p>
                                    </p>
                                    <p id="10">
                                        <h4>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                                        <p>
                                            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track 
                                            ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your 
                                            online browsing activities monitored and collected. At this stage no uniform technology standard for 
                                            recognising and implementing DNT signals has been finalised. As such, we do not currently respond to 
                                            DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked 
                                            online. If a standard for online tracking is adopted that we must follow in the future, we will inform you 
                                            about that practice in a revised version of this privacy notice.
                                        </p>
                                    </p>
                                    <p id="11">
                                        <h4>11. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
                                        <p>
                                            <b>In Short:</b>Yes, we will update this notice as necessary to stay compliant with relevant laws.
                                        </p>
                                        <p>
                                            We may update this privacy notice from time to time. The updated version will be indicated by an updated 
                                            'Revised' date and the updated version will be effective as soon as it is accessible. If we make material 
                                            changes to this privacy notice, we may notify you either by prominently posting a notice of such changes
                                            or by directly sending you a notification. We encourage you to review this privacy notice frequently to 
                                            be informed of how we are protecting your information.
                                        </p>
                                    </p>
                                    <p id="12">
                                        <h4>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
                                        <p>If you have questions or comments about this notice, you may email us at <a href="mailto:care@nutsby.com">care@nutsby.com</a> or contact us by post at:</p>
                                        <p>Nutsby Fitcare LLP</p>
                                        <p>A-46, Madhura Nagar</p>
                                        <p>Hyderabad, Telangana, 500038</p>
                                        <p>India</p>
                                    </p>
                                    <p id="13">
                                        <h4>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
                                        <p>
                                            Based on the applicable laws of your country, you may have the right to request access to the personal 
                                            information we collect from you, change that information, or delete it. To request to review, update, 
                                            or delete your personal information, please visit:
                                                <a href="#">https://nutsby.com/contact-us.</a>
                                        </p>
                                    </p>
                    </div>

                </Container>
            </Col>
        </Row>
    )

}
export default PrivacyJS;