import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col,Button,Form,Card,Image} from 'react-bootstrap';

function AboutJS() {
    return(
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Container>
                    <div className='about' style={{margin:"100px 0px"}}>
                        <h2 className='about_h2' style={{textAlign:"center"}}>Excited to Introduce Ourselves!</h2>
                            <p className='about_p' style={{margin:"20px 0px"}}>
                                Welcome to Nutsby, your ultimate nutritional superstore dedicated to 'Building a Healthy You!' Explore our diverse selection 
                                of premium offerings, including protein supplements, wholesome foods, organic delights, essential sports 
                                supplements, as well as a variety of nuts, dates, and berries. As advocates for holistic wellness, we 
                                believe in the transformative power of nutrition. Whether you visit our offline stores or shop online, 
                                Nutsby is your curated destination for top-tier products. Elevate your well-being with us.
                            </p>
                    </div>

                </Container>
            </Col>
        </Row>
    )

}
export default AboutJS;