import React from 'react';
import image404 from '../assets/images/PNG/404.png';

const NotFound = () => {
  return (
    <div
        className="not-found-container"
        style={{
            backgroundImage: `url(${image404})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
           
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '4rem', 
            boxSizing: 'border-box',
        }}
    >


<div className="not-found-content" style={{ textAlign: 'center', color: '#fff' }}>
  <img
    src={require("../assets/images/PNG/whitelogo.png")} 
    alt="Top-best-weight-loss-supplements"
    style={{ webkitUserDrag: "none", width: "20%" }} 
  /><br></br>
  <img
    src={require("../assets/images/PNG/404man.png")} 
    alt="Top-best-weight-loss-supplements"
    style={{ webkitUserDrag: "none", width: "48%" }} 
  />
  <h1>Oops!</h1>
  <p style={{ textAlign: "center", marginBottom: "2px", fontWeight: "600" ,fontSize:"medium",textAlign:"center",padding:"0px 10px"}}>
    You literally shook the Earth; we’re not able to find this page.
  </p>
  <p style={{ textAlign: "center", fontWeight: "200", color: "white",fontSize:"medium",textAlign:"center",padding:"0px 10px" }}>
  Don’t worry! Just click the button below to get back on track!
  </p>
  <a
    href="/"
    className="home-link"
    style={{
      color: 'white',
      textDecoration: 'none',
      border: '1px solid white',
      borderRadius: '33px',
      padding: '6px 20px',
      display: 'inline-block',
    }}
  >
    Home page
  </a>
</div>

    </div>
);

};

export default NotFound;