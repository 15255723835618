import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col,Button,Form,Card,Image} from 'react-bootstrap';

function ReturnandRefundJS() {
    return(
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Container>
                    <div className='about' style={{margin:"100px 0px"}}>
                        <h2 className='about_h2' style={{textAlign:"center"}}>RETURN AND REFUND POLICY</h2>
                            <p className='about_p' style={{margin:"20px 0px"}}>
                                Last updated December 11, 2023
                            </p>
                            <h4>RETURN AND REFUNDS</h4>
                            <p className='about_p' style={{margin:"20px 0px"}}>
                                All sales are final and no return/refund will be issued.
                            </p>
                            <h4>QUESTIONS</h4>
                            <p className='about_p' style={{margin:"20px 0px"}}>
                                If you have any questions concerning our return and refund policy, please contact us at:
                                <a href="mailto:care@nutsby.com" style={{color:"#219EBC",fontWeight:"500"}}>care@nutsby.com</a>
                            </p>
                    </div>

                </Container>
            </Col>
        </Row>
    )

}
export default ReturnandRefundJS;