import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Card,
    Image,
    Modal,
} from 'react-bootstrap';
import { 
    NavLink,
} from "react-router-dom";
import "../../SubCss/RateOrder.css";
import Rating from '../../MainComponents/SubComponents/Rating.js';
import { RateOrderdetailsApi, OrderRatingSubmitApi, SubmitProductReview } from '../../Service/ApiCalling';
import { useParams, useNavigate } from "react-router-dom";
import WriteReviewpopup from "../WriteReviewPopup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
function RateOrder() {
    const [productData, setproductData] = useState([]);
    const [Order_id, setOrder_id] = useState(useParams().id);
    const [ratings, setRatings] = useState(Array(productData.length).fill(0));
    const [deliveryrating, setDeliveryRating] = useState(0);
    const [deliveryRatingtoSend, setDeliveryRatingtoSend] = useState("0");
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [show, setShowWriteReviewModal] = useState(false);
    const handleClose = () => setShowWriteReviewModal(false);
    const handleShow = () => setShowWriteReviewModal(true);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);

    const [rate, setRate] = useState(0);
    const [prodrate, setProdRate] = useState([]);
    const [file, setFile] = useState([]);
    const [headline, setHeadline] = useState('');
    const [review, setReview] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderDetails = async () => {
            let OrderData = await RateOrderdetailsApi(Order_id);
            console.log(OrderData ,"OrderData")
            if (OrderData.error === "0") {
               
                setproductData(OrderData.order_products);
            } else {
                setproductData([]);
            }
        };
        fetchOrderDetails();
    }, [Order_id]);

    const handleRatingChange = (index, newRating) => {
        const newProdRate = [...prodrate];
        newProdRate[index] = newRating;
        setProdRate(newProdRate);
    };
    const [index,setIndex] = useState('')
    const handleDeliveryRatingChange = (newRating) => {
            setDeliveryRating(deliveryrating);
    };

    const handleButtonClick = (index) => {
        console.log(prodrate[index],"prodratings");
        setIndex(index);
        setSelectedProductIndex(index);

        setShowWriteReviewModal(true);
    };

    const handleCloseModal = () => {
        setShowWriteReviewModal(false);
    };

    const handleSubmitReview = (product_id) => {
        
        console.log(product_id,"pde")
        AddProductRatingApi(product_id);
        
    }
    const handleFileChange = (e) => {
        console.log(e.target.files);
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
    }
    
    // productRatings[
    //     prods[index]
    //         .productId!]
    const dataArrayofProductRating = productData.map((element, index) => ({
        "order_item_id": element.order_product_id,
        "item_id": element.product_id,
        "rating": prodrate[index] || 0
    }));

    const dataToSend = JSON.stringify(dataArrayofProductRating);

    
    const AddProductRatingApi = async (Product_id) => {
        

        let data = await SubmitProductReview(Product_id, rate, review, headline, file);
        if (data.error === "0") {
            console.log("Review submitted");
            setRate(0);
            setHeadline('');
            setReview('');
            setFile([]);
            handleClose();
            
            // Optionally refresh the average ratings           
        } else {
            console.log("Error submitting review");
        }
      }

      

    const submitOrderRating = async () => {        
        console.log(deliveryrating,"rateDel");
        console.log(Order_id,"Order_id");
        console.log(dataArrayofProductRating,"dataArrayofProductRating");
        console.log(dataToSend,"dataToSend");

        if(deliveryrating===1){

            setDeliveryRatingtoSend("1");

        }else if(deliveryrating===2){

            setDeliveryRatingtoSend("2");

        }else if(deliveryrating===3){

            setDeliveryRatingtoSend("3");

        }else if(deliveryrating===4){

            setDeliveryRatingtoSend("4");

        }else if(deliveryrating===5){

            setDeliveryRatingtoSend("5");

        }else if(deliveryrating===0 || deliveryrating===null){

            setDeliveryRatingtoSend("0");

        }
        console.log(deliveryRatingtoSend,"rateDelSend");
        
        let data = await OrderRatingSubmitApi(Order_id,  dataToSend,deliveryRatingtoSend);
        if (data.error === "0") {
            setDeliveryRating(0);    
            setDeliveryRatingtoSend("0");
            setProdRate([]);
            console.log("Order rating submitted!");
            window.location.href = "/Orders";
        } else {
            console.log(data.message);
        }
    };

    return (
        <div>
            <Container className='d-flex justify-content-between' style={{ marginTop: "2rem" }}>
                <Col xs="auto" style={{ display: "flex" }}>
                    <Button
                        className="order_status"
                        type="button"
                        style={{
                            borderColor: "transparent",
                            backgroundColor: "white",
                            color: "black",
                            borderRadius: "5px",
                            fontFamily: "Inter",
                            display: "flex",
                            alignItems: "center",
                            padding: "0px",
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="23"
                            viewBox="0 0 13 23"
                            fill="none"
                            style={{ width: "17px", height: "17px", marginRight: "6px" }}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.9988 0.94219C11.6066 0.549994 10.9708 0.549993 10.5786 0.94219L0.536048 10.9848C0.143851 11.377 0.143851 12.0127 0.536048 12.4049L10.5786 22.4476C10.9708 22.8397 11.6066 22.8397 11.9988 22.4476C12.391 22.0554 12.391 21.4195 11.9988 21.0273L2.66642 11.6949L11.9988 2.36235C12.391 1.97015 12.391 1.33439 11.9988 0.94219Z"
                                fill="black"
                            />
                        </svg>
                        Back
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button
                        className="submit_bottom"
                        onClick={submitOrderRating}
                        type="submit"
                        style={{
                            fontWeight: "500",
                            borderColor: "#FFB703",
                            backgroundColor: "#FFB703",
                            color: "black",
                            fontFamily: "Inter",
                            borderRadius: "30px",
                        }}
                    >
                        Submit
                    </Button>
                </Col>
            </Container>

            <Container className='mt-10'>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Card className='mt-10' style={{ borderRadius: "27px" }}>
                            <Card.Body>
                                <Row>
                                    <Col lg={2} md={2} sm={2} xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={require('../../assets/images/PNG/7.png')} style={{ width: "55px", height: "55px" }} />
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <Col>
                                            <div style={{ marginBottom: "0px" }}>How was your delivery experience?</div>
                                        </Col>
                                        <Col>
                                            {/* <Rating rating={deliveryrating} onRatingChange={handleDeliveryRatingChange} /> */}
                                            {[1, 2, 3, 4, 5].map((star) => (
                            <FontAwesomeIcon 
                                key={star} 
                                icon={faStar} 
                                onClick={() => setDeliveryRating(star)} 
                                style={{  color: deliveryrating >= star ? 'gold' : 'lightgray' }} 
                            />
                        ))}
                                        </Col>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {productData.map((element, index) => (
                <Container className='mt-2' key={element.order_product_id}>
                    <Row className="d-flex justify-content-center">
                        <Col>
                            <Card style={{ borderRadius: "27px" }}>
                                <Row className="g-0">
                                    <Col lg={2} md={2} sm={2} xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Card.Img src={element.image} alt={element.name} />
                                    </Col>
                                    <Col lg={9} md={9} sm={9} xs={9} style={{alignItems:"center"}}>
                                        <Card.Body>
                                            <div
                                                style={{
                                                    fontFamily: "Inter",
                                                    marginBottom: "0",
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: '2',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    
                                                }}
                                                
                                            >
                                                <NavLink style={{color:"black"}}
                         
                                        to={`/product_details/${element.product_id}/${encodeURIComponent(element.name)}`}>
                                                {element.name} </NavLink>
                                            </div>
                                            <Row className="d-flex align-items-center">
                                                <Col>
                                                    {/* <Rating
                                                        rating={ratings[index]}
                                                        onRatingChange={(newRating) => handleRatingChange(index, newRating)}
                                                    /> */}
                                                    {[1, 2, 3, 4, 5].map((star) => (
                                                        <FontAwesomeIcon
                                                            key={star}
                                                            icon={faStar}
                                                            onClick={() => handleRatingChange(index, star)}
                                                            style={{ color: prodrate[index] >= star ? 'gold' : 'lightgray' }}
                                                        />
                                                    ))}
                                                </Col>
                                                <Button
                                                    className=""
                                                    variant="outline-none"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px"
                                                    }}
                                                    onClick={()=>{handleButtonClick(index)}}
                                                >
                                                    <span style={{ color: "rgb(0, 142, 176)", marginRight: "5px" }}>Write a Review</span>
                                                    <svg
                                                        width="7"
                                                        height="15"
                                                        viewBox="0 0 7 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="icondetails"
                                                    >
                                                        <path
                                                            d="M6.58443 4.77775C6.57675 4.77134 6.5665 4.76878 6.55882 4.76238L1.61991 0.292135C1.34204 0.0424375 0.891302 0.0424375 0.613432 0.292135C0.610871 0.294697 0.609591 0.297257 0.608311 0.299818C0.541027 0.355373 0.486686 0.424952 0.449086 0.50369C0.411487 0.582429 0.391535 0.668427 0.390625 0.755678L0.390625 9.69745C0.392061 9.7863 0.412989 9.87375 0.451931 9.95363C0.490873 10.0335 0.54688 10.1039 0.615994 10.1597L0.613433 10.1623C0.753246 10.2831 0.931874 10.3496 1.11667 10.3496C1.30147 10.3496 1.4801 10.2831 1.61991 10.1623L6.58443 5.68178C6.64981 5.626 6.70232 5.5567 6.73832 5.47867C6.77432 5.40063 6.79297 5.31571 6.79297 5.22976C6.79297 5.14382 6.77432 5.0589 6.73832 4.98086C6.70232 4.90282 6.64981 4.83352 6.58443 4.77775Z"
                                                            fill="#008EB0"
                                                        />
                                                    </svg>
                                                </Button>
                                            </Row>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Container className='mt-2'></Container>
                </Container>
            ))}

            <Modal className="WriteReviewModal" show={show} onHide={handleClose} style={{ width: "100%", borderRadius: "15px" }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{borderBottom:"transparent"}}>
                    <Modal.Title>Product Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <WriteReviewpopup detailsData={productData} from={"rate order"} index={index} prodRating={prodrate[index]}/>
                
                {/* <Container className="d-flex align-content-center" style={{ backgroundColor: "white" }}>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            
                            <Row className="mt-0">
                                <Col lg={3} md={3} sm={3} xs={3}>
                                    <img src={productData[index].image} alt={productData[index].name} fluid />
                                </Col>
                                <Col lg={9} md={9} sm={9} xs={9}>
                                    <h4 className="text-justify">{productData[index].name}</h4>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "20px" }}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h5>Rate this Product</h5>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <FontAwesomeIcon 
                                            key={star} 
                                            icon={faStar} 
                                            onClick={() => setRate(star)} 
                                            style={{ width: "35px", height: "35px", color: rate >= star ? '#219ebc' : 'lightgray' }} 
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Form >
                                <Row style={{ marginTop: "20px" }}>
                                    <h5>Add a Headline</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Write a headline" 
                                            style={{ borderColor: "black" }} 
                                            value={headline}
                                            onChange={(e) => setHeadline(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Container style={{ marginTop: "20px" }}>
                                    <Row>
                                        <Col lg={2} md={2} sm={2} xs={4}>
                                            <div className="dotted-border-box" style={{ width: "100px", height: "100px" }}>
                                                <div className="centered-content">+</div>
                                                <input 
                                                    type="file" 
                                                    onChange={handleFileChange} 
                                                    style={{ opacity: 0, position: "relative", zIndex: 1, width: "100px", height: "100px" }} 
                                                />
                                            </div>
                                        </Col>
                                        {file.map((element, index) => (
                                            <Col lg={2} md={2} sm={2} xs={4} key={index}>
                                                <img src={element} alt="Uploaded file" className="dotted-border-box" style={{ padding: 0, width: "100px", height: "100px" }} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                                <Row style={{ marginTop: "20px" }}>
                                    <h5>Write a review</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Control 
                                            as="textarea"
                                            rows={10} 
                                            placeholder="Write something about the product" 
                                            style={{ borderColor: "black" }} 
                                            value={review}
                                            onChange={(e) => setReview(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                                    <Button onClick={()=>console.log(productData[index].product_id)} variant="primary"  style={{ borderColor: "orange", backgroundColor: "orange", color: "black", width: "100px" }}>
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                        </Col>
                    </Container> */}
                </Modal.Body>
               
            </Modal>
        </div>
    );
}

export default RateOrder;
