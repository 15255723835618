import React, {useEffect, useState} from "react";
import {Button, Offcanvas} from 'react-bootstrap';
import "../../SubCss/CouponList.css";
import {ApplyCouponApi, CouponsListAPI} from '../../Service/ApiCalling';
import { Container,Form} from 'react-bootstrap';
import {ReactComponent as FileImg} from '../../assets/images/fileIcon.svg'
import {ReactComponent as CouponImg} from '../../assets/images/couponIcon.svg'

const CouponsList = ({order_amount, onSelectCoupon}) => {
    const [couponslist,
        setCouponsList] = useState([]);
        const [isloading,setIsLoading] = useState(true);
    useEffect(() => {
        CouponsListAPIFunction();
    }, []);


    const [couponCode, setCouponCode] = useState('');
    const handleCouponChange = (code) => {
      setCouponCode(code);
    };
  
    const CouponsListAPIFunction = async() => {
        let data = await CouponsListAPI(order_amount);
        if (data.error == "0") {
            setCouponsList(data.list);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            // console.log(data.message);
        }
    };

    const ApplyCouponsAPIFunction = async(id) => {
        let data = await ApplyCouponApi(id);
        if(data.error === "0"){
            onSelectCoupon(data.coupon_code);
            // console.log(data.coupon_code);
            // handleCouponClick(data.list[0].coupon_code);
            // console.log("Successsssss");
        }else{
            // console.log("Failureeeeee");
        }
    }
    const ApplyCouponsAPIFunction1 = async(id) => {
        let data = await ApplyCouponApi(id);
        if(data.error === "0"){
            onSelectCoupon(data.list[0].coupon_code);
            // console.log("Successsssss");
        }else{
            // console.log("Failureeeeee");
        }
    }

    const handleCouponClick = (couponId) => {
        onSelectCoupon(couponId);
    };

    // Assuming currentDate is a Date object representing the current date
    const currentDate = new Date();
    return ( <> 
    <Offcanvas.Body style={{overflowY:"visible"}}>
        <div className="coupons-deals-container">
            {/* <div className="coupon-input-container">
            <input
                type="text "                
                placeholder="Type Coupon Code Here"
                onChange={(e) => handleCouponChange(e.target.value)}
                value={couponCode}            
                accept=""
                disabled={false}
                style={{
                    borderRadius: "38px",
                    outline: "none",
                    border: "none",
                    height: "30px",
                    width: "95%",
                    
                    cursor:"default",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    zIndex:"auto"
                }}
            />

                
                <button className="apply-btn d-flex justify-content-end">Apply</button>
            </div> */}

            {couponslist.map((element) => {
                 // Assuming each coupon object has an `expiryDate` property
                 const expiryDate = new Date(element.expire_date);

                // Compare currentDate with expiryDate
                const isExpired = currentDate > expiryDate;
                return ( <> 
                {isExpired?<></>:
                isloading?
                <Container className="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> 
              </Container>
              :
                <div class="couponlist">
                    <div className="d-flex justify-content-between" style={{ marginLeft: '20px', marginTop: '15px',width:"100%"}}>
                        <div style={{ marginRight: 'auto' }}>
                            <div style={{ border: '1px dotted #219EBC', padding: '2px', borderRadius: '42px', display: 'inline-flex', alignItems: 'center',backgroundColor:"#FFF" }}>
                            <span className="couponcode" style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                                fontSize: '15px',
                                fontFamily: 'Inter',
                            }}>
                                {element.coupon_code}
                            </span>
                                <FileImg style={{width:"19px",height:"19px"}}/>
                            </div>
                            <div style={{ marginLeft: '10px'}}>
                                <p className="mb-0 coupondesc" style={{ marginTop: '5px',fontSize:"15px",fontFamily:"Inter"}}>{element.coupon_description}</p>
                            </div>
                        </div>

                            <div>
                               
                                <CouponImg  className="couponimg" style={{width:"55px",height:"55px",marginRight:"15px"}}/>
                            </div>
                        </div>
                        <div class="kanan">
                            <div class="info">
                                <div style={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',marginLeft:"20px",marginTop:"5px"}}>
                                        <div style={{marginLeft: "15px",marginTop:"10px"}}>
                                                <ul style={{ padding: 0, color: "#023047"}}>
                                                    {element.applicable_brands !== "" ? 
                                                        <li className="couponlist-options" style={{listStyle:"disc",fontSize:"15px",fontFamily:"Inter"}}>Applicable only for {element.applicable_brands}.</li>
                                                    : 
                                                    element.applicable_brands !== "" ? 
                                                        <li className="couponlist-options" style={{listStyle:"disc",fontSize:"15px",fontFamily:"Inter"}}>Not applicable for {element.not_applicable_brands}.</li>
                                                        :<li style={{fontSize:"15px"}}></li>
                                                    }
                                                    <li className="couponlist-options" style={{listStyle:"disc",fontSize:"15px",fontFamily:"Inter"}}>Valid till {element.expire_date}</li>
                                                </ul>
                                        
                                        </div>

                                {element.is_coupon_applicable === "1"?
                                <span className="couponApplyButton" style={{marginRight:"10px", marginTop:"1px", color:"#219EBC",fontFamily:"Inter",fontSize:"15px",cursor:"pointer"}} onClick={() => ApplyCouponsAPIFunction(element.coupon_code)}
                                >Apply</span>:<></>}
                                </div>
                                </div>
                            
                        </div>
                    </div>}
 
                </>
            )
            })}

        </div>
    </Offcanvas.Body> </>

    );
}
export default CouponsList;