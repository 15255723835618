import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col,Button,Form,Card,Image} from 'react-bootstrap';

function ShippingPolicyJS() {
    return(
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Container>
                    <div className='about' style={{margin:"100px 0px"}}>
                        <h2 className='about_h2' style={{textAlign:"center"}}>SHIPPING & DELIVERY POLICY</h2>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                Last updated December 11, 2023
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                This Shipping & Delivery Policy is part of our Terms and Conditions ("Terms") and should be therefore read alongside our main Terms:
                            </p>
                            <a href='/terms-and-conditions' style={{color:"#219EBC",fontWeight:"500"}}>
                                https://nutsby.com/terms-and-conditions
                            </a>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                Please carefully review our Shipping & Delivery Policy when purchasing our products. 
                                This policy will apply to any order you place with us.
                            </p>
                            <h5>WHAT ARE MY SHIPPING & DELIVERY OPTIONS?</h5>
                            <h6>In-Store Pickup</h6>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                In-store pickup is available for All Purchases. Pickups are available Monday - Sunday 10:00 AM to 10:00 PM.
                            </p>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                We offer various shipping options. In some cases a third-party supplier may be managing
                                our inventory and will be responsible for shipping your products.
                            </p>
                            <h6>Free Shipping</h6>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                We offer free Same Day shipping on all orders.
                            </p>
                            <h6>Delivery Timeline</h6>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                Minimum: 0 Days, Maximum: 7 Days.
                            </p>
                            <h5>DO YOU DELIVER INTERNATIONALLY?</h5>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                We do not offer international shipping.
                            </p>
                            <h5>ARE THERE OTHER SHIPPING RESTRICTIONS?</h5>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                To ensure same-day delivery, order must be placed by 9 PM IST.
                            </p>
                            <h5>QUESTIONS ABOUT RETURNS?</h5>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                If you have questions about returns, please review our Return Policy:
                                <a href='/return-and-refund-policy' style={{color:"#219EBC", fontWeight:"500"}}>
                                    https://nutsby.com/return-and-refund-policy
                                </a>
                            </p>
                            <h5>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h5>
                            <p className='about_p' style={{margin:"10px 0px"}}>
                                If you have any further questions or comments, you may contact us by:
                                <ul>
                                    <li>
                                        Email: <a href='mailto:care@nutsby.com' style={{color:"#219EBC", fontWeight:"500"}}>care@nutsby.com</a>
                                    </li>
                                </ul>
                            </p>

                    </div>

                </Container>
            </Col>
        </Row>
    )

}
export default ShippingPolicyJS;