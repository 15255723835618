import React, { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css"; 
import { Container, Row, Col, Button, Breadcrumb,Form } from 'react-bootstrap';
import { register_affiliate } from '../../Service/ApiCalling';
import { useLocation} from 'react-router-dom';
function Afiliatepage() {
    const [otherCategory, setOtherCategory] = useState(false);
  const [upiSelected, setUpiSelected] = useState(false);
  const [bankDetailsSelected, setBankDetailsSelected] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [payee_name, setName] = useState('');
  const [instagram_link, setinstagram_link] = useState('');
  const [website_link, setwebsite_link] = useState('');
  const [category, setcategory] = useState('');
  const [description, setdescription] = useState('');
  const [bank_name, setbank_name] = useState('');
  const [bank_branch_name, setbank_branch_name] = useState('');
  const [bank_ifsc_code, setbank_ifsc_code] = useState('');
   const [bank_account_number, setbank_account_number] = useState('');
   const [bank_upi_id, setbank_upi_id] = useState('');
  const fetchInfo=async ()=> {  
    // if(params.id==="1"){
        let register_affiliateData = await register_affiliate(payee_name,instagram_link,website_link,category,description,bank_name,bank_branch_name,bank_ifsc_code, bank_account_number, bank_upi_id);
        if (register_affiliateData.error==="0") {  
            
           
}}
  // useEffect(() => {
  //   fetchInfo();
  //   if (pathname === "/afiliate") {
  //     document.body.style.backgroundColor = "#F4F5FA";
     
  //   } else {
  //     document.body.style.backgroundColor = "white";
  //   }
  // }, [pathname]);
  return (
   <>
<div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "10rem",borderRadius:'30px'}} > 
              <Row >
                    <Col lg={12} md={12} sm={12} col={12}  >
                      
                          
                          
                            <img src={require("../../assets/images/PNG/affilite.png")} alt="" style={{ borderRadius: '20px' ,webkitUserDrag:"none"}} />
                          
                            
                      </Col>                    
                  </Row> 
              </div>
              <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "9rem"}} > 
                  <Row>
                  <Col lg={12} md={12} sm={12} col={12}>
                  
                  
                            <img src={require("../../assets/images/PNG/mb_affilite.png")} alt="" style={{ borderRadius:'18px' ,webkitUserDrag:"none"}} />
                          
                        
                      </Col>                      
                  </Row> 
              </div>  

    <div className="container" style={{padding:"20px"}}>
        
      <div className="row">
      
        <div className="col-md-6">
          <div className="card p-4" style={{borderRadius:"20px"}}>
            <h4 style={{color:"#000000",fontWeight:"600",marginBottom:"25px"}}>Your Personal Information</h4>
            <Form>
              <div className="form-group">
                <label style={{padding:"4px"}}><span style={{color:""}}>Payee Name</span><span style={{color:"red"}}>*</span></label>
                <input type="text" className="form-control affliate" placeholder="Enter Payee Name" required />
              </div>
              <div className="form-group">
                <label style={{padding:"4px"}}>Instagram<span style={{color:"red"}}>*</span></label>
                <input type="link" className="form-control affliate" placeholder="Instagram Link" required />
              </div>
              <div className="form-group">
                <label style={{padding:"4px"}}>Website <span style={{color:"#9D9D9D"}}>(Optional)</span></label>
                <input type="link" className="form-control affliate" placeholder="Website Link" />
              </div>
              <div className="form-group">
                <label style={{marginBottom:"10px"}}>Content Categories<span style={{color:"red"}}>*</span></label>

               
                <div className="form-check" style={{paddingLeft:'0px',paddingBottom:"5px"}}>
      <input
       type="radio"
       className="radio"
       name="category" 
       onClick={() => setOtherCategory(false)}
      />
      <label style={{marginLeft:"8px"}} htmlFor="" className="form-check-label">Blog</label>
    </div>
    <div className="form-check" style={{paddingLeft:'0px',paddingBottom:"5px"}}>
      <input
      type="radio"
      className="radio"
      name="category" 
      onClick={() => setOtherCategory(false)}
      />
      <label style={{marginLeft:"8px"}} htmlFor="" className="form-check-label">Content Website</label>
    </div>
    <div className="form-check" style={{paddingLeft:'0px',paddingBottom:"5px"}}>
      <input
        type="radio"
        className="radio"
        name="category" 
        onClick={() => setOtherCategory(false)}
      />
      <label style={{marginLeft:"8px"}} htmlFor="" className="form-check-label">Social Media Page</label>
    </div>
    <div className="form-check" style={{paddingLeft:'0px',paddingBottom:"5px"}}>
      <input
         type="radio"
         className="radio"
         name="category" 
         onClick={() => setOtherCategory(false)}
      />
      <label style={{marginLeft:"8px"}} htmlFor="" className="form-check-label"> Web Browser</label>
    </div>
    <div className="form-check" style={{paddingLeft:'0px',paddingBottom:"5px"}}>
      <input
      type="radio"
      className="radio"
      name="category" 
        onClick={() => setOtherCategory(true)}
      />
      <label style={{marginLeft:"8px"}} htmlFor="" className="form-check-label">{" "} Others</label>
    </div>

 
  {otherCategory && (
    <input
      type="text"
      placeholder="Other Category"
      className="form-control"
    />
  )}
</div>

             
            </Form>
          </div>
        </div>

      
        <div className="col-md-6" style={{paddingLeft:"1px"}}>
          {/* Second Form */}
          <div className="card p-4 mb-2" style={{borderRadius:"20px"}}>
            <h4 style={{color:"#000000",fontWeight:"600"}}>Tell About Yourself</h4>
            <Form>
              <div className="" style={{marginBottom:"4px"}}>
                <label style={{marginBottom:"8px"}}>Description<span style={{color:"red"}}>*</span></label>
                <textarea
                className="form-control1"
                  placeholder="Write something about your content"
                  rows="4"
                  required
                ></textarea>
              </div>
            </Form>
          </div>

          {/* Third Form */}
          <div className="card p-4" style={{borderRadius:"20px"}}>
            <h4 style={{color:"#000000",fontWeight:"600"}}>Your Account Information</h4>
            <Form>
              <div className="form-check">
                <div style={{paddingBottom:"10px"}}>
              <input
                    type="radio"
                    name="payment"
                    className="radio"
                    onClick={() => {
                        setBankDetailsSelected(false);
                        setUpiSelected(true);
                      }}
                  />
                <label style={{marginLeft:'8px'}}>
                 {" "}
                  Bank UPI ID
                </label></div>
                {upiSelected && (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter UPI ID"
                   
                  />
                )}
              </div>
              <div className="form-check">
                <div style={{marginBottom:"10px"}}>
              <input
                    type="radio"
                    name="payment"
                    className="radio"
                    onClick={() => {
                      setBankDetailsSelected(true);
                      setUpiSelected(false);
                    }}
                  />
                <label style={{marginLeft:'8px'}}>
                 
                  Bank Details
                </label>
                </div>
                {bankDetailsSelected && (
                  <div className="bank-fields">
                    <input
                      type="text"
                      placeholder="Bank Account Number"
                      className="form-control"
                      required
                    />
                    <input type="text" className="form-control" placeholder="IFSC Code" required />
                    <input type="text" className="form-control" placeholder="Branch Name" required />
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="row mt-4">
        <div className="col-md-12 text-center">
          <button type="submit" className="btn btn-success">Submit</button>
        </div>
      </div>
    </div></>
  );
}

  

export default Afiliatepage;