import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css"; 
import { Container, Row, Col, Button, Breadcrumb, Form } from 'react-bootstrap';
import { web_contact_us } from '../../Service/ApiCalling';
import { useLocation } from 'react-router-dom'; 

function ContactJS() {
    const location = useLocation();
    const { pathname } = location;

   
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (pathname === "/contact-us") {
            document.body.style.backgroundColor = "#F4F5FA";
        } else {
            document.body.style.backgroundColor = "white";
        }
    }, [pathname]);

    
    const validateForm = () => {
        const errors = {};
        // Name validation
        if (!name.trim()) {
            errors.name = "Name is required.";
        }

        // Mobile number validation (assuming 10 digits)
        if (!/^\d{10}$/.test(mobile)) {
            errors.mobile = "Mobile number should be 10 digits.";
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            errors.email = "Please enter a valid email address.";
        }

        // Message validation
        if (!message.trim()) {
            errors.message = "Message is required.";
        }

        return errors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors); // Set errors in state
            return; // Stop the form from submitting if there are validation errors
        }
        setIsSubmitting(true); // Prevent default form submission

        try {
            const res = await web_contact_us(name, mobile, email, message);
            console.log(res,'response')
            if (res) {
                setSuccessMessage("Your message has been sent successfully!");
                setErrorMessage('');
  
                // Clear form fields
                setName('');
                setMobile('');
                setEmail('');
                setMessage('');
            } else {
                setErrorMessage("There was an error sending your message. Please try again.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setErrorMessage("There was an error sending your message.");
        }
        setIsSubmitting(false);
        setValidationErrors({}); // Clear any validation errors after submission
    };

    return (
        <>
            {/* Image sections omitted for brevity */}
            <div className="mainCarousel d-none d-md-block d-lg-block container" style={{ paddingBottom: "0px", paddingTop: "10rem", borderRadius: '30px' }}> 
                <Row>
                    <Col lg={12} md={12} sm={12} col={12}>
                        <img src={require("../../assets/images/PNG/contactus.png")} alt="" style={{ borderRadius: '20px', webkitUserDrag: "none" }} />
                    </Col>                    
                </Row> 
            </div>
            <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{ paddingBottom: "0px", paddingTop: "9rem" }}> 
                <Row>
                    <Col lg={12} md={12} sm={12} col={12}>
                        <img src={require("../../assets/images/PNG/contactus_mb.png")} alt="" style={{ borderRadius: '18px', webkitUserDrag: "none" }} />
                    </Col>                      
                </Row> 
            </div>  
    
            <div className="container" style={{ padding: "20px" }}>
                <div className="row">
                    <div className="col-md-6" style={{ paddingLeft: "1px" }}>
                    
                        <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center", flexDirection: "row", padding: "33px" }}>
                            <img 
                                src={require("../../assets/images/PNG/location.png")} 
                                alt="Location" 
                                style={{ width: "13%", height: "13%", marginRight: "20px", flexShrink: 0 }} 
                            />
                            <div>
                                <h5 style={{ color: "#000000", margin: 0,paddingBottom:"5px" }}>Corporate Office</h5>
                                <h6 style={{ margin: 0, color: "#808080", fontWeight: "300" }}>A-46, Madhura Nagar,<br />Hyderabad, Telangana, India - 500038.</h6>
                            </div>
                        </div>
                        <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center", flexDirection: "row", padding: "33px" }}>
                            <img 
                                src={require("../../assets/images/PNG/message.png")} 
                                alt="Email" 
                                style={{ width: "13%", height: "13%", marginRight: "20px", flexShrink: 0 }} 
                            />
                            <div>
                                <h5 style={{ color: "#000000", margin: 0 ,paddingBottom:"5px"}}>Email ID</h5>
                                <h6 style={{ margin: 0, color: "#808080", fontWeight: "300",paddingBottom:"5px" }}>care@nutsby.com</h6>
                                <h6 style={{ margin: 0, color: "#808080", fontWeight: "300" }}>info@nutsby.com</h6>
                            </div>
                        </div>
                        <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center", flexDirection: "row", padding: "33px" }}>
                            <img 
                                src={require("../../assets/images/PNG/call.png")} 
                                alt="Contact" 
                                style={{ width: "12%", height: "12%", marginRight: "20px", flexShrink: 0 }} 
                            />
                            <div>
                                <h5 style={{ color: "#000000", margin: 0 ,paddingBottom:"5px"}}>Contact Us</h5>
                                <h6 style={{ margin: 0, color: "#808080", fontWeight: "300" }}>+91 9963085543</h6>
                            </div>
                        </div>
                    </div>
            
                   

                    <div className="col-md-6" style={{ paddingLeft: "0px" }}>
                        <div className="card" style={{ borderRadius: "20px", padding: "25px" }}>
                            <h4 style={{ color: "#000000", fontWeight: "600", marginBottom: "5px" }}>Get in touch with us</h4>
                            <Form onSubmit={handleSubmit}>
                                {/* Name input */}
                                <div className="form-group" style={{ marginBottom: "5px" }}>
                                    <label style={{ padding: "4px" }}>Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control affliate" 
                                        placeholder="Full Name" 
                                        required 
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}
                                </div>

                                {/* Mobile and Email inputs */}
                                <div className="row">
                                    <div className="form-group col-6" style={{ paddingRight: '2px' }}>
                                        <label style={{ padding: "4px" }}>Phone no</label>
                                        <input 
                                            type="text" 
                                            className="form-control affliate" 
                                            placeholder="Phone no" 
                                            required 
                                            pattern="^[6789]\d{9}$"
                                            maxLength={10}
                                            title="please enter valid number"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                        {validationErrors.mobile && <div className="text-danger">{validationErrors.mobile}</div>}
                                    </div>
                                    <div className="form-group col-6" style={{ paddingLeft: '2px' }}>
                                        <label style={{ padding: "4px" }}>Email Id</label>
                                        <input 
                                            type="email" 
                                            className="form-control affliate" 
                                            placeholder="Email Id" 
                                            required 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {validationErrors.email && <div className="text-danger">{validationErrors.email}</div>}
                                    </div>
                                </div>

                                {/* Message input */}
                                <div className="form-group">
                                    <label style={{ padding: "4px" }}>Write a message</label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Write Here..."
                                        rows="3"
                                        required 
                                        name="description"
                                        style={{ backgroundColor: "#F5F5F5" }}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                    {/* {validationErrors.message && <div className="text-danger">{validationErrors.message}</div>} */}
                                </div>

                                {/* Submit button */}
                                <div className="col-12 col-lg-3 col-md-4">
                                    <button
                                        type="submit"
                                        className="btn w-100"
                                        disabled={isSubmitting}
                                        style={{
                                            backgroundColor: "#023047",
                                            color: "white",
                                            borderRadius: "25px",
                                            marginRight: "14px",
                                            fontFamily: "Inter",
                                        }}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </button>
                                </div>
                                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactJS;
